<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="settingHeader">
                <div class="profilePicture">
                    <img class="level1" :src="Level1" />
                    <div class="levelTextBox" :style="'background-image:url(' + LevelBox + ')'">
                        <span class="levelText">{{$store.state.user?.user?.levelName}}</span>
                    </div> 
                    <img class="headPic" :src="HeaderPic" />
                </div>
                <div class="profileText">
                    <span>{{ $t("ID") }}:{{$store.state.user?.user?.userId}}</span>
                    <span>{{ $t("Account") }}:{{$store.state.user?.user?.userName}}</span>
                </div>
            </div>
            <SettingInput :value="$store.state.user?.user?.userName" style="margin-bottom:1rem;">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4995 11.5C14.2609 11.5 16.4995 9.26142 16.4995 6.5C16.4995 3.73858 14.2609 1.5 11.4995 1.5C8.73809 1.5 6.49951 3.73858 6.49951 6.5C6.49951 9.26142 8.73809 11.5 11.4995 11.5Z" fill="#039685"></path>
                    <path d="M1.56073 20.8905C1.98347 17.0602 4.56953 13.8816 8.07019 12.6035C9.08375 13.1743 10.2538 13.4999 11.4999 13.4999C12.746 13.4999 13.916 13.1743 14.9296 12.6035C18.4303 13.8816 21.0163 17.0602 21.4391 20.8905C21.5064 21.5005 21.0024 21.9999 20.3888 21.9999H2.61101C1.99736 21.9999 1.49342 21.5005 1.56073 20.8905Z" fill="#039685"></path>
                </svg>
            </SettingInput>
            <SettingInput style="margin-bottom:1rem;" :click="showSetCPF" :value="$store.state.user.user.idcard">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 2.8335C1.82081 2.8335 1 3.65431 1 4.66683V19.3335C1 20.346 1.82081 21.1668 2.83333 21.1668H21.1667C22.1792 21.1668 23 20.346 23 19.3335V4.66683C23 3.65431 22.1792 2.8335 21.1667 2.8335H2.83333ZM4.77887 9.71687C4.77887 11.072 5.61904 12.2309 6.8069 12.7011C4.88194 13.1629 3.3865 14.7289 3.02782 16.6918C2.93683 17.1898 3.35594 17.6039 3.86221 17.6039H12.1122C12.6185 17.6039 13.0376 17.1898 12.9466 16.6918C12.5879 14.7289 11.0925 13.1629 9.16751 12.7011C10.3554 12.2309 11.1955 11.072 11.1955 9.71687C11.1955 7.94496 9.75912 6.50854 7.9872 6.50854C6.21529 6.50854 4.77887 7.94496 4.77887 9.71687ZM19.9207 9.64957C20.427 9.64957 20.8374 10.06 20.8374 10.5662C20.8374 11.0725 20.427 11.4829 19.9207 11.4829H14.4207C13.9145 11.4829 13.5041 11.0725 13.5041 10.5662C13.5041 10.06 13.9145 9.64957 14.4207 9.64957H19.9207ZM20.8374 15.1496C20.8374 14.6433 20.427 14.2329 19.9207 14.2329H14.4207C13.9145 14.2329 13.5041 14.6433 13.5041 15.1496C13.5041 15.6558 13.9145 16.0662 14.4207 16.0662H19.9207C20.427 16.0662 20.8374 15.6558 20.8374 15.1496Z" fill="#039685"></path>
                </svg>
            </SettingInput>
            <SettingInput style="margin-bottom:1rem;" placeholder="Associe o seu telemóvel" type="disabled" :value="$store.state.user.user.phone">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 2C2.5 0.89543 3.39543 0 4.5 0H18.5C19.6046 0 20.5 0.895431 20.5 2V22C20.5 23.1046 19.6046 24 18.5 24H4.5C3.39543 24 2.5 23.1046 2.5 22V2ZM4 3C4 2.44772 4.44772 2 5 2H18C18.5523 2 19 2.44772 19 3V19C19 19.5523 18.5523 20 18 20H5C4.44772 20 4 19.5523 4 19V3ZM9.10498 1C9.10498 0.723858 9.32884 0.5 9.60498 0.5H13.605C13.8811 0.5 14.105 0.723858 14.105 1C14.105 1.27614 13.8811 1.5 13.605 1.5H9.60498C9.32884 1.5 9.10498 1.27614 9.10498 1ZM13.105 22C13.105 22.8284 12.4334 23.5 11.605 23.5C10.7766 23.5 10.105 22.8284 10.105 22C10.105 21.1716 10.7766 20.5 11.605 20.5C12.4334 20.5 13.105 21.1716 13.105 22Z" fill="#039685"></path>
                </svg>
                <span slot="right" class="rigthOption" v-if="!$store.state.user.user.phone" @click="toVerfity">{{ $t("GoToLink") }}</span>
            </SettingInput>
            <SettingInput style="margin-bottom:1rem;" placeholder="Senha de saque" type="disabled">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7V9H19C20.1046 9 21 9.89543 21 11V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V11C3 9.89543 3.89543 9 5 9H6V7ZM16 7V9H8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 14.7998C14 15.5625 13.5731 16.2255 12.9451 16.5628L13.5612 18.1029C13.7714 18.6284 13.3843 19.2 12.8184 19.2H11.1816C10.6157 19.2 10.2286 18.6284 10.4388 18.1029L11.0549 16.5628C10.4269 16.2255 10 15.5625 10 14.7998C10 13.6952 10.8954 12.7998 12 12.7998C13.1046 12.7998 14 13.6952 14 14.7998Z" fill="#039685"></path>
                </svg>
                <span slot="right" class="rigthOption" @click="showSetPWD">{{ $t("Refer") }}</span>
            </SettingInput>
            <SettingInput style="margin-bottom:1rem;" type="disabled">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7V9H19C20.1046 9 21 9.89543 21 11V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V11C3 9.89543 3.89543 9 5 9H6V7ZM16 7V9H8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 14.7998C14 15.5625 13.5731 16.2255 12.9451 16.5628L13.5612 18.1029C13.7714 18.6284 13.3843 19.2 12.8184 19.2H11.1816C10.6157 19.2 10.2286 18.6284 10.4388 18.1029L11.0549 16.5628C10.4269 16.2255 10 15.5625 10 14.7998C10 13.6952 10.8954 12.7998 12 12.7998C13.1046 12.7998 14 13.6952 14 14.7998Z" fill="#039685"></path>
                </svg>
                <span slot="right" class="rigthOption" @click="showSetBPWD">{{ $t("Refer") }}</span>
            </SettingInput>
            <SettingInput style="margin-bottom:1rem;" placeholder="Email" type="disabled" :value="$store.state.user.user.email">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.1667 20.25H2.83333C2.561 20.25 2.30253 20.1906 2.07017 20.0841L9.50372 12.6506L10.0936 12.982C11.1587 13.5805 12.4612 13.57 13.5166 12.9544L14.3775 12.4522C14.4162 12.5179 14.4637 12.5798 14.5201 12.6362L21.9558 20.0719C21.7168 20.1861 21.4492 20.25 21.1667 20.25Z" fill="#9D9D9D"></path>
                    <path d="M22.9971 18.5205C22.999 18.4861 23 18.4515 23 18.4167V7.4228L15.9889 11.5123L22.9971 18.5205Z" fill="#9D9D9D"></path>
                    <path d="M7.8437 11.7178L1 7.87261V18.4167C1 18.4636 1.00177 18.5102 1.00524 18.5563L7.8437 11.7178Z" fill="#9D9D9D"></path>
                    <path d="M1 5.58333V5.76971L10.9916 11.3837C11.4899 11.6636 12.0992 11.6587 12.5928 11.3708L22.9801 5.31202C22.8489 4.42815 22.087 3.75 21.1667 3.75H2.83333C1.82081 3.75 1 4.57081 1 5.58333Z" fill="#9D9D9D"></path>
                </svg>
                <span slot="right" class="rigthOption" @click="showEmail">{{ $t("Refer") }}</span>
            </SettingInput>
            <span class="tips">
                {{ $t("SelectBirthdayTips") }}
            </span>
            <div class="birthdayBox">
                <SettingInput :value.sync="day" :placeholder="$t('Days')" :type="saveBirthdayVisible ? 'disabledi' : 'disabled'" style="margin-right:1rem" :inputvalidate="DateValidateInput">
                </SettingInput>
                <SettingInput :value.sync="month" type="disabled" :placeholder="$t('Month')" style="margin-right:1rem" :click="showMonth">
                    <svg class="rightSvg" slot="right" width="11" height="40" viewBox="0 0 11 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_342481)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.669908 10.9844C0.962801 10.6915 1.43768 10.6915 1.73057 10.9844L10.7462 20L1.73057 29.0156C1.43768 29.3085 0.962801 29.3085 0.669908 29.0156C0.377015 28.7227 0.377015 28.2478 0.669908 27.955L8.62486 20L0.669908 12.045C0.377015 11.7522 0.377015 11.2773 0.669908 10.9844Z" fill="#2FA33F"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_1_342481">
                                <rect width="11" height="40" rx="5.5" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </SettingInput>
                <SettingInput :value.sync="year" :placeholder="$t('Year')" :type="saveBirthdayVisible ? 'disabledi' : 'disabled'" :inputvalidate="YearValidateInput">
                </SettingInput>
            </div>
            <div class="settingOptionBox">
                <div class="returnButton" @click="goBack">
                    {{ $t("Return") }}
                </div>
                <div v-show="saveBirthdayVisible" class="saveButton" @click="saveBirthday" :style="canPush ? '' : 'filter: brightness(80%);'">
                    {{ $t("Save") }}
                    <div style="margin-left:0.3rem;transform:scale(0.6)" v-loading="isPush"></div>
                </div>
            </div>
        </div>
        <Modal :visible.sync="setCPF" :gradient="false">
            <SetCPF :visible.sync="setCPF"></SetCPF>
        </Modal>
        
        <Modal
            :visible.sync="setPWD"
            :title="$t('ChangePassword')"
        >
            <div class="passwordBox">
                <div class="InputBox">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="current" :type="currentPwd" :placeholder="$t('CurrentPassword')"/>
                    </div>
                    <template v-if="currentPwd == 'password'">
                        <svg @click="currentPwd = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="currentPwd == 'text'">
                        <svg @click="currentPwd = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="newp" :type="newPwd" :placeholder="$t('NewPassword')"/>
                    </div>
                    <template v-if="newPwd == 'password'">
                        <svg @click="newPwd = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="newPwd == 'text'">
                        <svg @click="newPwd = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="confirmP" :type="contirmPwd" :placeholder="$t('ConfirmNewPassword')"/>
                    </div>
                    <template v-if="contirmPwd == 'password'">
                        <svg @click="contirmPwd = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="contirmPwd == 'text'">
                        <svg @click="contirmPwd = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="queryChangeButton buttonTransition" @click="confirmUserPassword">
                    {{ $t("ConfirmChange") }}
                </div>
            </div>
        </Modal>

        <Modal :visible.sync="bsetVisible" :gradient="false" :title="$t('ChangeTransactionPassword')" titlec="var(--gdient2)">
            <ChangeTranscationModal :visible.sync="bsetVisible" v-if="!isFirst"></ChangeTranscationModal>
            <SetTranscationModal :visible.sync="bsetVisible" v-else></SetTranscationModal>
        </Modal>

        <Modal :visible.sync="setEmail" :gradient="false">
            <SetEmail :visible.sync="setEmail"></SetEmail>
        </Modal>

        <MonthSelect :visible.sync="monthVisible" :value.sync="month"></MonthSelect>
    </div>
</template>

<script>

    import Level1 from "../assets/img/level1.png";
    import LevelBox from "../assets/img/levelBox.png";
    import { SetBirthday } from "@/api/setting.js";

    const imageMap = {
        1: require('@/assets/simplewheel/1.png'),
        2: require('@/assets/simplewheel/2.png'),
        3: require('@/assets/simplewheel/3.png'),
        4: require('@/assets/simplewheel/4.png'),
        5: require('@/assets/simplewheel/5.png'),
        6: require('@/assets/simplewheel/6.png'),
        7: require('@/assets/simplewheel/7.png'),
        8: require('@/assets/simplewheel/8.png'),
        9: require('@/assets/simplewheel/9.png'),
        10: require('@/assets/simplewheel/10.png'),
        11: require('@/assets/simplewheel/11.png'),
        12: require('@/assets/simplewheel/12.png'),
        // 添加更多映射
    };
    // 根据avatar获取图片
    const getAvatarImage = (avatar) => imageMap[avatar] || imageMap[1];

    import SettingInput from "@/components/SettingInput.vue";
    import Modal from "@/components/Modal.vue";
    import SetCPF from "@/components/SetCPF.vue";
    import SetEmail from "@/components/SetEmail.vue";
    import ChangeTranscationModal from '@/components/ChangeTranscationModal.vue';
    import SetTranscationModal from '@/components/SetTranscationModal.vue';
    import MonthSelect from "@/components/MonthSelect.vue";
    import { GetTranscationInfo } from "@/api/deposit";

    export default {
        name:"Setting",
        data(){
            return {
                Level1,
                LevelBox,

                setCPF:false,

                setPWD:false,
                currentPwd: "password",
                newPwd: "password",
                contirmPwd: "password",
                current: '', // 当前密码
                newp: "", // 新密码
                confirmP: "", // 新密码确认密码

                // 以下是交易密码的设置form
                bsetVisible:false,
                isFirst:"", // 是否是第一次设置交易密码

                setEmail:false,

                year:"",
                month:"",
                day:"",
                isPush:false,
                monthVisible:false,

                saveBirthdayVisible:true, // 默认显示，如果有生日就不显示
            }
        },
        components:{
            SettingInput,
            Modal,
            SetCPF,
            SetEmail,
            MonthSelect,
            ChangeTranscationModal,
            SetTranscationModal
        },
        computed:{
            HeaderPic() {
                return getAvatarImage(this.$store.state.user.avatar);
            },
            canPush(){
                return this.year && this.month && this.day && this.year.length == 4;
            }
        },
        created(){
            this.checkTranscationStatus();
        },
        mounted(){
            if(this.$store.state.user.user.birthday){
                this.saveBirthdayVisible = false;
                this.year = this.$store.state.user.user.birthday.split("-")[0];
                this.month = this.$store.state.user.user.birthday.split("-")[1];
                this.day = this.$store.state.user.user.birthday.split("-")[2];
            }
        },
        methods:{
            goBack(){
                this.$router.go(-1);
            },
            saveBirthday(){
                if(this.canPush){
                    if(!this.isPush){
                        let data = {
                            birthday:""
                        }
                        // 补零处理
                        const formattedDay = this.day < 10 ? `0${this.day}` : `${this.day}`;
                        data.birthday = `${this.year}-${this.month}-${formattedDay}`;

                        SetBirthday(data).then(res => {
                            if(res.code == 200){
                                this.$message(this.$t("SaveBirthdaySuccess"));
                                this.$store.dispatch("setBirthday",data.birthday);
                                this.saveBirthdayVisible = false;
                            }else{
                                this.$message(this.$t("SaveBirthdayFailed"))
                            }
                        })
                    }
                }
            },
            YearValidateInput(event) {
                let inputValue = event.target.value;
                // 只允许输入数字且限制为4位
                if (!/^\d{0,4}$/.test(inputValue)) {
                    inputValue = inputValue.slice(0, 4); // 保留前4位数字
                }
                event.target.value = inputValue;
                this.$emit('input', inputValue); // 更新 v-model
                this.year = inputValue;
            },
            DateValidateInput(event) {
                let inputValue = event.target.value;
                // 只允许输入数字且限制为1到2位
                if (!/^\d{0,2}$/.test(inputValue)) {
                    inputValue = inputValue.slice(0, 2); // 保留前2位数字
                }
                let date;
                // 将日期限制在1到31之间
                if(inputValue == ''){
                    date = 0;
                }else{
                    date = parseInt(inputValue, 10);
                }
                if (date > 31) {
                    date = 31;
                } else if (date < 1 && inputValue !== '') {
                    date = 1;
                }
                // 补零处理
                event.target.value = date;
                this.$emit('input', date); // 更新 v-model
                this.day = date;
            },
            toVerfity(){
                if(!this.$store.state.user.user.phone){
                    this.$router.push({
                        path:"/validate",
                        query:{
                            target:"/setphone"
                        }
                    })
                }
            },
            showEmail(){
                this.setEmail = true;
            },
            showSetPWD(){
                this.setPWD = true;
            },
            showSetBPWD(){
                this.bsetVisible = true;
            },
            showSetCPF(){
                if(!this.$store.state.user.user.idcard){
                    this.setCPF = true;
                }
            },
            showMonth(){
                if(this.saveBirthdayVisible){
                    this.monthVisible = true;
                }
            },
            confirmUserPassword(){

            },
            checkTranscationStatus(){ // 检查交易状态
                GetTranscationInfo().then(res => {
                    if(res.code == 200){
                        this.isFirst = !res.data.hasSetTransactionPassword;
                    }
                })
            }
        }
    }
</script>

<style scoped>

.queryChangeButton {
    background: var(--gdient2);
    color: #fff;
    font-size: 1.7rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    margin-top: 3rem;
    font-weight: bold;
}

.passwordBox {
    box-sizing: border-box;
    padding-top: 3rem;
}

.selectBox svg {
    height: 3rem;
    width: 2.5rem;
}

.selectBox span {
    color: var(--subfont);
    margin-left: .5rem;
    font-size: 1.5rem;
}

.selectBox {
    display: flex;
    align-items: center;
}

.selectBox path {
    fill:var(--subfont);
}

.InputBox {
    height: 5.5rem;
    background: var(--subbackground);
    width: 100%;
    border-radius: 1rem;
    display: flex;
    box-sizing: border-box;
    padding: 0 2rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.InputBox > div {
    width:100%;
}

.InputBox input::placeholder {
    color: var(--subfont);
    font-weight: bold;
}

.InputBox input {
    color: #fff;
    font-size: 1.5rem;
    background: none;
    outline: none;
    border: none;
    margin-left: 1rem;
    flex:1;
}

.InputBox > svg {
    height: 2rem;
    width: 2rem;
}

.InputBox path {
    fill:var(--subfont);
}

</style>

<style scoped>

    .saveButton{
        box-sizing:border-box;
        overflow:hidden;
        background:var(--gdient2);
        border-radius:1rem;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        font-weight:bold;
        width:100%;
        height:4rem;
        margin-left:1rem;
    }

    .returnButton{
        width:100%;
        box-sizing:border-box;
        border:0.05rem solid var(--gdient2);
        border-radius:1rem;
        color:var(--gdient2);
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        font-weight:bold;
        overflow:hidden;
        height:4rem;
    }

    .settingOptionBox{
        display:flex;
        width:100%;
        margin-top:2rem;
    }

    .birthdayBox{
        display:flex;
        align-items: center;
        margin-top:1rem;
        width:100%;
    }

    .tips{
        color:var(--basic);
        font-weight:bold;
        font-size:1.3rem;
    }

    .rigthOption{
        font-size:1.5rem;
        color:var(--gdient2);
        font-weight:bold;
    }

    .brownSvg path {
        fill:var(--subfont);
    }
    .brownSvg{
        margin-right:1rem;
    }

    .rightSvg{
        width:0.8rem;
        transform:rotate(90deg)
    }

    .rightSvg path{
        fill:var(--subfont);
    }

    .profileText > span:nth-child(2){
        margin-top:0.5rem;
    }
    
    .profileText{
        display:flex;
        flex-direction: column;
        font-size:1.5rem;
        color:var(--basic);
        font-weight:bold;
        margin-left:3rem;
    }

    .levelText {
        color:#fff;
        font-size: 1.1rem;
        font-weight: bold;
        transform:rotate(-90deg);
    }
    .level1 {
        width: 100%;
        position: absolute;
        left: 0rem;
        top: 0rem;
        transform: scale(1.03);
    }

    .levelTextBox{
        position:absolute;
        bottom:-0.5rem;
        right:-0.5rem;
        width: 4rem;
        height: 3.8rem;
        background-repeat:no-repeat;
        background-size:100% 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
    }
    .headPic {
        width: 100%;
        height: 100%;
    }
    .profilePicture {
        height: 8rem;
        width: 8rem;
        position: relative;
    }
    .box {
        background: var(--background);
        box-sizing: border-box;
        padding-top: 8rem;
        padding-bottom: 8rem;
        box-sizing: border-box;
        padding: 2rem 2rem;
        padding-bottom:30rem;
    }

    .settingHeader{
        height:fit-content;
        display:flex;
        align-items: center;
        margin-bottom:2rem;
    }
</style>