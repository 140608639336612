<template>
    <div class="box">
        <div class="circle-container">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
        </div>
    </div>
</template>

<script>

    import EventBus from "@/main";

    export default {
        name:"Loading",
        data(){
            return {
            }
        },
        methods:{

        },
        mounted(){
            EventBus.$on('checkAdv', () => {
                setTimeout(() => {
                    this.$router.push("/home")
                },1000)
            });
        }
    }
</script>

<style scoped>
    .circle-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .circle {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        margin: 0 1rem;
        animation: fadeInOut 2s infinite ease-in-out;
        opacity: 0; /* 初始状态为隐藏 */
    }
    @keyframes fadeInOut {
        0% { opacity: 0; }
        25% { opacity: 1; }
        75% { opacity: 1; }
        100% { opacity: 0; }
    }
    .circle:nth-child(1) { background-color: var(--circle1); animation-delay: 0s; }
    .circle:nth-child(2) { background-color: var(--circle2); animation-delay: 0.2s; }
    .circle:nth-child(3) { background-color: var(--circle3); animation-delay: 0.4s; }
    .circle:nth-child(4) { background-color: var(--circle4); animation-delay: 0.6s; }
    .circle:nth-child(5) { background-color: var(--circle5); animation-delay: 0.8s; }
    .circle:nth-child(6) { background-color: var(--circle6); animation-delay: 1s; }
    .circle:nth-child(7) { background-color: var(--circle7); animation-delay: 1.2s; }
    .box{
        box-sizing:border-box;
        width:100%;
        height:100vh;
        background:var(--background);
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-between;
    }
</style>