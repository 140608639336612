<template>
    <div class="mainLoading">
    </div>
</template>

<script>
    export default {
        name:"MainLoading",
        data(){
            return {

            }
        },
        methods:{

        }
    }
</script>

<style scoped>
    .mainLoading{
        position:absolute;
        z-index:9999999;
        top:0;
        left:0;
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
    }
</style>