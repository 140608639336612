import Vue from "vue"
import VueRouter from "vue-router";
import Home from "../view/Home.vue";
import Promotion from "../view/Promotion.vue";
import Refer from "../view/Refer.vue";
import ReferC from "../view/ReferCom.vue";
import ReferRef from "../view/ReferRef.vue";
import ReferMember from "../view/ReferMember.vue";
import ReferBonus from "../view/ReferBonus.vue";

import Inbox from "../view/Inbox.vue";
import Mine from "../view/Mine.vue";

import BetBonus from "../view/BetBonus.vue"
import Login from "../view/Login.vue"
import Deposit from "../view/Deposit.vue"

import Help from "../view/Help.vue";
import Service from "../view/Service.vue"
import Activity from "../view/Activity.vue"
import VIP from "../view/VIP.vue"

import Settings from "../view/Settings.vue"
import Setting from "@/view/Setting.vue";
import Region from "../view/Region.vue"
import Load from "../view/Load.vue"
import Loading from "../view/Loading.vue"
import Other from "../view/Other.vue";
import Classification from "../view/Classification.vue"
import CashWheel from "../view/CashWheel.vue";
import Report from "@/view/Report.vue";
import Validate from "@/view/Validate.vue";
import SetPhone from "@/view/SetPhone.vue"
import Agreement from "@/view/Agreement.vue";

import store from '../store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter(
    {
        routes: [
            {
                path: "/load",
                name: "Load",
                component: Load
            },
            {
                path: "/home",
                name: "Home",
                component: Home,
                meta: { requiresRefresh: true }
            },
            {
                path: "/promotion",
                name: "Promotion",
                component: Promotion
            },
            {
                path: "/refer",
                name: "Refer",
                component: Refer,
                children: [
                    {
                        path: 'invite',
                        name: "ReferC",
                        component: ReferC
                    },
                    {
                        path: 'referc',
                        name: "ReferRef",
                        component: ReferRef
                    },
                    {
                        path: 'member',
                        name: "ReferMember",
                        component: ReferMember
                    },
                    {
                        path: 'bonus',
                        name: "ReferBonus",
                        component: ReferBonus 
                    }
                ]
            },
            {
                path: "/inbox",
                name: "Inbox",
                component: Inbox
            },
            {
                path: "/mine",
                name: "Mine",
                component: Mine
            },
            {
                path: "/betbonus",
                name: "BetBonus",
                component: BetBonus
            },
            {
                path: "/login",
                name: "Login",
                component: Login
            },
            {
                path: "/deposit",
                name: "Deposit",
                component: Deposit
            },
            {
                path: "/help",
                name: "Help",
                component: Help
            },
            {
                path: "/service",
                name: "Service",
                component: Service
            },
            {
                path: "/activity",
                name: "Activity",
                component: Activity
            },
            {
                path: "/VIP",
                name: "VIP",
                component: VIP
            },
            {
                path: "/setting",
                name: "Setting",
                component: Setting
            },
            {
                path:"/cashwheel",
                name:"CashWheel",
                component:CashWheel
            },
            {
                path: "/region",
                name: "Region",
                component: Region
            },
            {
                path: "/other",
                name: "Other",
                component: Other
            },
            {
                path: "/classification",
                name: "Classification",
                component: Classification
            },
            {
                path: "/report",
                name: "Report",
                component: Report
            },
            {
                path: "/",
                name: "Loading",
                component: Loading
            },
            {
                path: "/validate",
                name: "Validate",
                component: Validate
            },
            {
                path: "/setphone",
                name: "SetPhone",
                component: SetPhone
            },
            {
                path: "/agreement",
                name: "Agreement",
                component: Agreement
            },
        ]
    }
)

let whiteList = [
    "/",
    "/load",
    "/home",
    "/promotion",
    "/classification",
    "/login",
    "/activity",
    "/vip",
    "/agreement"
]

router.beforeEach((to, from, next) => {
    if (localStorage.getItem("token") && !localStorage.getItem("user")) {
        store.dispatch('GetInfo').then((res) => {
            if (localStorage.getItem("user")) {
                next({...to, replace: true}) // hack方法 确保addRoutes已完成
            } else {
                next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            }
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
                next({path: '/'})
            })
        })
    }
    if (document.getElementById('fadeBox')) {
        document.getElementById('fadeBox').classList.remove('fade-out');
    }
    if (from.path === '/inbox') {
        // document.getElementById('fadeBox').classList.add('fade-out');
        // setTimeout(() => {
        //     next();
        // }, 500);
        // return ;
    }
    if ((!localStorage.getItem("token")) && (whiteList.indexOf(to.path) === -1)) {
        next("/login")
    } else {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        next();
    }
})


export default router
