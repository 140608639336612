import Vuex from "vuex"
import user from "./user"
import Vue from "vue"

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        user
    }
})
