<template>
    <div class="simpleWheelout" v-show="visible">
        <div class="simpleWheel noselect">
            <transition name="fade">
                <div class="wheelBox" v-if="visible">
                </div>
            </transition>
            <transition name="fade">
                <div class="wheelBoxTop" v-if="visible">
                    <svg @click="toRank" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M781.226667 238.506667V180.906667c0-26.88-21.76-49.066667-49.066667-49.066667h-430.933333c-26.88 0-49.066667 21.76-49.066667 49.066667v55.466666C179.626667 248.32 123.733333 311.893333 123.733333 387.84c0 83.626667 67.413333 151.893333 150.613334 153.6 40.96 93.44 133.973333 158.72 242.346666 158.72 108.8 0 201.813333-65.706667 242.773334-159.146667 78.506667-6.4 140.8-72.96 140.8-153.173333 0-72.533333-51.2-133.546667-119.04-149.333333zM192 387.84c0-38.4 25.6-70.826667 60.16-81.493333v133.546666c0 10.24 0.853333 20.053333 2.133333 29.866667-35.84-9.386667-62.293333-42.666667-62.293333-81.92z m450.986667-1.28l-49.493334 51.2 11.52 72.106667c3.84 24.746667-9.386667 34.133333-30.293333 22.613333l-61.013333-36.266667-61.013334 34.133334c-20.906667 11.52-34.133333 1.706667-30.293333-22.613334l11.52-72.106666-49.493333-51.2c-17.066667-17.066667-11.52-34.133333 11.52-37.973334l68.266666-9.386666 30.293334-62.72c9.386667-22.613333 26.453333-22.613333 37.973333 0l30.293333 64.426666 68.266667 9.386667c23.466667 4.266667 29.013333 21.333333 11.946667 38.4z m136.533333 80.213333c0.853333-8.96 1.706667-17.493333 1.706667-26.453333V310.186667c29.866667 13.226667 50.773333 43.093333 50.773333 78.08 0 34.986667-21.76 65.706667-52.48 78.506666zM640 892.16H384c-18.773333 0-34.133333-15.36-34.133333-34.133333s15.36-34.133333 34.133333-34.133334h256c18.773333 0 34.133333 15.36 34.133333 34.133334s-15.36 34.133333-34.133333 34.133333z" fill="#bdd3fb">
                        </path>
                        <path d="M477.866667 689.493333h68.266666v153.6h-68.266666z" fill="#bdd3fb">
                        </path>
                    </svg>
                    <div class="wheelBoxTopRight">
                        <svg @click="toHelp" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M511.998 16.512c-273.649 0-495.482 221.837-495.482 495.486 0 273.651 221.833 495.49 495.482 495.49 273.653 0 495.486-221.839 495.486-495.488 0-273.649-221.831-495.488-495.486-495.488zM560.084 797.914c0 13.682-11.089 24.776-24.774 24.776h-74.323c-13.679 0-24.772-11.093-24.772-24.776v-74.321c0-13.686 11.093-24.778 24.772-24.778h74.323c13.684 0 24.774 11.091 24.774 24.778v74.321zM682.891 456.897c-9.958 14.199-32.561 32.291-60.858 54.35l-31.359 21.64c-15.23 11.814-28.738 25.568-33.733 41.315-1.707 5.365-2.986 14.183-3.847 23.706-0.434 4.792-4.721 14.568-14.741 14.568-24.551 0-71.341 0-80.651 0-13.109 0-15.451-10.268-15.232-15.291 1.451-32.919 4.468-62.144 17.88-77.878 27.155-31.839 88.943-71.469 88.943-71.469 9.407-7.099 17.023-14.816 22.783-23.226 10.471-14.438 19.158-30.294 19.158-47.626 0-19.921-5.824-38.079-17.51-54.515-11.646-16.371-32.979-24.573-63.891-24.573-30.43 0-52.001 10.1-64.716 30.291-9.393 14.918-15.307 28.634-17.756 43.558-0.871 5.282-4.258 16.407-15.548 16.407-23.854 0-67.833 0-78.66 0-16.749 0-20.437-10.854-19.953-16.086 6.063-65.94 31.831-110.993 77.393-139.922 30.981-19.918 69.097-29.913 114.31-29.913 59.41 0 108.726 14.162 148.043 42.527 39.247 28.326 58.927 70.299 58.927 125.952 0.004 34.082-11.958 62.822-28.98 86.185z" fill="#bdd3fb"></path>
                        </svg>
                        <svg @click="closeModal" style="margin-left:2rem;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M623.807 528.693a113.756 113.756 0 0 0-0.139-33.126l252.787-252.692c26.245-26.236 26.245-68.772 0-95.006-26.248-26.236-68.798-26.236-95.043 0L528.281 400.902c-5.296-0.762-10.708-1.164-16.216-1.164s-10.92 0.402-16.216 1.164l-253.13-253.031c-26.245-26.236-68.796-26.236-95.043 0-26.245 26.234-26.245 68.77 0 95.006l252.789 252.692a113.726 113.726 0 0 0-0.14 33.124L147.676 781.247c-26.245 26.236-26.245 68.77 0 95.006 26.248 26.236 68.798 26.236 95.043 0L494.85 624.218c5.613 0.859 11.362 1.305 17.215 1.305s11.602-0.446 17.215-1.305l252.13 252.035c26.245 26.236 68.796 26.236 95.043 0 26.245-26.236 26.245-68.77 0-95.006L623.807 528.693z" fill="#bdd3fb"></path><path d="M512.063 444.326c37.407 0 67.73 30.327 67.73 67.736 0 37.41-30.323 67.735-67.73 67.735-37.405 0-67.728-30.325-67.728-67.735 0.001-37.408 30.323-67.736 67.728-67.736z" fill="#bdd3fb"></path></svg>
                    </div>
                </div>
            </transition>
            <transition name="rotate-slide">
                <div class="wheelTop" v-if="visible" ref="wheelTop">
                    <img class="simplebg1" :src="SimpleWheelBg" />
                    <div v-show="!isResult" style="width:100%;height:100%;position:absolute;top:0;left:0;transition:all 0.5s !important;transform:scale(1)" ref="wheelText">
                        <img class="simplebg2" :src="SimpleWheelInner" />
                        <img class="rotateImg simpleani1" :src="SimpleWheel1" />
                        <img class="rotateImg simpleani1" :src="SimpleWheel1" />
                        <img class="rotateImg simpleani1" :src="SimpleWheel1" />
                        <img class="rotateImg simpleani2" :src="SimpleWheel2" />
                        <img class="rotateImg simpleani2" :src="SimpleWheel2" />
                        <img class="rotateImg simpleani2" :src="SimpleWheel2" />
                        <div class="wheelText" ref="wheel"></div>
                        <div class="wheelPointer">
                            <img :src="WheelPointer" />
                            <span v-text="remainingParticipation"></span>
                        </div>
                    </div>
                    <div v-show="isResult" style="width:100%;height:100%;position:absolute;top:0;left:0;background: radial-gradient(circle, #1f1aaf 0%, transparent 50%);" ref="wheelResult">
                        <img :src="YouWin" 
                            style="
                            position: absolute;
                            left: 50%;
                            transform: translate(-50%, 0%);
                            height: 8rem;
                            opacity: 0;
                            transition:all 2s !important;
                            top: 9rem;" 
                            ref="youwin"
                        />
                        <img :src="Design" 
                            style="
                                position: absolute;
                                left: 0%;
                                right: 0px;
                                width: 100%;
                                top: 4rem;
                                opacity: 0;
                                /* z-index:2; */
                            "
                            ref="resultd"
                        />
                        <div ref="rresult" style="transition:all 1s !important;opacity: 0;position:absolute;height:5rem;background:rgba(0,0,0,0.4);border-radius:3rem;display:flex;align-items: center;left:50%;
                            transform: translate(-50%, 0%);top:18rem;width:20rem;justify-content: center;">
                            <img :src="result.fullPrizeImage" style="height:3rem;" />
                            <span style="font-size:1.6rem;font-weight:bold;color:#fff;margin-left:1rem;" v-text="result.prizeValue"></span>
                        </div>
                        <span ref="tips" style="transition:all 1s !important;opacity: 0;position:absolute;left:50%;transform: translate(-50%, 0%);top:25rem;text-align:center;width:22rem;color:#fff;font-size:1rem;font-weight:bold;">
                            {{ $t("CashWheelCongratulations") }}
                        </span>
                        <div @click="closeResult" ref="rbutton" style="
                                position:absolute;
                                left:50%;
                                transform: translate(-50%, 0%);
                                top:29rem;
                                height:4rem;
                                width:16rem;
                                color:#fff;
                                display:flex;
                                align-items: center;
                                justify-content: center;
                                border-radius:1rem;
                                cursor:pointer;
                                font-size:1.3rem;
                                font-weight:bold;
                                background:#ca3042;
                                opacity: 0;
                                transition:all 1s !important;
                            ">
                            {{ $t("BackToSpin") }}
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="slide-right">
                <div class="wheelBottom" v-if="visible" ref="wheelBottom">
                    <img :src="SimpleWheel" />
                    <div :class="(remainingParticipation > 0 && !isParticipationing) ? 'wheelBottomButton' : 'wheelBottomButton disabled'" @click="(remainingParticipation > 0 && !isParticipationing) ? start() : test">
                        <template v-if="remainingParticipation > 0 && $store.state.user.token">
                            <span class="wheelBottomButtonText">{{ $t("SPIN") }}</span>
                            <div class="wheelButtonAnimate">
                                <div class="wheelButtonAnimateLeft">
                                </div>
                                <div class="wheelButtonAnimateRight">
                                </div>
                            </div>
                        </template> 
                        <template v-else>
                            <span class="wheelBottomButtonText disabled">{{ $t("SPIN") }}</span>
                        </template>
                    </div>
                    <span class="wheellabel" v-if="!$store.state.user.token">{{ $t("RegisterMustCashWheel") }}</span>
                    <span class="wheeltime">{{ formatStartTime }} - {{ formatEndTime }}</span>
                </div>
            </transition>
        </div>
        <div class="rankBox noselect" v-show="rankVisible">
            <svg @click="closeRank" style="transform: rotate(180deg);" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" class="icon">
                <path data-v-d4cc28d8="" d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z" fill="#8da5ca"></path>
            </svg>
            <div class="rankClassBox">
                <div @click="changeActive(index)" :class="item.active ? 'active rankClassItem' : 'rankClassItem'" v-for="(item,index) in rankClassList" :key="index" v-text="item.text">
                </div>
            </div>
            <div class="historyBox">
                <div class="historyHeaderBox">
                    <div class="historyHeaderItem" style="width:65%;">
                        {{ rankClassList[0].active ? $t("Player") : $t("Time") }}
                    </div>
                    <div class="historyHeaderItem" style="width:35%;">
                        {{ $t("Rewards") }}
                    </div>
                </div>
                <div class="rankTextBox" v-if="rankClassList[0].active">
                    <template v-if="rankMemberList.length != 0">
                        <transition-group name="fade2" tag="div">
                            <div class="historyBodyItem" v-for="item in rankMemberList" :key="item.id">
                                <div class="historyBodyItemLeft">
                                    <div class="historyBodyItemAvatar">
                                        <img :src="item.avatar" />
                                    </div>
                                    <span v-text="item.name"></span>
                                </div>
                                <div class="historyBodyItemRight">
                                    <img :src="item.rewardsimg" style="width:2rem;margin-right:.5rem;" />
                                    <span v-text="item.rewards"></span>
                                </div>
                            </div>
                        </transition-group>
                    </template>
                    <template v-else>
                        <Empty :color="'#334569'"></Empty>
                    </template>
                </div>
                <div class="rankTextBox2" v-else>
                    <template v-if="myRankMemberList.length != 0">
                        <div class="historyBodyItem" style="flex-direction: row;height:4rem" v-for="(item,index) in myRankMemberList" :key="index">
                            <div class="historyBodyItemLeft">
                                <span v-text="formatDate(item.createTime)"></span>
                            </div>
                            <div class="historyBodyItemRight">
                                <img :src="item.fullPrizeImage" style="width:2rem;margin-right:.5rem;" />
                                <span v-text="item.prizeName"></span>
                            </div>
                        </div>
                        <div class="">
                            <div class="loadMore2" @click="getSelfRewardList">
                                <i v-if="myRankLoding" class="el-icon-loading" style="margin-left:.5rem"></i>
                                {{ $t("LoadMore") }}
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <Empty :color="'#334569'"></Empty>
                    </template>
                </div>
            </div>
        </div>
        <div class="helpBox noselect" v-show="helpVisible">
            <div class="helpTopBox">
                <span style="padding:0 0.5rem"></span>
                <span>{{ $t("Help") }}</span>
                <svg @click="closeHelp" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" class="icon">
                    <path data-v-d4cc28d8="" d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z" fill="#8da5ca"></path>
                </svg>
            </div>
            <div class="helpTextBox">
                <div class="helpTextParagraph" v-html="rule">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import SimpleWheel from "@/assets/simplewheel.png";
    import SimpleWheelBg from "@/assets/wheelbg.png";
    import SimpleWheelInner from "@/assets/wheelinnerbg.png";
    import SimpleWheel1 from "@/assets/1.png";
    import SimpleWheel2 from "@/assets/2.png";
    import WheelPointer from "@/assets/wheelpointer.png";

    import Result from "@/assets/simplewheel/result.png";
    import YouWin from "@/assets/simplewheel/youwin.png";
    import Design from "@/assets/simplewheel/resultdesign.png";
    import A1 from "@/assets/simplewheel/1.png";
    import A2 from "@/assets/simplewheel/2.png";
    import A3 from "@/assets/simplewheel/3.png";
    import A4 from "@/assets/simplewheel/4.png";
    import A5 from "@/assets/simplewheel/5.png";
    import A6 from "@/assets/simplewheel/6.png";

    import Empty from "@/components/Empty.vue";

    import { LuckyDetails,Participation,RrontPage,GetParticipation } from "@/api/cashwheel.js";
    import { formatDate } from "@/utils/dateUtil"

    const avatarList = [A1,A2,A3,A4,A5,A6];

    // 从 avatarList 中随机选择一个头像
    function getRandomElement(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
    }
    // 随机生成一个字符串
    function getRandomString(length) {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }

    function weightedRandomChoice(items) {
        // 计算所有项的总权重
        const totalWeight = items.reduce((sum, item) => sum + item.weight, 0);
        
        // 生成一个 0 到 totalWeight 之间的随机数
        let random = Math.random() * totalWeight;
        
        // 遍历项，找到对应的项
        for (let item of items) {
            if (random < item.weight) {
                return item;
            }
            random -= item.weight;
        }
        
        // 在正常情况下不会到达这里
        return items[0];
    }

    function calculateWeight(prizeValue) {
        // 你可以根据实际需求调整这个函数
        return 1 / prizeValue;
    }

    export default {
        name:"SimpleWheel",
        components:{
            Empty
        },
        computed:{
            formatStartTime(){
                const date = new Date(this.startTime);
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
                return formattedDate
            },
            formatEndTime(){
                const date = new Date(this.endTime);
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
                return formattedDate
            }
        },
        props:{
            visible:{
                type:Boolean
            },
            activityId:{
                type:Number
            },
            rule:{
                type:String
            },
            startTime:{
                type:Number
            },
            endTime:{
                type:Number
            }
        },
        watch:{
            visible(val){
                if(!val){
                    clearInterval(this.rankInterval);
                }
                this.$nextTick(() => {
                    this.initWheel();
                    this.initRewards();
                })
            },
            activityId(){
                this.initRewards();
            }
        },
        data(){
            return {
                formatDate,
                SimpleWheel,
                SimpleWheelBg,
                SimpleWheelInner,
                SimpleWheel1,
                SimpleWheel2,
                WheelPointer,
                YouWin,
                Design,
                Result,
                rewardList:[],
                rankClassList:[
                    {
                        active:true,
                        text:this.$t("History")
                    },
                    {
                        active:false,
                        text:this.$t("MyRewards")
                    }
                ],
                rankMemberList:[],
                helpVisible:false,
                rankVisible:false,
                remainingParticipation:0, // 总抽奖次数
                dailyParticipationLimit:0, // 每日可抽奖次数
                todayParticipation:0, // 今日已抽奖次数
                previousId:0,
                canParticipation:true,
                
                result:{}, // 结果
                isResult:false,
                isParticipationing:false, // 正在抽奖中
                timeoutList:[],
                rankInterval:null,

                myRankMemberList:[],
                myRankLoding:false,
                page:1,
                size:10
            }
        },
        methods:{
            generateObject() {
                const id = 'id-' + Date.now() + '-' + Math.floor(Math.random() * 10000); // 当前时间戳作为 id
                const avatar = getRandomElement(avatarList); // 从 avatarList 中随机选择一个头像
                const name = `${getRandomString(Math.floor(Math.random() * 4) + 3)}****${getRandomString(Math.floor(Math.random() * 4) + 3)}`; // 生成一个符合格式的名字

                // 为 rewardList 中的每个项计算权重
                const weightedList = this.rewardList.map(item => ({
                    ...item,
                    weight: calculateWeight(item.prizeValue)
                }));

                // 使用加权随机选择函数从 weightedList 中选择一个项
                const selectedItem = weightedRandomChoice(weightedList);
                const rewards = selectedItem.prizeValue; // 选择的项的奖品值
                const rewardsimg = selectedItem.fullPrizeImage; // 选择项的图片值

                return {
                    rewardsimg,
                    id,
                    avatar,
                    name,
                    rewards
                };
            },
            test(){
            },
            toHelp(){
                this.helpVisible = true;
            },
            closeHelp(){
                this.helpVisible = false;
            },
            getSelfRewardList(){
                if(!this.myRankLoding){
                    this.myRankLoding = true;
                    let data = {
                        activityId:this.activityId,
                        pageSize:this.size,
                        currentPage:this.page
                    }
                    RrontPage(data).then(res => {
                        if(res.data.records.length == 0){
                            this.$message(this.$t("TheEnd"));
                            this.myRankLoding = false;
                            return ;
                        }
                        this.myRankMemberList = [...this.myRankMemberList,...res.data.records];
                        this.page += 1;
                        this.myRankLoding = false;
                    }).catch(err => {
                        this.myRankLoding = false;
                    })
                }
            },
            toRank(){
                this.myRankMemberList = [];
                this.page = 1;
                if(this.rewardList.length == 0){
                    return ;
                }
                this.rankVisible = true;
                if(this.rankMemberList.length <= 0){
                    this.rankMemberList = [];
                    for(let i=0;i<8;i++){
                        this.rankMemberList.push(this.generateObject());
                    }
                }
                this.rankInterval = setInterval(() => {
                    const newItem = this.generateObject();
                    setTimeout(() => {
                        this.rankMemberList.pop();
                        setTimeout(() => {
                            this.rankMemberList.unshift(newItem);
                        },700)
                    },2000)
                },2000)
                if(this.$store.state.user.token){
                    this.getSelfRewardList();
                }
            },
            closeRank(){
                this.rankVisible = false;
                clearInterval(this.rankInterval);
            },
            closeModal(){
                this.resetModal();
                this.$emit("update:visible",false)
            },
            changeActive(index){
                this.rankClassList.forEach(item => {
                    item.active = false;
                })
                this.rankClassList[index].active = true;
            },
            async initRewards(){
                let res = await LuckyDetails({activityId:this.activityId});
                if(this.$store.state.user.token){ // 如果token有的话
                    let res2 = await GetParticipation({activityId:this.activityId});
                    this.remainingParticipation = res2.data.remainingParticipation;
                    this.dailyParticipationLimit = res.data.dailyParticipationLimit;
                    this.todayParticipation = res2.data.todayParticipation;
                }else{
                    this.remainingParticipation = 0;
                    this.todayParticipation = 0;
                }
                this.rewardList = res.data;
                this.initWheel();
                this.$forceUpdate();
            },
            initWheel(){
                this.$nextTick(() => {
                    const wheel = this.$refs.wheel
                    const numSegments = this.rewardList.length;
                    const angle = 360 / numSegments;
                    
                    // 清空容器中的现有内容
                    if(wheel){
                        wheel.innerHTML = '';
                    }

                    function getClipPercentage(numSegments) {
                        const predefinedPercentages = {
                            8:58,
                            9:53,
                            10:49,
                            11:45,
                            12:42,
                            13:39,
                            14:37,
                            15:35,
                            16:33
                        };

                        return predefinedPercentages[numSegments] || 50; // 默认值 50，防止出现意外情况
                    }
                    this.previousId = this.rewardList[1].id;

                    for (let i = 0; i < numSegments; i++) {

                        const segment = document.createElement('div');
                        segment.id = this.rewardList[i].id;
                        segment.className = 'segment';
                        // 动态设置 clip-path
                        // const clipPercentage = 58 - (numSegments - 8) * 3.8;/* 8个58% 9个53% 10个 49% 11个45% 12个42% 13个39% */
                        const clipPercentage = getClipPercentage(numSegments); 
                        segment.style.clipPath = `polygon(0 0,${clipPercentage}% 0,100% 100%,0 ${clipPercentage}%)`;
                        // 9个是加5
                        // let seq = numSegments - 4;
                        segment.style.transform = `rotate(${i * angle + 13}deg)`;
                        if(i == 1){
                            segment.style.background = '#3886ff';
                        }
                        const div = document.createElement('div');
                        const img = document.createElement('img');
                        img.src = this.rewardList[i].fullPrizeImage;
                        
                        const span = document.createElement('span');
                        span.innerText = this.rewardList[i].name;

                        div.appendChild(img);
                        div.appendChild(span);
                        segment.appendChild(div);
                        wheel.appendChild(segment);
                    }
                })
            },
            start(){ // 开始抽奖，在开始抽奖之前得先执行一段动画
                // 底部块划出去
                if(this.visible){

                    if(this.todayParticipation >= this.dailyParticipationLimit){
                        this.$message(this.$t("CashWheelEnough"));
                        return ;
                    }

                    let bottom = this.$refs.wheelBottom;
                    let top = this.$refs.wheelTop
                    top.classList.remove('wheel-to-top-ani');
                    bottom.classList.remove('wheel-to-bottom-ani');
                    this.timeoutList.push(setTimeout(() => {
                        top.classList.add('wheel-to-center');   
                        top.classList.add('wheel-animate2');
                        bottom.classList.add('wheel-to-bottom');
                    },1))

                    this.isParticipationing = true;
                    this.timeoutList.push(
                        setTimeout(() => {
                            this.toParticipation();
                        },2000)
                    )
                }
            },
            toParticipation(){ // 参与抽奖
                if(this.visible){
                    if(this.canParticipation){
                    
                        if(document.getElementById(this.previousId)){
                            document.getElementById(this.previousId).style.background = 'linear-gradient(to right, #5533da, #572de2)';
                        }

                        this.canParticipation = false;

                        const box = this.$refs.wheel;
                        // 重置 transform 和 transition 样式
                        box.style.transition = 'none';
                        box.style.transform = 'rotate(0deg)';

                        // 强制重绘，确保样式重置生效
                        box.offsetHeight; // 触发浏览器重绘
                        // 开始旋转
                        box.classList.add('spin-rotate');

                        Participation({activityId:this.activityId}).then(res => {
                            // 接口成功返回后的逻辑
                            this.stopSpin(res.data.id); // 假设 targetId 是返回的奖品 id
                            this.result = res.data;
                            this.remainingParticipation -= 1;
                            this.todayParticipation += 1;
                        }).catch(error => {
                            this.canParticipation = true;
                        })
                    }
                }
            },
            stopSpin(targetId) {
                if(this.visible){
                    const box = this.$refs.wheel;
                    const targetIndex = this.rewardList.findIndex(item => item.id === targetId);
                    const anglePerSlice = 360 / this.rewardList.length;
                    const baseAngle = 360 - (targetIndex * anglePerSlice);
                    const targetAngle = baseAngle + (360 / this.rewardList.length); // 调整为 -25 度

                    // 移除旋转动画类
                    box.classList.remove('spin-rotate');

                    let currentRotation = this.getRotationAngle(box);
                    let startTime = null;
                    const duration = 7000; // 动画持续时间（毫秒）
                    const totalRotation = targetAngle + 3600; // 旋转多圈以保证足够的旋转感

                    function animate(time) {
                        if (!startTime) startTime = time;
                        const elapsed = time - startTime;
                        const progress = Math.min(elapsed / duration, 1); // 进度从 0 到 1

                        // 使用缓动函数调整旋转速度
                        const easedProgress = 1 - Math.pow(1 - progress, 2); // 缓动效果

                        // 计算当前角度
                        const angle = currentRotation + easedProgress * (totalRotation - currentRotation);
                        box.style.transform = `rotate(${angle}deg)`;

                        if (progress < 1) {
                            requestAnimationFrame(animate);
                        } else {
                            // 确保最终角度精确
                            box.style.transform = `rotate(${targetAngle}deg)`;
                        }
                    }

                    requestAnimationFrame(animate);
                    
                    this.timeoutList.push(setTimeout(() => {
                        document.getElementById(targetId).style.background = '#3886ff';
                        this.previousId = targetId
                    },7000))

                    this.timeoutList.push(setTimeout(() => {
                        this.resultAnimate();
                    },7500))
                }
            },
            resultAnimate(){
                if(this.visible){
                    let wheelText = this.$refs.wheelText;
                    let youwin = this.$refs.youwin;
                    let resultd = this.$refs.resultd;
                    let tips = this.$refs.tips;
                    let rbutton = this.$refs.rbutton;
                    let rresult = this.$refs.rresult;
                    
                    wheelText.classList.add('wheel-to-small');
                    resultd.classList.remove("wheel-to-pulse-re");
                    rresult.classList.remove("wheel-to-pulse2-re");
                    this.timeoutList.push(setTimeout(() => {
                        this.isResult = true;
                        this.$nextTick(() => {
                            this.timeoutList.push(setTimeout(() => {
                                youwin.classList.add("wheel-to-opactiy");
                                resultd.classList.add("wheel-to-pulse");
                                this.timeoutList.push(setTimeout(() => {
                                    tips.classList.add("wheel-to-opactiy");
                                    this.timeoutList.push(setTimeout(() => {
                                        rbutton.classList.add("wheel-to-opactiy");
                                        rresult.classList.add("wheel-to-pulse2");
                                        this.timeoutList.push(setTimeout(() => {
                                            rbutton.classList.add("buttonTransition");
                                        },1000))
                                    },200))
                                },200))
                            },50))
                        })
                    },500))
                }
            },
            resetModal(){
                this.timeoutList.forEach(id => clearTimeout(id));
                this.canParticipation = true;
                this.result = {};
                this.isResult = false;
                this.isParticipationing = false;

                let wheelText = this.$refs.wheelText;
                let youwin = this.$refs.youwin;
                let resultd = this.$refs.resultd;
                let tips = this.$refs.tips;
                let rbutton = this.$refs.rbutton;
                let rresult = this.$refs.rresult;
                let bottom = this.$refs.wheelBottom;
                let top = this.$refs.wheelTop

                wheelText.className = '';
                youwin.className = '';
                resultd.className = '';
                tips.className = '';
                rbutton.className = '';
                rresult.className = '';
                bottom.className = 'wheelBottom';
                top.className = 'wheelTop';
            },
            closeResult(){ // 关闭结果
                if(this.visible){
                    let wheelText = this.$refs.wheelText;
                    let youwin = this.$refs.youwin;
                    let resultd = this.$refs.resultd;
                    let tips = this.$refs.tips;
                    let rbutton = this.$refs.rbutton;
                    let rresult = this.$refs.rresult;
                    let bottom = this.$refs.wheelBottom;
                    let top = this.$refs.wheelTop;

                    youwin.classList.remove("wheel-to-opactiy");
                    rbutton.classList.remove("wheel-to-opactiy");
                    tips.classList.remove("wheel-to-opactiy");
                    rresult.classList.remove("wheel-to-pulse2");
                    rresult.classList.add("wheel-to-pulse2-re");
                    resultd.classList.remove("wheel-to-pulse");
                    resultd.classList.add("wheel-to-pulse-re");

                    this.timeoutList.push(setTimeout(() => {
                        this.isResult = false;
                        this.$nextTick(() => {
                            this.timeoutList.push(setTimeout(() => {
                                wheelText.classList.remove('wheel-to-small');
                                this.timeoutList.push(setTimeout(() => {
                                    bottom.classList.remove('wheel-to-bottom');
                                    bottom.classList.add('wheel-to-bottom-ani');
                                    top.classList.remove("wheel-to-center");
                                    top.classList.add('wheel-to-top-ani');
                                    this.isParticipationing = false;
                                    this.canParticipation = true;
                                },500))
                            },50))
                        })
                    },1000))
                }
            },
            getRotationAngle(element) {
                const style = window.getComputedStyle(element);
                const transform = style.transform || style.webkitTransform || style.mozTransform;
                if (transform === 'none') return 0;
                const matrix = transform.match(/^matrix\((.+)\)$/);
                if (matrix) {
                const values = matrix[1].split(', ');
                const a = values[0];
                const b = values[1];
                const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
                return angle < 0 ? angle + 360 : angle;
                }
                return 0;
            },
        },
        created(){
            this.initRewards();
        },
        mounted(){
            // this.initWheel();
        },
        beforeDestroy(){
            clearInterval(this.rankInterval);
        }
    }
</script>

<style>

    .wheel-animate2{
        transition: all 2s !important;
    }

    .wheel-to-pulse2 {
        animation: pulse2 0.5s 1 forwards;
    }

    .wheel-to-pulse2-re {
        animation: pulse2-re 0.5s 1 forwards;
    }

    .wheel-to-pulse {
        animation: pulse 1s 1 forwards;
    }

    .wheel-to-pulse-re {
        animation: pulse-re 1s 1 forwards;
    }

    .wheel-to-bottom-ani{
        animation: wheel-to-bottom 1s 1 forwards;
    }

    .wheel-to-top-ani {
        animation: wheel-to-top 1s 1 forwards;
    }

    @keyframes wheel-to-top {
        0% {
            top:50%;
            margin-top:-20rem;
        }
        100% {
            top:0%;
            margin-top:0rem;
        }
    }

    @keyframes wheel-to-bottom {
        0% {
            bottom:-26rem;
            opacity: 0;
        }
        100% {
            opacity: 1;
            bottom:1rem;
        }
    }

    .wheel-to-none{
        opacity:0 !important;
    }

    .wheel-to-opactiy{
        opacity: 1 !important;
    }

    .wheel-to-small{
        transform: scale(0.05) !important;
    }

    .wheel-to-center {
        /* bottom:-40rem !important; */
        top:50% !important;
        margin-top:-20rem !important;
    }

    .wheel-to-bottom {
        bottom:-40rem !important;
        opacity: 0;
        transition:all 2s !important;
    }

    .spin-rotate {
        animation: spin-rotate 2s linear infinite;
    }

    @keyframes pulse2-re {
        0% {
            transform: scale(1) translate(-50%, 0%);
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: scale(0.1) translate(-50%, 0%);
        }
    }

    @keyframes pulse2 {
        0% {
            opacity: 0;
            transform: scale(0.1) translate(-50%, 0%);
        }
        100% {
            transform: scale(1) translate(-50%, 0%);
            opacity: 1;
        }
    }

    @keyframes pulse-re {
        0% {
            transform: scale(1.1);
            opacity: 1;
        }
        33% {
            transform: scale(0.7);
            opacity: 1;
        }
        66% {
            opacity: 1;
            transform: scale(0.9);
        }
        100% {
            opacity: 0;
            transform: scale(0.1);
        }
    }

    @keyframes pulse {
        0% {
            opacity: 0;
            transform: scale(0.1);
        }
        33% {
            opacity: 1;
            transform: scale(0.9);
        }
        66% {
            transform: scale(0.7);
            opacity: 1;
        }
        100% {
            transform: scale(1.1);
            opacity: 1;
        }
    }

    @keyframes spin-rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    .segment > div > img {
        width:2.5rem;
        margin-right:1.5rem;
        transform:rotate(-90deg);
    }
    
    .segment > div > span {
        font-size:1.2rem;
        font-weight:bold;
        color:#fff;
        text-shadow:3px 5px 2px rgba(0,0,0,0.15);
        display:block;
        width:6rem;
        overflow:hidden;
        height:fit-content;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight:bold;
    }

    .segment > div {
        display:flex;
        align-items: center;
        position: relative;
        transform: rotate(45deg);
        box-sizing: border-box;
        padding-left: 3rem;
    }

    .segment {
        position:absolute;
        width:50%;
        height:50%;
        background: linear-gradient(to right, #5533da, #572de2);
        /* segment.style.clipPath = `polygon(0 0,50% 0,100% 100%,0 50%)`; */
        /* clip-path: polygon(0 0,29% 0,100% 100%,0 29%); */
        transform-origin: bottom right;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;

        /* position: absolute;
        width: 50%;
        height: 50%;
        transform-origin: 100% 100%;
        clip-path: polygon(0 0, 86% 0, 100% 100%);
        display:flex;
        flex-direction: column; */
    }

</style>

<style scoped>

    .fade2-enter-active, .fade2-leave-active {
        transition: all 0.5s;
    }
    .fade2-enter{
        margin-left:200%;
        opacity: 0;
    }
    .fade2-leave-to /* .fade-leave-active 在离开时 */ {
        opacity: 0;
    }

    .loadMore2{
        box-sizing:border-box;
        padding:1rem 1.5rem;
        border-radius:1rem;
        background:#1f294d;
        color:#6c88af;
        width:fit-content;
        margin:0 auto;
        margin-top:1.5rem;
        font-size:1.5rem;
        font-weight:bold;
        cursor:pointer;
    }

    .wheelButtonAnimateLeft{
        height:100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        width:3rem;
        animation: opacitiAnimate 6s linear infinite;
    }
    .wheelButtonAnimateRight{
        width:0.2rem;
        height:100%;
        background:#fff;
        margin-left:.3rem;
        animation: opacitiAnimate 6s linear infinite;
    }

    @keyframes opacitiAnimate {
        0% {
            opacity:0;
        }
        25% {
            opacity:1;
        }
        50% {
            opacity:0;
        }
        100% {
            opacity:1;
        }
    }

    @keyframes gradientAnimation {
        0% {
            left:-4rem;
        }
        50% {
            left:21rem;
        }
        100% {
            left:50rem;
        }
    }

    .wheelButtonAnimate{
        position:absolute;
        height:10rem;
        display:flex;
        transform:rotate(43deg);
        animation: gradientAnimation 6s linear infinite;
    }

    .wheelBottomButtonText{
        font-size:1.6rem;
        font-weight:bold;
        color:#fff;
        text-shadow:3px 5px 2px rgba(0,0,0,0.15);
    }

    .slide-right-enter-active, .slide-right-leave-active {
        transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    }

    .slide-right-enter, .slide-right-leave-to /* .slide-right-leave-active in <2.1.8 */ {
        transform: translateX(100%);
        opacity: 0;
    }

    .slide-right-enter-to, .slide-right-leave {
        transform: translateX(0);
        opacity: 1;
    }

    .rotate-slide-enter-active {
        transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    }
    .rotate-slide-leave-active {
        transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }
    .rotate-slide-enter, .rotate-slide-leave-to /* .rotate-slide-leave-active in <2.1.8 */ {
        transform: translateX(-100%) rotate(-180deg);
        opacity: 0;
    }
    .rotate-slide-enter-to, .rotate-slide-leave {
        transform: translateX(0) rotate(0);
        opacity: 1;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 2s ease-in-out;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0;
    }

    .noselect{
        user-select: none;
    }

    .helpTextBottomTextBodyItem{
        width:50%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.2rem;
        color:#fff;
        font-weight:bold;
    }

    .helpTextBottomTextBodyItem{
        height:3.5rem;
        width:100%;
        display:flex;
        background:#232b4e;
    }

    .helpTextBottomTextBody{
        width:100%;
    }

    .helpTextBottomTextHeaderItem{
        width:50%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.2rem;
        color:#97bef6;
        font-weight:bold;
    }

    .helpTextBottomTextHeader{
        display:flex;
        height:4rem;
        background:#313c6b;
    }

    .helpTextBottomText{
        width:100%;
        margin-top:1.5rem;
    }

    .helpTextBottomTitle{
        color:#fff;
        font-size:1.45rem;
        font-weight:bold;
        white-space:nowrap;
    }

    .helpTextBottom{
        margin-top:4rem;
    }

    .helpTextTop{

    }

    .helpTextParagraph{
        color:#4a5872;
        font-size:1.35rem;
        font-weight:600;
        margin-bottom:2rem;
        word-break: break-all;
        line-height:2.2rem;
    }

    .helpTextBox{
        height:100%;
        flex:1;
        width:100%;
        margin-top:1rem;
        overflow-x:hidden;
        overflow-y:scroll;
        scrollbar-width:none;
    }
    .helpTextBox::-webkit-scrollbar{
        display:none;
    }

    .helpTopBox > span {
        color:#fff;
        font-size:2rem;
        font-weight:bold;
    }

    .helpTopBox > svg {
        width:2rem;
        height:2rem;
    }

    .helpTopBox{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    .helpBox{
        height:60rem;
        width:40rem;
        border-radius:2rem;
        overflow:hidden;
        position:absolute;
        background: linear-gradient(225deg,#1a2c5e,#0d1637);
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        z-index:10;
        box-sizing:border-box;
        padding:3rem 3rem;

        display:flex;
        flex-direction: column;
    }


    .historyBodyItemRight > span {
        font-size:1.4rem;
        color:#fff;
        font-weight:bold;
    }

    .historyBodyItemLeft > span {
        font-size:1.4rem;
        margin-left:1rem;
        color:#fff;
        font-weight:bold;
    }

    .historyBodyItemAvatar > img {
        width:95%;
        height:95%;
    }

    .historyBodyItemAvatar{
        width:3rem;
        height:3rem;
        border-radius:50%;
        overflow:hidden;
        background:#eac03b;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .historyBodyItemLeft{
        width:65%;
        display:flex;
        align-items: center;
    }
    .historyBodyItemRight{
        width:35%;
        display:flex;
        justify-content: center;
    }
    
    .historyBodyItem{
        display:flex;
        align-items: center;
        width:100%;
        height:5rem;
    }

    .rankTextBox > div {
        height:100%;
        width:100%;
        flex:1;
        display:flex;
        align-items: center;
        flex-direction: column;
    }

    .rankTextBox2{
        height:100%;
        width:100%;
        flex:1;
        max-height: 40rem;
        overflow-y:scroll;
        overflow-x:hidden;
    }

    .rankTextBox2::-webkit-scrollbar { /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
    }

    .rankTextBox2::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.2);
    }

    .rankTextBox2::-webkit-scrollbar-track { /*滚动条里面外层轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
        border-radius: 0;
        background: rgba(255, 255, 255, 0.1);
    }

    .rankTextBox{
        height:100%;
        width:100%;
        flex:1;
        display:flex;
        align-items: center;
        /* justify-content: center; */
        flex-direction: column;
        overflow-y:scroll;
        overflow-x:hidden;
        scrollbar-width:none;
        flex-wrap:wrap;
    }

    .historyBox{
        height:fit-content;
        width:100%;
        height:100%;
        flex:1;
        display:flex;
        flex-direction: column;
    }
    .historyHeaderBox{
        display:flex;
        height:3rem;
        width:100%;
        align-items: center;
    }
    .historyHeaderItem{
        color:#6c88af;
        font-size:1.2rem;
        font-weight:bold;
    }

    .rankClassBox{
        background:#1f294d;
        border-radius:0.3rem;
        width:100%;
        height:4rem;
        margin-top:3rem;
        box-sizing:border-box;
        padding:0.5rem;
        display:flex;
        overflow:hidden;
    }
    .rankClassItem.active{
        background: #664dfd !important;
        color:#fff !important;
    }
    .rankClassItem{
        width:50%;
        display:flex;
        align-items: center;
        justify-content: center;
        color:#6c88af;
        font-size:1.5rem;
        border-radius:0.5rem;
        cursor:pointer;
        font-weight:bold;
    }

    .rankBox > svg {
        width:2rem;
        height:2rem;
    }

    .rankBox{
        height:60rem;
        width:40rem;
        border-radius:2rem;
        overflow:hidden;
        position:absolute;
        background: linear-gradient(225deg,#1a2c5e,#0d1637);
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        z-index:10;
        box-sizing:border-box;
        padding:3rem 3rem;

        display:flex;
        flex-direction: column;
    }


    @keyframes breathing-animation {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2); /* 放大 20% */
        }
        100% {
            transform: scale(1);
        }
    }

    .wheelPointer > span {
        position: absolute;
        z-index: 8;
        top: 50%;
        left: 50%;
        color:#fff;
        transform: translate(-50%,40%);
        font-size:1.2rem;
        font-weight:bold;
    }
    .wheelPointer > img {
        width:100%;
    }
    .wheelPointer{
        width: 6rem;
        height:5rem;
        position: absolute;
        z-index: 8;
        top: 16.5rem;
        left: 17.3rem;
        transform-origin: center; /* 动画从中心点进行 */
        animation: breathing-animation 5s infinite ease-in-out;
    }

    @keyframes spin-clockwise {
        from {
            transform: rotate(var(--start-angle));
        }
        to {
            transform: rotate(calc(var(--start-angle) + 360deg));
        }
    }

    @keyframes spin-counterclockwise {
        from {
            transform: rotate(var(--start-angle));
        }
        to {
            transform: rotate(calc(var(--start-angle) - 360deg));
        }
    }

    .simpleani2:nth-child(7){
        --start-angle: 90deg; /* 起始角度 */
        animation: spin-counterclockwise 8s linear infinite;
    }

    .simpleani2:nth-child(8) {
        --start-angle: 135deg; /* 起始角度 */
    }

    .simpleani1:nth-child(4){
        --start-angle: 90deg; /* 起始角度 */
        animation: spin-counterclockwise 8s linear infinite;
    }

    .simpleani1:nth-child(5) {
        --start-angle: 135deg; /* 起始角度 */
    }

    .wheelText{
        position: absolute;
        width: 28.4rem;
        height: 28.4rem;
        border-radius: 50%;
        top: 5.9rem;
        left: 6.12rem;
        background: #000;
        overflow: hidden;
    }

    .simpleani2{
        width: 85%;
        position: absolute;
        top: 3.1rem;
        left: 3.3rem;
        animation: spin-clockwise 8s linear infinite;
        --start-angle: 0deg; /* 起始角度 */
    }

    .simpleani1{
        width: 85%;
        position: absolute;
        top: 3.1rem;
        left: 3.3rem;
        opacity: 0.5;
        transform: rotate(360deg);
        animation:spin-clockwise 8s linear infinite;
        --start-angle: -45deg; /* 起始角度 */
    }

    .wheeltime{
        margin-top:1rem;
        font-size:1.3rem;
        color:#97bef6;
        font-weight:bold;
    }

    .wheellabel{
        font-size:1.3rem;
        color:#ed1d49;
        font-weight:bold;
        margin-top:1rem;
    }

    .wheelBottomButton > svg {
        height:2.5rem;
        width:2.5rem;
    }

    .wheelBottomButton.disabled{
        background: linear-gradient(to bottom,#6e6d6e,#929292) !important; 
    }
    .wheelBottomButton{
        background: linear-gradient(to bottom,#5718ed,#9568ed);
        height:4.5rem;
        width:18rem;
        margin-top:3rem;
        border-radius:1rem;
        box-shadow:0px 0px 1rem #9568ed;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        position:relative;
        overflow:hidden;
    }

    .wheelBottom > img {
        width:22rem;
    }

    .simplebg2 {
        width:85%;
        position:absolute;
        top:3.1rem;
        left:3.3rem;
    }

    .simplebg1 {
        width:100%;
        position:absolute;
        top:0;
        left:0;
    }

    .wheelTop{
        height:40rem;
        width:100%;
        top:0%;
        margin-top:0rem;
        display:flex;
        flex-direction: column;
        position:absolute;
        z-index:2;
        align-items: center;
    }

    .wheelBottom{
        width:100%;
        bottom:1rem;
        display:flex;
        flex-direction: column;
        position:absolute;
        z-index:3;
        align-items: center;
        opacity: 1;
    }

    .wheelBoxTopRight > svg {
        width:3rem;
        height:3rem;
    }

    .wheelBoxTopRight{
        display:flex;
        align-items: center;
    }

    .wheelBoxTop > svg {
        width:4rem;
        height:4rem;
    }

    .wheelBoxTop{
        height:4rem;
        width:100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        position:absolute;
        top:0;
        left:0;
        z-index:7;
        box-sizing:border-box;
        padding:0 1rem;
        margin-top:1rem;
    }

    .simpleWheel{
        height:60rem;
        width:40rem;
        border-radius:2rem;
        overflow:hidden;
        position:relative;
    }
    .simpleWheelout{
        width:100%;
        height:fit-content;
        display:flex;
        justify-content: center;
        align-items: center;
        position:relative;
    }
    .wheelBox{
        background: linear-gradient(225deg,#11123a,#1f1aaf);
        /* background: linear-gradient(225deg, #11123a 0%, #11123a 60%, #1f1aaf 80%); */
        position:absolute;
        top:0;
        left:0;
        z-index:1;
        width:100%;
        height:100%;
        box-sizing:border-box;
        padding:2rem;
    }
</style>