<template>
    <div class="container">
        <div class="navBox">
            <img class="goback" :src="Left" @click="toBack" />
            <span >Receba R$ 100 de graça</span>
            <div style="width:0.5rem"></div>
        </div>
        <div class="text">
            <div class="balanceBox">
                <div class="balanceTopBox">
                    <img :src="Cash" />
                    <span>R$ 0.00</span>
                    <div class="balanceButton">
                        <img :src="RS" />
                        <span>SACAR</span>
                    </div>
                </div>
                <div class="balanceProcess">
                </div>
                <div class="balanceDes">
                    Ainda e necessário
                    <span>100.00</span>
                    para realizar do saque
                </div>
            </div>
            <div class="animateBox">
                <img class="animateBg" :src="AnimateBg" />
                <img class="animateBb" :src="AnimateBb" />
                <img class="animateDe" :src="AnimateDe" />
                <img v-if="animateFlag" class="animateLight1" :src="Light1" />
                <img v-else class="animateLight2" :src="Light2" />
                <div class="animateTextBox">
                    <div class="animateRotateBox" ref="animateRotateBox">
                        <div class="slice" v-for="item in goodList" :key="item.id" :id="item.id">
                            <img :src="item.fullPrizeImage" />
                            <span v-text="item.name"></span>
                        </div>
                    </div>
                    <div class="allow" @click="toParticipation()">
                        <img :src="Arrow" />
                        <span>1</span>
                    </div>
                </div>
                <div class="animateDao">
                    Expira em<span>2d 21:03:30</span>
                </div>
            </div>
            <div class="nameListBox">
                <div class="nameListTitle">
                    <div class="name">
                        Relatório
                    </div>
                    <div class="value">
                        Minha Referencia
                    </div>
                </div>
                <div class="nameListList">
                    <div class="nameListItem" v-for="(item,index) in nameList" :key="index">
                        <div class="itemName" v-text="item.name">

                        </div>
                        <div class="itemRight">
                            <span class="itemThing" v-text="item.thing">

                            </span>
                            <span class="itemValue">
                                {{  item.value }}
                                <span>R$</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nameTips">
                <div class="nameTipsTitle">
                    Regras
                </div>
                <div class="nameTipsItem" v-for="(item,index) in tipList" :key="index" v-text="item">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Cash from "../assets/cashwheel/cash.png";
    import RS from "../assets/cashwheel/rs.png";

    import AnimateBg from "../assets/cashwheel/animateBg.png";
    import AnimateBb from "../assets/cashwheel/animateBb.png";
    import AnimateDe from "../assets/cashwheel/animateDe.png";

    import Light1 from "../assets/cashwheel/light1.png";
    import Light2 from "../assets/cashwheel/light2.png";
    
    import Arrow from "../assets/cashwheel/animateArrow.png";

    import { LuckyDetails,Participation } from "../api/cashwheel.js";

    import Left from "../assets/cashwheel/left.png";

    export default {
        name:"CashWheel",
        components:{
        },
        data(){
            return {
                Left,
                Cash,
                RS,
                AnimateBg,
                AnimateBb,
                AnimateDe,
                Arrow,
                Light1,
                Light2,

                animateFlag:false,
                animateInterval:null,
                nameList:[
                    {
                        "name":"554*******393",
                        "thing":"Acabou de saca",
                        "value":"+1000"
                    },
                    {
                        "name":"554*******393",
                        "thing":"Acabou de saca",
                        "value":"+1000"
                    },
                    {
                        "name":"554*******393",
                        "thing":"Acabou de saca",
                        "value":"+1000"
                    },
                    {
                        "name":"554*******393",
                        "thing":"Acabou de saca",
                        "value":"+1000"
                    },
                    {
                        "name":"554*******393",
                        "thing":"Acabou de saca",
                        "value":"+1000"
                    },
                    {
                        "name":"554*******393",
                        "thing":"Acabou de saca",
                        "value":"+1000"
                    },
                    {
                        "name":"554*******393",
                        "thing":"Acabou de saca",
                        "value":"+1000"
                    },
                    {
                        "name":"554*******393",
                        "thing":"Acabou de saca",
                        "value":"+1000"
                    }  
                ],
                tipList:[
                    "1.Você pode começar a sacar dinheiro quando o valor acumulado atingir 100BRL (o valor máximo da recompensa é 1.000BRL, não há limite de vezes).",
                    "2.Convidar amigos para se registrarem em 9099.COM irá ajudá-lo a sacar dinheiro rapidamente.",
                    "3.Cada usuário receberá uma rodada grátis por dia.",
                    "4.A atividade de cada usuário é válida por 3 dias e pode participar da atividade várias vezes.",
                    "5.Se você receber um bônus extra, poderá jogar de graça e ganhar mais dinheiro real.",
                    "6.Se nenhum saque for feito dentro de 3 dias, os fundos serão perdidos."
                ],
                goodList:[],

                isparticipation:false, // 是否正在抽奖
            }
        },
        methods:{
            toBack(){
                this.$router.go(-1);
            },
            stopSpin(targetId) {
                const box = this.$refs.animateRotateBox;
                const targetIndex = this.goodList.findIndex(item => item.id === targetId);
                const anglePerSlice = 360 / this.goodList.length;
                const baseAngle = 360 - (targetIndex * anglePerSlice);
                const targetAngle = baseAngle + 25; // 调整为 -25 度

                // 移除旋转动画类
                box.classList.remove('spin');

                // 重置当前旋转状态
                const currentRotation = this.getRotationAngle(box);
                box.style.transform = `rotate(${currentRotation}deg)`;

                // 强制重绘，确保当前旋转状态生效
                box.offsetHeight;

                // 缓慢旋转到目标位置
                box.style.transition = 'transform 3s cubic-bezier(0.25, 0.1, 0.25, 1)';
                box.style.transform = `rotate(${targetAngle + 3600}deg)`; // 3600 是旋转多圈以保证足够的旋转感
            },
            toParticipation(){ // 参与抽奖
                const box = this.$refs.animateRotateBox;
                // 重置 transform 和 transition 样式
                box.style.transition = 'none';
                box.style.transform = 'rotate(0deg)';

                // 强制重绘，确保样式重置生效
                box.offsetHeight; // 触发浏览器重绘

                // 开始旋转
                box.classList.add('spin');

                Participation({activityId:this.$route.query.id}).then(res => {
                    // 接口成功返回后的逻辑
                    this.stopSpin(res.data.id); // 假设 targetId 是返回的奖品 id
                })
            },
            // 获取当前旋转角度
            getRotationAngle(element) {
                const style = window.getComputedStyle(element);
                const transform = style.transform || style.webkitTransform || style.mozTransform;
                if (transform === 'none') return 0;
                const matrix = transform.match(/^matrix\((.+)\)$/);
                if (matrix) {
                const values = matrix[1].split(', ');
                const a = values[0];
                const b = values[1];
                const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
                return angle < 0 ? angle + 360 : angle;
                }
                return 0;
            },
            initData(){
                LuckyDetails({activityId:this.$route.query.id}).then(res => {
                    this.goodList = res.data;
                })
            },
            startScrolling() {
                setInterval(() => {
                    const firstItem = this.nameList.shift();
                    this.nameList.push(firstItem);
                }, 1500); // Adjust this interval to match the animation duration
            },
            setAnimateInterval(){
                this.animateInterval = setInterval(() =>{
                    this.animateFlag = !this.animateFlag;
                },1000)
            },
        },
        mounted(){
            this.initData();
        },
        beforeDestroy(){
            clearInterval(this.animateInterval);
        }
    }
</script>

<style>
    .spin {
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
</style>

<style scoped>

    .navBox>span{
        font-size:2rem;
        color:rgba(255,255,255,0.65);
    }
    .goback{
        transform: rotate(180deg);
        opacity: 0.65;
        width: 2rem;
        height: 2rem;
        cursor:pointer;
    }
    .navBox{
        background:var(--primary);
        height:4rem;
        width:100%;
        box-sizing:border-box;
        padding:0 1rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
        position:absolute;
        top:0;
        left:0;
        z-index:10;
    }

    .nameTipsItem{
        margin: 1rem 0;
        color:#7d8397;
        font-size:1.4rem;
        box-sizing:border-box;
        padding:0 2rem;
        width:100%;
    }

    .nameTipsTitle{
        color:#8795b1;
        font-size:1.4rem;
        font-weight:bold;
    }

    .nameTips{
        background:rgba(0,0,0,0.1);
        width:100%;
        box-sizing:border-box;
        padding:0.5rem 0.5rem;
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .itemValue > span{
        width:2rem;
        height:2rem;
        border-radius:50%;
        background:#6ddf38;
        color:#ffffff;
        box-sizing:border-box;
        padding:.4rem;
    }

    .itemValue{
        color:#6ddf38;
        font-size:1.4rem;
    }

    .itemThing{
        color:#8795b1;
        font-size:1.4rem;
    }

    .itemRight{
        width:70%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        box-sizing:border-box;
        padding:0 2rem;
    }

    .itemName{
        color:#8795b1;
        font-size:1.4rem;
        width:30%;
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .nameListItem{
        display:flex;
        justify-content: space-between;
        height:4rem;
        animation: scrollUp 1s linear infinite; /* Adjust the duration based on the number of items */
    }

    .nameListList{
        height:30rem;
        overflow:hidden;
    }

    @keyframes scrollUp {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-100%);
        }
    }

    .value{
        width:70%;
        display:flex;
        justify-content: center;
        align-items: center;
        color:rgb(156, 156, 156);
        font-size: 1.4rem;
    }

    .name{
        width:30%;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        font-size: 1.4rem;
    }

    .nameListTitle{
        height: 4rem;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
    }

    .nameListBox{
        margin-top:27rem;
    }
</style>

<style scoped>

    .slice > span {
        font-size: 0.9rem;
        color: #fff;
        position: absolute;
        left: 8.3rem;
        transform: rotate(-20deg);
        top: 5.5rem
    }

    .slice > img {
        width: 3.5rem;
        position: absolute;
        left: 7.6rem;
        transform: rotate(-20deg);
        top: 2.5rem;
    }

    .allow > span {
        font-size:2.5rem;
        font-weight:bold;
        color:#fff;
        position: absolute;
        z-index:3;
        top:50%;
        left:50%;
        transform: translate(-50%,-10%);
    }

    .allow > img {
        width:100%;
    }

    .allow{
        position: absolute;
        z-index: 20;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
        width: 7.8rem;
        height: 9rem;
    }

    .slice:nth-child(odd) { background-color: #1fad7e; }
    .slice:nth-child(even) { background-color: #c82508; }

    .slice {
        position: absolute;
        width: 50%;
        height: 50%;
        transform-origin: 100% 100%;
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        display:flex;
        flex-direction: column;
    }

    .slice:nth-child(1) { transform: rotate(0deg); }
    .slice:nth-child(2) { transform: rotate(45deg); }
    .slice:nth-child(3) { transform: rotate(90deg); }
    .slice:nth-child(4) { transform: rotate(135deg); }
    .slice:nth-child(5) { transform: rotate(180deg); }
    .slice:nth-child(6) { transform: rotate(225deg); }
    .slice:nth-child(7) { transform: rotate(270deg); }
    .slice:nth-child(8) { transform: rotate(315deg); }


    .animateDao > span{
        color:#fff;
        margin-left:0.1rem;
    }
    .animateDao{
        color:rgb(135, 149, 177);
        font-size:.34658rem;
        position:absolute;
        z-index:2;
        bottom:0.5rem;
        left:50%;
        
        transform: translate(-50%, 0%)
    }

    .animateTextBox{
        z-index: 4;
        position: absolute;
        top: 53%;
        width: 26.5rem;
        height: 26.5rem;
        background: #fff;
        border-radius: 50%;
        overflow: hidden;
        left: 13.5%;
    }

    .animateRotateBox{
        z-index:4;
        position:absolute;
        top:0%;
        left:0;
        width:100%;
        height:100%;
        background:#fff;
        border-radius:50%;
        overflow:hidden;
    }

    .animateLight1{
        z-index:4;
        position:absolute;
        top:41%;
        width:73%;
        left:8%;
    }

    .animateLight2{
        z-index:4;
        position:absolute;
        top:41%;
        width:73%;
        left:8%;
    }

    .animateDe{
        width:23%;
        position:absolute;
        z-index:5;
        left:32%;
        top:32%;
    }

    .animateBb{
        width:120%;
        position:absolute;
        z-index:2;
        left:-15%;
        top:-23%;
    }

    @keyframes rotate {
        0% {
            transform: translate(-50%, -40%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -40%) rotate(360deg);
        }
    }

    .animateBg{
        left:50%;
        top:40%;
        position:absolute;
        width:130%;
        z-index:1;
        animation: rotate 10s linear infinite;
    }

    .animateBox{
        width:100%;
        height:20rem;
        margin-top:7rem;
        position:relative;
    }

    .balanceDes > span{
        font-weight:bold;
        color:#6ddf38;
    }

    .balanceDes{
        color:#fff;
        font-size:1.2rem;
        text-align: center;
        margin-top:1rem;
    }

    .balanceProcess{
        height:0.8rem;
        background:#6ddf38;
        margin-top:1rem;
        border-radius:.2666rem;
    }

    .balanceButton > img {
        width:1.5rem;
        margin-right:1rem;
    }

    .balanceButton{
        background:#efb423 linear-gradient(90deg,#49a319,#2b8b2f);
        color:#fff;
        font-size:1.5rem;
        display:flex;
        align-items: center;
        box-sizing:border-box;
        padding:1rem 1.5rem;
        border-radius: 1rem;
        height:fit-content;
    }

    .balanceTopBox > span{
        font-size:3rem;
        color:#6ddf38;
        font-weight:bold;
    }

    .balanceTopBox > img {
        width:4rem;
        height:auto;
    }

    .balanceTopBox{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    .balanceBox{
        box-sizing:border-box;
        padding:1rem 1.5rem;
        display:flex;
        flex-direction: column;
        background:rgba(0,0,0,0.1);
        border-radius:.2666rem;
    }

    .text{
        height:auto;
        overflow-x:hidden;
        overflow-y:scroll;
        scrollbar-width:none;
        position:relative;
        width:100%;
        height:calc(100% - 4rem);
        padding:2rem 2rem;
        padding-top:6rem;
        background:var(--primary);
        box-sizing:border-box;
    }
    .container{
        height:100%;
        overflow:hidden;
        width:100%;
        position:relative;
    }
</style>