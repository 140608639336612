// 检测是否是移动设备
function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// 检测是否是PC设备
export function isPcDevice() {
    return !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

(function () {
    function setRem() {

        var baseFontSize = 16; // 默认基准字体大小，可以根据需要调整
        var designWidth = 750; // 设计稿宽度，假设为 750px

        if (isMobileDevice()) {
            var screenWidth = window.innerWidth;
        } else if (isPcDevice()) {
            var screenWidth = parseInt(document.body.style.maxWidth)
        }

        var rem = (screenWidth / designWidth) * baseFontSize;

        document.documentElement.style.fontSize = rem + 'px';
    }

    // 页面加载完成时初始化
    setRem();
})();

export default function setRem() {

    var baseFontSize = 16; // 默认基准字体大小，可以根据需要调整
    var designWidth = 750; // 设计稿宽度，假设为 750px

    if (isMobileDevice()) {
        var screenWidth = window.innerWidth;
    } else if (isPcDevice()) {
        var screenWidth = parseInt(document.body.style.maxWidth)
    }

    var rem = (screenWidth / designWidth) * baseFontSize;

    document.documentElement.style.fontSize = rem + 'px';
}
