<template>
    <div class="container">
        <div class="navBox">
            <img class="goback" :src="Left" @click="toBack" />
            <span>{{ $t("PasswordVerification") }}</span>
            <div style="width:0.5rem"></div>
        </div>
        <div class="text">
            <span class="textTitle">{{ $t("VerifyAccountPassword") }}</span>
            <span class="textDescription">{{ $t("VerifyDes") }}</span>
            <div class="settingInput">
                <svg class="brownSvg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 2.8335C1.82081 2.8335 1 3.65431 1 4.66683V19.3335C1 20.346 1.82081 21.1668 2.83333 21.1668H21.1667C22.1792 21.1668 23 20.346 23 19.3335V4.66683C23 3.65431 22.1792 2.8335 21.1667 2.8335H2.83333ZM4.77887 9.71687C4.77887 11.072 5.61904 12.2309 6.8069 12.7011C4.88194 13.1629 3.3865 14.7289 3.02782 16.6918C2.93683 17.1898 3.35594 17.6039 3.86221 17.6039H12.1122C12.6185 17.6039 13.0376 17.1898 12.9466 16.6918C12.5879 14.7289 11.0925 13.1629 9.16751 12.7011C10.3554 12.2309 11.1955 11.072 11.1955 9.71687C11.1955 7.94496 9.75912 6.50854 7.9872 6.50854C6.21529 6.50854 4.77887 7.94496 4.77887 9.71687ZM19.9207 9.64957C20.427 9.64957 20.8374 10.06 20.8374 10.5662C20.8374 11.0725 20.427 11.4829 19.9207 11.4829H14.4207C13.9145 11.4829 13.5041 11.0725 13.5041 10.5662C13.5041 10.06 13.9145 9.64957 14.4207 9.64957H19.9207ZM20.8374 15.1496C20.8374 14.6433 20.427 14.2329 19.9207 14.2329H14.4207C13.9145 14.2329 13.5041 14.6433 13.5041 15.1496C13.5041 15.6558 13.9145 16.0662 14.4207 16.0662H19.9207C20.427 16.0662 20.8374 15.6558 20.8374 15.1496Z" fill="#039685"></path>
                </svg>
                <input type="password" v-model="cpf" :placeholder="$t('Password')" />
            </div>
            <div class="saveButton" @click="confirm">
                {{ $t("Confirm") }}
            </div>
        </div>
    </div>
</template>

<script>

    import Left from "@/assets/cashwheel/left.png";

    export default {
        name:"Validate",
        components:{
        },
        data(){
            return {
                Left,
                target:""
            }
        },
        methods:{
            toBack(){
                this.$router.go(-1);
            },
            confirm(){
                this.$router.push(this.target)
            }
        },
        mounted(){
            this.target = this.$route.query.target
        },
        beforeDestroy(){
        }
    }
</script>

<style scoped>

    .saveButton{
        box-sizing:border-box;
        overflow:hidden;
        background:var(--gdient2);
        border-radius:1rem;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        font-weight:bold;
        width:100%;
        height:4rem;
        margin-top:1rem;
    }

    .brownSvg path {
        fill:var(--subfont);
    }
    
    .brownSvg{
        margin-right:1rem;
    }

    .settingInput{
        display:flex;
        align-items: center;
        height:4.5rem;
        width:100%;
        border: 0.05rem solid var(--subbackgroundspecial);
        border-radius:0.5rem;
        box-sizing:border-box;
        padding:0 1rem;
        overflow:hidden;
        margin-top:1rem;
    }
    .settingInput > input {
        border:none;
        outline:none;
        background:none;
        padding:0;
        margin:0;
        color:var(--basic);
        font-size:1.3rem;
        font-weight:bold;
        flex:1;
        width:100%;
    }
    .settingInput > input::placeholder{
        color:var(--subfont);
    }

    .textDescription{
        font-size:1.2rem;
        font-weight:bold;
        color:rgba(255, 255, 255, 0.6);
        margin-top:1rem;
        word-break: break-all;
    }

    .textTitle{
        font-size:1.4rem;
        font-weight:bold;
        color:#fff;
    }

    .navBox>span{
        font-size:2rem;
        color:rgba(255,255,255,0.65);
    }
    .goback{
        transform: rotate(180deg);
        opacity: 0.65;
        width: 2rem;
        height: 2rem;
        cursor:pointer;
    }
    .navBox{
        background:var(--primary);
        height:4rem;
        width:100%;
        box-sizing:border-box;
        padding:0 1rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
        position:absolute;
        top:0;
        left:0;
        z-index:10;
    }
    .container{
        height:100%;
        overflow:hidden;
        width:100%;
        position:relative;
    }
    .text{
        height:auto;
        overflow-x:hidden;
        overflow-y:scroll;
        scrollbar-width:none;
        position:relative;
        width:100%;
        height:calc(100% - 4rem);
        padding:2rem 2rem;
        padding-top:6rem;
        background:var(--primary);
        box-sizing:border-box;
        display:flex;
        flex-direction: column;
    }

</style>