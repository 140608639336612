import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=a081aac8&scoped=true"
import script from "./Pagination.vue?vue&type=script&lang=js"
export * from "./Pagination.vue?vue&type=script&lang=js"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=a081aac8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a081aac8",
  null
  
)

export default component.exports