<template>
    <div class="game-item buttonTransition">
        <img :src="item.fullGameImg" v-show="!loading" @click="debouncedHandleClick" @load="imgOnload"/>

        <div class="repairBox" v-if="item.isMaintenance">
            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m0 85.333334C323.477333 170.666667 170.666667 323.477333 170.666667 512s152.810667 341.333333 341.333333 341.333333 341.333333-152.810667 341.333333-341.333333S700.522667 170.666667 512 170.666667z m0 106.666666a21.333333 21.333333 0 0 1 21.333333 21.333334v234.666666h170.666667a21.333333 21.333333 0 0 1 21.333333 21.333334v42.666666a21.333333 21.333333 0 0 1-21.333333 21.333334H469.333333a21.333333 21.333333 0 0 1-21.333333-21.333334V298.666667a21.333333 21.333333 0 0 1 21.333333-21.333334h42.666667z" fill="#ffffff"></path>
            </svg>
            <span style="margin-top:0.8rem" v-text="formatDateNoYearSecond(item.maintenanceStartTime)"></span>
            <span class="repairTimeSpe">-</span>
            <span v-text="formatDateNoYearSecond(item.maintenanceEndTime)"></span>
        </div>

        <div class="classItemCollection" @click="favoriteClick(item.gameId)" v-show="!loading">
            <svg :class="isFavorite ? 'collectioned' : 'uncollection'" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M845.5 934.7c2.5 18.1-4.7 34.8-19.6 45.6-14.9 10.7-33 12.4-49.6 4.4L534 868.1c-13.8-6.6-28.3-6.6-42.1 0L249.4 984.3c-16.6 7.9-34.7 6.3-49.6-4.5-14.9-10.8-22-27.5-19.5-45.6l36.2-265.7c2.1-15.1-2.4-28.9-13-39.9l-186-193.9C4.9 421.5 0.8 403.8 6.5 386.4c5.7-17.4 19.4-29.3 37.5-32.6l264.8-47.7c15.1-2.7 26.8-11.2 34.1-24.6l127.6-236c8.7-16.1 24.4-25.4 42.7-25.4 18.4 0 34 9.3 42.7 25.5l127.4 236.2c7.3 13.4 19 21.9 34 24.7l264.8 48c18.1 3.3 31.8 15.2 37.5 32.6 5.7 17.4 1.6 35.1-11.1 48.3l-186 193.7c-10.6 11-15.1 24.8-13 39.9l36 265.7z" fill="#ffffff">
                </path>
                <path d="M809.4 669c-2-15.1 2.4-28.8 13-39.9l186.1-193.7c12.7-13.2 16.8-30.9 11.1-48.3-5.7-17.4-19.4-29.3-37.5-32.6l-264.8-48c-15.1-2.7-26.8-11.2-34-24.7L555.9 45.5c-8.7-16.1-24.3-25.4-42.7-25.5-18.4 0-34 9.3-42.7 25.4l-127.6 236c-7.3 13.4-19 21.9-34.1 24.6L44 353.8c-18.1 3.3-31.8 15.2-37.5 32.6-5.7 17.4-1.6 35.1 11.1 48.3l186 193.9c10.6 11 15.1 24.8 13 39.9l-21.8 159.6h636.3L809.4 669z" fill="#ffffff" opacity=".2">
                </path>
                <path d="M809.4 669c-2-15.1 2.4-28.8 13-39.9l186.1-193.7c12.7-13.2 16.8-30.9 11.1-48.3-5.7-17.4-19.4-29.3-37.5-32.6l-264.8-48c-15.1-2.7-26.8-11.2-34-24.7L555.9 45.5c-8.7-16.1-24.3-25.4-42.7-25.5-18.4 0-34 9.3-42.7 25.4l-127.6 236c-7.3 13.4-19 21.9-34.1 24.6L44 353.8c-18.1 3.3-31.8 15.2-37.5 32.6-5.7 17.4-1.6 35.1 11.1 48.3l186 193.9c10.6 11 15.1 24.8 13 39.9l-2.9 21.1h598.6l-2.9-20.6z" fill="#ffffff" opacity=".2">
                </path>
                <path d="M1008.5 435.4c12.7-13.2 16.8-30.9 11.1-48.3-5.7-17.4-19.4-29.3-37.5-32.6l-264.8-48c-15.1-2.7-26.8-11.2-34-24.7L555.9 45.5c-8.7-16.1-24.3-25.4-42.7-25.5-18.4 0-34 9.3-42.7 25.4l-127.6 236c-7.3 13.4-19 21.9-34.1 24.6L44 353.8c-18.1 3.3-31.8 15.2-37.5 32.6-5.7 17.4-1.6 35.1 11.1 48.3l67.3 70.2h856.9l66.7-69.5z" fill="#ffffff" opacity=".2">
                </path>
                <path d="M982.2 354.5l-264.8-48c-15.1-2.7-26.8-11.2-34-24.7L555.9 45.5c-8.7-16.1-24.3-25.4-42.7-25.5-18.4 0-34 9.3-42.7 25.4l-127.6 236c-7.3 13.4-19 21.9-34.1 24.6L44 353.8c-9.8 1.8-18.1 6.2-24.8 12.6h987.1c-6.5-6-14.7-10.2-24.1-11.9z" fill="#ffffff" opacity=".2">
                </path>
                <path d="M555.9 45.5c-8.7-16.1-24.3-25.4-42.7-25.5-18.4 0-34 9.3-42.7 25.4L372 227.7h282.3L555.9 45.5z" fill="#ffffff" opacity=".2">
                </path>
            </svg>
        </div>

        <div class="skeleton" v-show="loading">
            <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M298.666667 298.666667h426.666666c140.8 0 256 115.2 256 256s-115.2 256-256 256c-72.533333 0-140.8-29.866667-192-85.333334h-46.933333c-93.866667 106.666667-256 115.2-362.666667 21.333334s-115.2-256-21.333333-362.666667C157.866667 328.533333 226.133333 298.666667 298.666667 298.666667m-42.666667 128v85.333333H170.666667v85.333333h85.333333v85.333334h85.333333v-85.333334h85.333334v-85.333333H341.333333v-85.333333H256m405.333333 128c-34.133333 0-64 29.866667-64 64s29.866667 64 64 64 64-29.866667 64-64-29.866667-64-64-64m128-128c-34.133333 0-64 29.866667-64 64s29.866667 64 64 64 64-29.866667 64-64-29.866667-64-64-64z" fill="#252e3a">
                </path>
            </svg>
        </div>
        
    </div>
</template>

<script>

import { mapState } from 'vuex';
import { formatDateNoYearSecond } from "@/utils/dateUtil";

export default {
    name: "GameItem",
    props: {
        item: {
            //游戏对象
            type: Object,
        },
        click: {
            type: Function,
            default: () => {
            }
        },
    },
    data() {
        return {
            formatDateNoYearSecond,
            loading: true,
            isFavorite:false,
        }
    },
    computed:{
        ...mapState({
            favoriteList: state => state.user.favoriteList,
        })
    },
    watch:{
        favoriteList(){
            this.checkFavorite();
        }
    },  
    mounted() {
        this.checkFavorite();
    },
    methods: {
        checkFavorite(){
            if (this.favoriteList.includes(this.item.gameId)) {
                this.isFavorite = true;
            }else{
                this.isFavorite = false;
            }
        },
        imgOnload() {
            this.loading = false;
        }, 
        handleClick() {
            if(!this.item.isMaintenance){
                this.click(this.item);
            }
        },
        // 包装的防抖点击方法
        debouncedHandleClick: debounce(function () {
            this.handleClick();
        }, 300),
        
        favoriteClick(gameId) {
            let temp = [...this.favoriteList];

            if(this.isFavorite){ // 取消收藏
                temp = temp.filter(id => id !== gameId);
            }else{ // 收藏
                if (!temp.includes(gameId)) {
                    temp.push(gameId);
                }
            }
            this.$store.dispatch("setFavoriteList",temp)
        },
    }
}

// 防抖函数定义（可以放在同一文件或单独文件中）
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
</script>

<style scoped>

.repairTimeSpe{
    margin:0.2rem 0;
}

.repairBox > span {
    font-size:1.4rem;
    font-weight:bold;
    color:#fff;
    line-height:1;
}

.repairBox > svg {
    width:5rem;
    height:5rem;
}

.repairBox{
    position:absolute;
    top:0;
    left:0;
    z-index:21;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.6);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.classItemCollection svg {
    width: 1.6rem;
    height: 1.6rem;
}

.classItemCollection {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    background: rgba(0, 0, 0, 0.3);
    z-index: 20;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
}

.collectioned path {
    fill: var(--yellow5);
}

.uncollection path {
    fill: rgba(255, 255, 255, .7)
}

.game-item {
    height: 18rem;
    width: calc(33.33% - 1rem);
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
}

.game-item:nth-child(3n) {
    margin-right: 0;
}

.game-item img {
    width: 100%;
    height: 100%;
}
</style>
