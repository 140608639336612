<template>
    <div class="childBox">
        <div class="searchBox">
            <DatePicker :startDate.sync="start" :endDate.sync="end"></DatePicker>
            <div class="searchButton buttonTransition">
                {{ $t("Search") }}
            </div>
        </div>
        <div class="headerBox">
            <div @click="changeAction(index)" :class="item.active ? 'headerItem buttonTransition active' : 'headerItem buttonTransition'" v-for="(item,index) in headerList" :key="index">
                <span v-text="item.name"></span>
            </div>
        </div>
        <TableSimple :column="column" :data="data">
        </TableSimple>
    </div>
</template>

<script>

    
    import DatePicker from "@/components/DatePicker.vue";
    import TableSimple from "@/components/TableSimple.vue";

    export default {
        name:"ReferMember",
        data(){
            return {
                data:[],
                column:[
                    {   
                        name:this.$t("Time"),
                        key:"id"
                    },
                    {
                        name:this.$t("Type"),
                        key:"date"
                    },
                    {   
                        name:this.$t("Bonus"),
                        key:"id"
                    },
                ],
                headerList:[
                    {
                        name:this.$t("All"),
                        active:true,
                    },
                    {
                        name:this.$t("Today"),
                        active:false,
                    },
                    {
                        name:this.$t("Yesterday"),
                        active:false,
                    },
                    {
                        name:this.$t("ThisWeek"),
                        active:false,
                    },
                    {
                        name:this.$t("ThisMonth"),
                        active:false,
                    },
                ],
                start:"0000-00-00",
                end:"0000-00-00"
            }
        },
        components:{
            DatePicker,
            TableSimple
        },
        methods:{
            changeAction(index){
                this.headerList.forEach((item, i) => {
                    if (i === index) {
                        item.active = true;
                    } else {
                        item.active = false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .headerItem.active{
        background:var(--gdient2);
    }
    .headerItem.active span{
        color:#fff !important;
    }
    .headerItem span{
        color:var(--subfont);
        font-size:1.3rem;
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:bold;
    }
    .headerItem{
        height:4rem;
        width:8rem;
        background:var(--subbackground);
        border-radius:1rem;
        display:flex;
        justify-content: center;
        align-items: center;
        box-sizing:border-box;
        padding:0 1rem;
        text-align: center;
    }
    .headerBox{
        display:flex;
        margin-top:2rem;
        justify-content: space-between;
    }
    .searchButton{
        background:var(--blue);
        color:#fff;
        border-radius:1rem;
        box-sizing:border-box;
        padding:0 2em;
        height:3.5rem;
        font-size:1.3rem;
        display:flex;
        align-items: center;
        margin-left:1rem;
    }
    .searchBox{
        margin-top:2rem;
        display:flex;
    }
    
    .childBox{
        display:flex;
        flex-direction: column;
    }
</style>