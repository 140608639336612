import { render, staticRenderFns } from "./Region.vue?vue&type=template&id=df66329e&scoped=true"
import script from "./Region.vue?vue&type=script&lang=js"
export * from "./Region.vue?vue&type=script&lang=js"
import style0 from "./Region.vue?vue&type=style&index=0&id=df66329e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df66329e",
  null
  
)

export default component.exports