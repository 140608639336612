<template>
    <div class="container">
        <div class="navBox">
            <img class="goback" :src="Left" @click="toBack" />
            <span>{{ $t("ChangePhoneNumber") }}</span>
            <div style="width:0.5rem"></div>
        </div>
        <div class="text">
            <span class="textTitle">{{ $t("LinkMobileNumber") }}</span>
            <span class="textDescription">{{ $t("SetPhoneTips") }}</span>
            <div class="settingInput">
                <svg class="brownSvg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 2C2.5 0.89543 3.39543 0 4.5 0H18.5C19.6046 0 20.5 0.895431 20.5 2V22C20.5 23.1046 19.6046 24 18.5 24H4.5C3.39543 24 2.5 23.1046 2.5 22V2ZM4 3C4 2.44772 4.44772 2 5 2H18C18.5523 2 19 2.44772 19 3V19C19 19.5523 18.5523 20 18 20H5C4.44772 20 4 19.5523 4 19V3ZM9.10498 1C9.10498 0.723858 9.32884 0.5 9.60498 0.5H13.605C13.8811 0.5 14.105 0.723858 14.105 1C14.105 1.27614 13.8811 1.5 13.605 1.5H9.60498C9.32884 1.5 9.10498 1.27614 9.10498 1ZM13.105 22C13.105 22.8284 12.4334 23.5 11.605 23.5C10.7766 23.5 10.105 22.8284 10.105 22C10.105 21.1716 10.7766 20.5 11.605 20.5C12.4334 20.5 13.105 21.1716 13.105 22Z" fill="#039685"></path>
                </svg>
                <div style="display:flex;align-items: center;margin-right:0.5rem">
                    <img style="height:2rem" src="https://dl-br-cf.sadslj88.com/image-prod/bximages/h5/home/icons/baxi.webp" alt="">
                    <span style="color: rgba(255, 255, 255, 0.6);font-size:1.3rem;">+55</span>
                </div>
                <input type="text" v-model="phone" :placeholder="$t('Phone')" />
            </div>
            <div class="settingOptionBox">
                <div class="returnButton" @click="returnF">
                    {{ $t("Return") }}
                </div>
                <div class="saveButton" @click="confirm" :style="canPush ? '' : 'filter: brightness(80%);'">
                    {{ $t("Confirm") }}
                    <div style="margin-left:0.3rem;transform:scale(0.6)" v-loading="isPush"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Left from "@/assets/cashwheel/left.png";
    import { SetPhone } from "@/api/setting.js";
    import { checkPhoneNumber } from "@/utils/interceptor.js";

    export default {
        name:"SetPhone",
        components:{
        },
        data(){
            return {
                Left,
                phone:"",
                isPush:false,
            }
        },
        computed:{
            canPush(){
                return checkPhoneNumber(this.phone);
            }
        },
        methods:{
            toBack(){
                this.$router.go(-1);
            },
            returnF(){
                this.$router.go(-1);
            },
            confirm(){
                if(this.canPush){
                    if(!this.isPush){
                        let data = {
                            phone:this.phone
                        }       
                        this.isPush = true;
                        SetPhone(data).then(res => {
                            this.$message(res.msg);
                            if(res.code == 200){
                                this.$router.push("setting")
                            }
                            this.isPush = false;
                        }).catch(err => {
                            this.isPush = false;
                        })
                    }
                }
                
            }
        },
        mounted(){
        },
        beforeDestroy(){
        }
    }
</script>

<style scoped>

.saveButton{
        box-sizing:border-box;
        overflow:hidden;
        background:var(--gdient2);
        border-radius:1rem;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        font-weight:bold;
        width:50%;
        height:4rem;
    }

    .returnButton{
        width:50%;
        box-sizing:border-box;
        border:0.05rem solid var(--gdient2);
        border-radius:1rem;
        color:var(--gdient2);
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        font-weight:bold;
        overflow:hidden;
        margin-right:1rem;
        height:4rem;
    }

    .settingOptionBox{
        display:flex;
        width:100%;
        margin-top:1rem;
    }

    .brownSvg path {
        fill:var(--subfont);
    }
    
    .brownSvg{
        margin-right:0.5rem;
    }

    .settingInput{
        display:flex;
        align-items: center;
        height:4.5rem;
        width:100%;
        border: 0.05rem solid var(--subbackgroundspecial);
        border-radius:0.5rem;
        box-sizing:border-box;
        padding:0 1rem;
        overflow:hidden;
        margin-top:1rem;
    }
    .settingInput > input {
        border:none;
        outline:none;
        background:none;
        padding:0;
        margin:0;
        color:var(--basic);
        font-size:1.3rem;
        font-weight:bold;
        flex:1;
        width:100%;
    }
    .settingInput > input::placeholder{
        color:var(--subfont);
    }

    .textDescription{
        font-size:1.2rem;
        font-weight:bold;
        color:rgba(255, 255, 255, 0.6);
        margin-top:1rem;
        word-break: break-all;
    }

    .textTitle{
        font-size:1.4rem;
        font-weight:bold;
        color:#fff;
    }

    .navBox>span{
        font-size:2rem;
        color:rgba(255,255,255,0.65);
    }
    .goback{
        transform: rotate(180deg);
        opacity: 0.65;
        width: 2rem;
        height: 2rem;
        cursor:pointer;
    }
    .navBox{
        background:var(--primary);
        height:4rem;
        width:100%;
        box-sizing:border-box;
        padding:0 1rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
        position:absolute;
        top:0;
        left:0;
        z-index:10;
    }
    .container{
        height:100%;
        overflow:hidden;
        width:100%;
        position:relative;
    }
    .text{
        height:auto;
        overflow-x:hidden;
        overflow-y:scroll;
        scrollbar-width:none;
        position:relative;
        width:100%;
        height:calc(100% - 4rem);
        padding:2rem 2rem;
        padding-top:6rem;
        background:var(--primary);
        box-sizing:border-box;
        display:flex;
        flex-direction: column;
    }

</style>