<template>
    <div class="recent-item" @click="debouncedHandleClick">
        <div class="skeleton" v-show="loading">
            <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M298.666667 298.666667h426.666666c140.8 0 256 115.2 256 256s-115.2 256-256 256c-72.533333 0-140.8-29.866667-192-85.333334h-46.933333c-93.866667 106.666667-256 115.2-362.666667 21.333334s-115.2-256-21.333333-362.666667C157.866667 328.533333 226.133333 298.666667 298.666667 298.666667m-42.666667 128v85.333333H170.666667v85.333333h85.333333v85.333334h85.333333v-85.333334h85.333334v-85.333333H341.333333v-85.333333H256m405.333333 128c-34.133333 0-64 29.866667-64 64s29.866667 64 64 64 64-29.866667 64-64-29.866667-64-64-64m128-128c-34.133333 0-64 29.866667-64 64s29.866667 64 64 64 64-29.866667 64-64-29.866667-64-64-64z" fill="#252e3a">
                </path>
            </svg>
        </div>
        <img :src="item.fullGameImg" v-show="!loading" @load="imgOnload"/>
    </div>
</template>

<script>
export default {
    name: "RecentItem",
    props: {
        item: {
            type: Object,
        },
        click: {
            type: Function,
            default: () => {
            }
        }
    },
    mounted() {

    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        imgOnload() {
            this.loading = false;
        },
        handleClick() {
            if(!this.item.isMaintenance){
                this.click(this.item);
            }
        },
        // 包装的防抖点击方法
        debouncedHandleClick: debounce(function () {
            this.handleClick();
        }, 300) // 300 毫秒防抖时间
    }
}

// 防抖函数定义（可以放在同一文件或单独文件中）
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
</script>

<style scoped>

.recent-item img {
    width: 100%;
    height: 11rem;
    margin-top: -1rem;
}

.recent-item:nth-child(5n){
    margin-right:0 !important;
}

.recent-item {
    height: 9rem;
    width: calc((100% - 8rem) / 5);
    overflow: hidden;
    position: relative;
    border-radius: 1rem;
    margin-right:1.25rem;
    margin-bottom:2rem;
}
</style>
