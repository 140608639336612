<template>
    <div class="container" id="tabbar" :style="'max-width:'+maxWidth + 'px'">
        <div @click="toTabbar(item)" :class="item.active ? 'tabbar-item tarbbaractive buttonTransition' : 'tabbar-item buttonTransition'" v-for="(item,index) in tabbarListC" :key="index">
            <div class="tabbarItemSvg" v-html="item.icon">
            </div>
            <span v-text="$t(item.name)"></span>
            <template v-if="token">
                <template v-if="usermessage > 0 && item.path == '/inbox'">
                    <div class="globalMessage" v-text="usermessage">
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>

import EventBus from "@/main";
import { mapState } from 'vuex';

export default {
        name:"Tabbar",
        data(){
            return {
                tabbarListC:[],
                maxWidth:0,
            }
        },
        computed:{
            ...mapState({
                tabbarList: state => state.user.tabbarList,
                usermessage:state => state.user.messageCount,
                token:state => state.user.token
            })
        },
        methods:{
            toTabbar(item){
                this.$router.push(item.path)
                this.tabbarList.forEach((item2) => {
                    item2.active = item2.name === item.name;
                });
                this.$forceUpdate();
            },
            changeSize(){
                if(document.body.style.maxWidth.trim()){
                    this.maxWidth = parseInt(document.body.style.maxWidth);
                }else{
                    this.maxWidth = window.innerWidth;
                }
            },
            initTabbarList(value){ // 初始化tabbarlist
                let temp = [...value];
                this.tabbarListC = temp.sort((a, b) => a.sort - b.sort).map((item,index) => {
                    item.active = false;
                    if(item.path == this.$route.path){
                        item.active = true;
                    }
                    return item;
                })
            }
        },
        watch:{
            tabbarList(newValue){ // tabbar变化时
                this.initTabbarList(newValue);
            },
            '$route.path'(newValue){
                this.tabbarListC = this.tabbarListC.map(item => {
                    item.active = false;
                    if(newValue.indexOf(item.path) != -1){
                        item.active = true;
                    }
                    return item;
                })
            }
        },
        mounted() {
            EventBus.$on('change-tabbar', () => {
                this.changeSize()
            });
            this.$nextTick(() => {
                this.changeSize();
            });
        },
    }
</script>

<style>
    .tabbarItemSvg > svg {
        width:100%;
        height:100%;
    }
    .tabbarItemSvg path {
        fill:var(--icon);
    }
    .tabbarItemSvg circle {
        stroke:var(--icon);
    }
</style>

<style scoped>

    .tarbbaractive {
        background: radial-gradient(circle, var(--tabbar) 10%, var(--tabbar2) 40%, var(--primary) 80%);
    }

    .tarbbaractive span {
        color: var(--mainfont) !important;
    }

    .globalMessage{
        position: absolute;
        right:.5rem;
        top:.5rem;
        z-index:10;
        width:2rem;
        height:2rem;
        border-radius:50%;
        background:var(--error);
        display:flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        border:1px solid var(--warning);
    }
    .container{
        z-index:1901;
        position:fixed;
        bottom:0;
        left: 50%;
        transform: translateX(-50%);
        max-width:557px;
        height:8rem;
        width:100%;
        background:var(--primary);
        box-sizing: border-box;
        padding:0 1rem;
        display:flex;
        justify-content: space-between;
        overflow:hidden;
    }
    .tabbarItemSvg{
        height:3rem;
        width:3rem;
    }
    .tabbar-item{
        width:15%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position:relative;
    }
    .tabbar-item span{
        color:var(--subfont);
        font-size:1.5rem;
        font-weight:bold;
    }
</style>
