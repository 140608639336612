<template>
    <div class="childBox" style="color:#fff">
        <div class="noticeBox" :style="'background-image:url(' + Bg + ')'">
            <span class="noticeTitle">{{ $t("InviteTips") }}</span>
            <span class="noticeSubTitle">{{ $t("YourLink") }}</span>
            <div class="linkBox">
                <span v-text="referralLink"></span>
                <div class="copyButton" @click="copyText" :data-clipboard-text="referralLink" id="copyButton">
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M768 682.666667V170.666667a85.333333 85.333333 0 0 0-85.333333-85.333334H170.666667a85.333333 85.333333 0 0 0-85.333334 85.333334v512a85.333333 85.333333 0 0 0 85.333334 85.333333h512a85.333333 85.333333 0 0 0 85.333333-85.333333zM170.666667 170.666667h512v512H170.666667z m682.666666 85.333333v512a85.333333 85.333333 0 0 1-85.333333 85.333333H256a85.333333 85.333333 0 0 0 85.333333 85.333334h426.666667a170.666667 170.666667 0 0 0 170.666667-170.666667V341.333333a85.333333 85.333333 0 0 0-85.333334-85.333333z" fill="#ffffff">
                        </path>
                    </svg>
                </div>
            </div>
            <span class="noticeSubTitle">{{ $t("ShareLink") }}:</span>
            <div class="shareList">
                <div class="shareItem" @click="toOther('tg')">
                    <img :src="TG" />
                </div>
                <div class="shareItem" @click="toOther('ins')">
                    <img :src="INS" />
                </div>
                <div class="shareItem" @click="toOther('ytb')">
                    <img :src="YTB" />
                </div>
                <div class="shareItem" @click="toOther('wapp')">
                    <img :src="WAPP" />
                </div>
                <div class="shareItem" @click="toOther('x')">
                    <img :src="X" />
                </div>
                <div class="shareItem" @click="toOther('tk')">
                    <img :src="TK" />
                </div>
            </div>
        </div>
        <div class="optionBox">
            <div class="optionList">
                <div class="optionLabel">
                    <span class="optionLabelText">{{ $t("Bonus") }}</span>
                    <span class="optionLabelValue">0</span>
                </div>
                <div class="optionButtonList">
                    <div class="withdrawButton buttonTransition" @click="toWithdraw">
                        {{ $t("Withdraw") }}
                    </div>
                    <div class="transferButton buttonTransition" @click="showModal">
                        {{ $t("Transfer") }}
                    </div>
                </div>
            </div>
            <div class="contentBox">
                {{ $t("ProxyBonusTips") }}
            </div>
        </div>
        <div class="rewardsBox">
            <span class="ruleTitle">{{ $t("AffiliateProgram") }}</span>
            <span class="affiliateDes">{{ $t('LancomeModel') }}</span>
            <div class="levelBox">
                <img :src="Level" />
                <span class="You">{{ $t("You") }}</span>
                <span class="Wager">{{ $t("BettingDiscounts") }}</span>
                <span class="Refer">{{ $t("ReferMembers") }}</span>
                <span class="level1">{{ $t("Level") }} 1</span>
                <span class="level2">{{ $t("Level") }} 2</span>
                <span class="level3">{{ $t("Level") }} 3</span>
            </div>
        </div>
        <div class="description">{{ $t("ProxyRuleTitle") }}</div>
        <div class="levelDes" v-html="proxyRule">
        </div>
        <Modal :visible="transferVisible" :gradient="true" :padding="false">
            <div class="transferBox">
                <span>{{ $t("ProxyBonusModalTips") }}</span>
                <div class="transferOption">
                    <div class="closeButton" @click="transferVisible = false">
                        {{ $t("Cancel") }}
                    </div>
                    <div class="queryButton" @click="transferVisible = false">
                        {{ $t("OK") }}
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>

    import Bg from "../assets/img/noticeBg.png"
    import Coin from "../assets/img/coin.png"
    import Group from "../assets/img/group.png"
    import Level from "../assets/img/level.png"
    import Modal from "../components/Modal.vue"

    import Clipboard from 'clipboard';

    import TG from "@/assets/refer/tg.webp";
    import INS from "@/assets/refer/ins.webp";
    import YTB from "@/assets/refer/ytb.webp";
    import WAPP from "@/assets/refer/wapp.webp";
    import X from "@/assets/refer/x.webp";
    import TK from "@/assets/refer/tk.webp";

    import { GetUserRule } from "@/api/proxy.js";

    export default {
        name:"ReferCom",
        data(){
            return {
                X,
                WAPP,
                INS,
                TG,
                YTB,
                TK,

                Bg,
                Coin,
                Group,
                Level,
                transferVisible:false,

                proxyRule:""
            }
        },
        mounted(){
            this.getRule();
        },
        computed:{
            referralLink(){
                return `${window.location.origin}/#/?agentid=${this.$store.state.user.user.userId}`
            }
        },
        components:{
            Modal
        },
        methods:{
            getRule(){
                GetUserRule().then(res => {
                    if(res.code == 200){
                        this.proxyRule = res.data.content;
                    }
                })
            },
            toOther(type){
                const currentUrl = window.location.href; // 获取当前站点 URL
                const currentTitle = document.title; // 获取当前页面标题
                switch(type){
                    case "tg":
                        window.open(`https://t.me/share/url?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(currentTitle)}`, '_blank');
                        break;
                    case "ins":
                        window.open(`https://www.instagram.com/?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(currentTitle)}`, '_blank');
                        break;
                    case "ytb":
                        window.open(`https://www.youtube.com/results?search_query=${encodeURIComponent(currentTitle + ' ' + currentUrl)}`, '_blank');
                        break;
                    case "wapp":
                        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(currentTitle + ' ' + currentUrl)}`, '_blank');
                        break;
                    case "x":
                        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(currentTitle)}&url=${encodeURIComponent(currentUrl)}`, '_blank');
                        break;
                    case "tk":
                        window.open(`https://www.tiktok.com/share?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(currentTitle)}`, '_blank');
                        break;
                }
                // this.$router.push("/other")
            },
            copyText(){
                this.$message.info(this.$t("CopySuccess"));
                new Clipboard('#copyButton')
            },
            toHelp(){
                this.$router.push("/help")
            },
            toWithdraw(){
                this.$router.push("/deposit")
            },
            toBonus(){
                this.$router.push("/refer/bonus")
            },
            showModal(){
                this.transferVisible = true;
            }
        }
    }
</script>

<style scoped>
    .level3{
        color:var(--basic);
        position:absolute;
        left:46%;
        font-size:1.2rem;
        top:30.2rem;
    }
    .level2{
        color:var(--basic);
        position:absolute;
        left:46%;
        font-size:1.2rem;
        top:23.5rem;
    }
    .level1{
        color:var(--basic);
        position:absolute;
        left:46%;
        font-size:1.2rem;
        top:17.2rem;
    }

    .bonusItemValue{
        font-size:1.5rem;
        color:var(--basic);
        font-weight:bold;
        margin-bottom:1rem;
    }
    .bonusItemTitle{
        color:var(--mainfont);
        font-weight:bold;
        font-size:1.3rem;
        white-space: nowrap;
        margin-bottom:2rem;
    }
    .bonusTextItem{
        background:linear-gradient(to bottom,var(--sub7black),var(--sub8black));
        width:48%;
        display:flex;
        box-sizing:border-box;
        padding:1.5rem 2rem;
        border-radius:1rem;
        align-items: center;
        flex-direction: column;
        margin-bottom:4rem;
    }
    .bonusText{
        margin:0 auto;
        margin-top:3rem;
        display:flex;
        justify-content: space-between;
        width:100%;
    }
    .bonusTitle>svg{
        width:2rem;
        height:2rem;
    }
    .bonusTitle>span{
        color:#fff;
        font-size:2rem;
        font-weight:bold;
    }
    .bonusTitle{
        display:flex;
        justify-content: space-between;
    }
    .bonusBox{
        box-sizing: border-box;
        padding:3rem 2rem;
    }
    .queryButton{
        width:12rem;
        height:4rem;
        background-color:var(--success);
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.6rem;
        font-weight:bold;
        border-radius:1.5rem;
    }
    .closeButton{
        width:12rem;
        height:4rem;
        background-color:var(--error);
        color:#fff;
        display:flex;
        align-items: center;
        margin-right:3rem;
        justify-content: center;
        font-size:1.6rem;
        font-weight:bold;
        border-radius:1.5rem;
    }
    .transferOption{
        display:flex;
        justify-content: center;
        margin-top:2rem;
    }
    .transferBox>span{
        color:var(--mainfont);
        font-size:1.7rem;
        font-weight:bold;
        word-break: break-all
    }
    .transferBox{
        display:flex;
        flex-direction: column;
        box-sizing:border-box;
        padding:2rem 2rem;
    }
    .levelDes>span{
        font-size:1.5rem;
        color:var(--basic);
        text-align: center;
        line-height:2rem;
    }
    .levelDes{
        background:var(--primary);
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding:2rem 1.5rem;
        padding-bottom:10rem;
        margin-top:1.5rem;
        border-radius:1rem;
    }
    .Refer{
        color:var(--basic);
        position:absolute;
        left:58%;
        font-size:1.5rem;
        top:12rem;
    }
    .Wager{
        color:var(--basic);
        position:absolute;
        left:18%;
        font-size:1.5rem;
        top:12rem;
    }
    .You{
        color:var(--basic);
        position:absolute;
        left:48%;
        font-size:1.5rem;
        top:9rem;
    }
    .levelBox>img{
        width:100%;
        margin-top:2rem;
    }
    .levelBox{
        width:100%;
        height:fit-content;
        position:relative;
    }
    .affiliateDes{
        margin-top:1.5rem;
        font-size:1.5rem;
        color:var(--mainfont);
        font-weight:bold;
    }
    .rewardsGroup>img{
        height:1rem;
    }
    .rewardsGroup>span{
        color:var(--mainfont);
        font-size:1.3rem;
        font-weight:bold;
        margin-left:.5rem;
    }
    .rewardsGroup{
        margin-top:.5rem;
    }
    .rewardsPrice{
        color:#fff;
        font-size:1.3rem;
        margin-bottom:.5rem;
    }
    .rewardsItems>img{
        width:4rem;
    }
    .rewardsItems{
        display:flex;
        flex-direction: column;
        align-items: center;
        position:absolute;
        left:30%;
    }
    .rewardsProcess{
        background:var(--subfont);
        width:100%;
        height:1.5rem;
        flex:1;
        border-radius:2rem;
    }
    .rewardsText>svg{
        height:2.5rem;
        width:2.5rem;
        margin-right:2rem;
    }
    .rewardsText{
        display:flex;
        align-items: center;
        justify-content: center;
        width:100%;
        margin-bottom:2rem;
        position:relative;
        height:10rem;
    }
    .rewardsDes{
        color:var(--mainfont);
        font-size:1.3rem;
        font-weight:bold;
        text-align: center;
    }
    .rewardsBox{
        background:var(--primary);
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding:2rem 1.5rem;
        margin-top:1.5rem;
        border-radius:1rem;
    }
    .ruleText{
        margin-top:.5rem;
        color:var(--mainfont);
        font-size:1.3rem;
        font-weight:bold;
    }
    .ruleItemTitle{
        display:flex;
        justify-content: space-between;
    }
    .ruleItemTitle>span:nth-child(1){
        color:#fff;
        font-weight:bold;
        font-size:1.3rem;
    }
    .ruleItemTitle>span:nth-child(2){
        color:#3084fc;
        font-weight:bold;
        font-size:1.3rem;
        text-decoration: underline;
    }
    .ruleItem{
        width:100%;
        display:flex;
        flex-direction: column;
        margin-top:1rem;
    }
    .ruleTitle{
        color:var(--blue);
        font-size:1.5rem;
        margin-bottom:1rem;
        font-weight:bold;
    }
    .ruleBox{
        background:var(--primary);
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding:2rem 1.5rem;
    }
    .description{
        font-size:1.3rem;
        font-weight:bold;
        text-align: center;
        margin-top:2rem;
        margin-bottom:2rem;
        color:var(--basic);
    }
    .contentBox{
        word-break: break-all;
        color:var(--basic);
        font-size:1.3rem;
        margin-top:2rem;
    }
    .transferButton{
        background:var(--error);
        padding:.7rem 1.5rem;
        color:#fff;
        border-radius:1rem;
        font-size:1.3rem;
    }
    .withdrawButton{
        background:var(--success);
        padding:.7rem 1.5rem;
        color:#fff;
        border-radius:1rem;
        font-size:1.3rem;
        margin-right:1.5rem;
    }
    .optionList{
        display:flex;
        justify-content: space-between;
        align-items: center;
        width:100%;
    }
    .optionLabelText{
        color:var(--mainfont);
        font-size:1.5rem;
        font-weight:bold;
    }
    .optionLabelValue{
        color:var(--basic);
        font-size:2rem;
        margin-left:1rem;
        font-weight:bold;
    }
    .optionButtonList{
        display:flex;
    }
    .optionLabel{
        
    }
    .optionBox{
        background:var(--primary);
        padding:2rem 1.5rem 1.5rem 1.5rem;
        margin-top:2rem;
        border-radius:1rem;
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .shareItem img{
        height:100%;
        width:100%;
    }

    .shareItem{
        margin-right:1rem;
        display:flex;
        justify-content: center;
        align-items: center;
        width:4rem;
        height:4rem;
        /* background:var(--sub5black); */
        border-radius:50%;
        overflow:hidden;

    }
    .shareList{
        display:flex;
        margin-top:1rem;
    }
    .copyButton svg{
        height:1.5rem;
        width:1.5rem;
    }
    .copyButton{
        display:flex;
        align-items: center;
        justify-content: center;
        background:var(--blue);
        width:2.3rem;
        height:2.3rem;
        border-radius:.5rem;
    }
    .noticeSubTitle{
        color:#fff;
        margin-top:1rem;
        font-size:1.3rem;
        font-weight:bold;
    }
    .linkBox span{
        box-sizing:border-box;
        padding:0.5rem 1rem;
        display:block;
        font-size:1.3rem;
        color:var(--mainfont);
    }
    .linkBox{
        margin-top:1rem;
        background:rgba(0,0,0,0.5);
        border-radius:.5rem;
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .noticeBox{
        height:fit-content;
        width:100%;
        background-repeat:no-repeat;
        background-size:100% 100%;
        margin-top:1.5rem;
        border-radius:1rem;
        box-sizing:border-box;
        padding:1.5rem 3rem;
        display:flex;
        flex-direction: column;
    }
    .noticeTitle{
        color:var(--mainfont);
        font-size:1.5rem;
        font-weight:bold;
    }
    .childBox{
        height:auto;
        overflow:hidden;
        width:100%;
    }
</style>