<template>
    <div :class=" mode=='dark' ? 'emptyBox' : 'lightBox'">
        <!-- <img :style="color ? 'filter:' + hexColor : ''" :src="mode == 'dark' ? Empty : EmptyLight" /> -->
        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
            <path d="M469.333333 234.666667V106.666667h85.333334v128h-85.333334z m-161.834666-94.165334l85.333333 85.333334-60.330667 60.330666-85.333333-85.333333 60.330667-60.330667z m469.333333 60.330667l-85.333333 85.333333-60.330667-60.330666 85.333333-85.333334 60.330667 60.330667z m-548.693333 182.826667A85.333333 85.333333 0 0 1 301.845333 341.333333H722.133333a85.333333 85.333333 0 0 1 73.706667 42.346667l131.2 224.853333A85.333333 85.333333 0 0 1 938.666667 651.541333V832a85.333333 85.333333 0 0 1-85.333334 85.333333H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333333v-180.48a85.333333 85.333333 0 0 1 11.626667-42.986667l131.178667-224.853333zM722.133333 426.666667H301.866667l-99.562667 170.666666h255.146667l9.6 28.8a58.602667 58.602667 0 0 0 2.133333 4.778667c1.92 3.84 4.906667 8.896 8.96 13.781333 7.829333 9.408 18.133333 16.64 33.877333 16.64 15.744 0 26.026667-7.232 33.898667-16.64a72.469333 72.469333 0 0 0 11.050667-18.453333l0.021333-0.106667 9.6-28.8h255.146667l-99.562667-170.666666zM853.333333 682.666667H623.509333a154.730667 154.730667 0 0 1-12.074666 16.64C592.64 721.92 560.256 746.666667 512 746.666667c-48.256 0-80.64-24.768-99.434667-47.36a154.730667 154.730667 0 0 1-12.074666-16.64H170.666667v149.333333h682.666666v-149.333333z" fill="#4E5969"></path>
        </svg>
        <span :style="color ? 'color:' + color + '' : ''" class="emptyText">{{ $t("NoData") }}</span>
    </div>
</template>

<script>

    export default {
        name:"Empty",
        props:{
            mode:{
                type: String,
                default: 'dark' // 默认值
            },
            color:{
                type:String,
                default:"",
            }
        },
        computed:{
            hexColor(){
                let hex = this.color;
                // 将十六进制颜色值转换为 RGB
                const hexToRgb = hex => {
                    let r = parseInt(hex.slice(1, 3), 16);
                    let g = parseInt(hex.slice(3, 5), 16);
                    let b = parseInt(hex.slice(5, 7), 16);
                    return { r, g, b };
                };
                
                const rgb = hexToRgb(hex);

                // 使用算法计算与该颜色最接近的滤镜效果
                let sepia = 100;  // 固定为100% sepia
                let saturate = 1000;  // 增加饱和度
                let hueRotate = (Math.atan2(rgb.g - rgb.b, rgb.r - rgb.b) * 180 / Math.PI) || 0;

                // 返回滤镜字符串
                return `sepia(${sepia}%) saturate(${saturate}%) hue-rotate(${hueRotate}deg)`;
            }
        },
        data(){
            return {
            }
        },
        methods:{

        }
    }
</script>

<style>

    .lightBox{
        display:flex;
        width:100%;
        height:100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .lightBox>svg{
        width:6rem;
        height:6rem;
    }
    .lightBox path {
        fill:var(--subfont);
    }
    .lightBox>span{
        color:var(--subfont);
        margin-top:1rem;
        font-size:1.5rem;
    }

    .emptyBox{
        display:flex;
        width:100%;
        height:100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .emptyBox>svg{
        width:6rem;
        height:6rem;
    }
    .emptyBox path {
        fill:var(--subfont);
    }
    .emptyBox>span{
        color:var(--subfont);
        margin-top:1rem;
        font-size:1.5rem;
    }
</style>