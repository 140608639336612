<template>
    <div class="notification-bar" v-if="isShow">
        <div class="notificationDesign">
            <div class="notificationRadio">
            </div>
        </div>
        <div class="notification">
            <transition name="slide">
                <div class="notification-item">
                    {{ notifications }}
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex';

    export default {
        name: "Notice",
        data() {
            return {

            }
        },
        computed:{
            ...mapState({
                notifications: state => state.user.noticeList,
            }),
            isShow(){
                this.$emit("changeNoticeVisible",this.notifications.length != 0)
                return this.notifications.length != 0;

                // this.$emit("changeNoticeVisible",false)
                // return false;
            }
        },
        methods:{
        },
        mounted(){
        }
    }

</script>

<style scoped>
    .notificationRadio {
        height: 1rem;
        width: 1rem;
        background-color: var(--success);
        box-shadow: 0px 0px 10px var(--success);
        border-radius: 50%;
    }

    .notificationDesign {
        height: 3rem;
        width: 3rem;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .notification-bar {
        width: 100%; /* 设置通知栏宽度 */
        overflow: hidden; /* 隐藏溢出内容 */
        background-color: rgba(0, 0, 0, 0.2);
        height: 3rem;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        margin-bottom:1.5rem;
    }

    .notification {
        white-space: nowrap; /* 禁止换行 */
        width: calc(100% - 3rem);
        margin-left: 3rem;
        overflow: hidden;
    }

    .notification-item {
        color: #fff;
        display: inline-block; /* 内联布局 */
        padding-right: 20px; /* 右边距，调整公告之间的间隔 */
        animation: slide 10s linear infinite; /* 设置动画效果 */
    }

    @keyframes slide {
        0% {
            transform: translateX(40rem); /* 从屏幕右侧外开始 */
        }
        100% {
            transform: translateX(-100%); /* 移动到完全左侧外 */
        }
    }
</style>