<template>
    <div class="box allPageBox">
        <!--切换 提现和充值导航-->
        <img :src="PriceButton" v-show="false"/>
        <div class="header">
            <div class="navList">
                <div @click="changeActive(index)" :class="item.active ? 'navItem active' : 'navItem'" v-for="(item,index) in navList" :key="index" v-text="item.name">
                </div>
            </div>
            <svg @click="goBack" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path>
            </svg>
        </div>
        <div class="text">
            <!--充值-->
            <template v-if="navList[0].active">
                <div class="text-title">{{ $t("SelectType") }}</div>
                <div class="textOptions">
                    <div @click="typeClick(index)" :class="payMethodSelected===index ? 'textItem active buttonTransition' : 'textItem buttonTransition'" v-for="(item,index) in payconfig" :key="item.methodId">
                        <img :src="item.fullMethodIcon" />
                        <span v-text="item.methodName"></span>
                    </div>
                </div>
                <div class="text-title">{{ $t("SelectChannel") }}</div>
                <div class="textOptions">
                    <div @click="channelClick(index)" :class="payChannelSelected===index ? 'textItem active buttonTransition' : 'textItem buttonTransition'" v-for="(item,index) in payconfig[payMethodSelected]?.payChannels" :key="item.channelId">
                        <span v-text="item.name"></span>
                    </div>
                </div>
                <div class="text-title">{{ $t("AmountOption") }}</div>
                <div class="priceOptions" v-if="payconfig[payMethodSelected]?.payChannels[payChannelSelected]"><!-- -image:url(' + PriceButton + ') -->
                    <div @click="payAmountClick(index)" :class="payMoneySelected===index ? 'priceItem active buttonTransition' : 'priceItem buttonTransition'" :style="payMoneySelected===index ? 'background: radial-gradient(circle, var(--gdient1), var(--gdient2));' : ''" v-for="(item,index) in payconfig[payMethodSelected]?.payChannels[payChannelSelected]?.payConfig.fastAmount.split(',')" :key="index">
                        <div class="priceButtonDesign" :style="payMoneySelected===index ? 'background-image:url(' + PriceButton + ')' : '' ">
                            
                        </div>
                        <span>{{ item }}</span>
                        <div class="plusPrice" v-if="rechargeActivity" v-text="mathPrize(item,rechargeActivityPriceList)">
                        </div>
                        <div class="plusDesign" :style="payMoneySelected===index ? '' : 'display:none'">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path d="M442.88 1010.176c-147.968-183.296-322.048-322.048-442.88-365.568l286.72-172.032 138.24 268.8S650.24 195.584 1006.08 13.824C998.4 143.872 963.072 256.512 1024 395.264c-156.16 35.328-476.672 424.96-581.12 614.912z" fill="#ffffff"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <el-form ref="depositForm" :model="depositForm" :rules="depositRules">
                    <div class="text-title">{{ $t("EnterYourAmount") }}</div>
                    <div class="InputBox">
                        <div style="display:flex;align-items: center;width:100%">
                            <div class="selectBox">
                                R$
                            </div>
                            <el-form-item prop="rechargeAmount" style="width:100%">
                                <input v-model="depositForm.rechargeAmount" @blur="changeDepositPrice" @focus="rechargeAmountFocus" :min="depositForm.min" :max="depositForm.max" type="number" placeholder="Please enter your amount" style="width:100%"/>
                            </el-form-item>
                            <span v-if="rechargeActivity" style="color:var(--success);font-size:1.5rem;">{{ $t("Extra") }}+R${{ mathPrize(this.depositForm.rechargeAmount,this.rechargeActivityPriceList) }}</span>
                        </div>
                    </div>
                    <div class="inputDesBox" v-if="rechargeActivityTitle"> 
                        <Checkbox :size="2.5" :model.sync="rechargeActivity"></Checkbox>
                        <div class="inputDes">
                            <span style="" v-text="rechargeActivityTitle"></span>
                        </div>
                    </div>
                    <div class="LoginButton" @click="deposit">
                        {{ $t("Pay") }}
                        <span style="font-size:1rem;margin-left:0.3rem" v-loading="depositLoading"></span>
                    </div>
                </el-form>
                <div class="text-des" v-html="depositRule">
                </div>
            </template>
            <!--提现-->
            <template v-else>
                <div class="withdrawBox">
                    <div class="withdrawFirst">
                        <div class="withdrawBalance">
                            <span class="withdrawBalanceLabel">{{ $t("Withdrawable") }}</span>
                            <span class="withdrawBalanceVal" v-text="withdrawBalance"></span>
                        </div>
                        <!--帮助问号-->
                        <svg @click="showWithdraw" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m32 704h-64v-64h64v64z m11.2-203.2l-5.6 4.8c-3.2 2.4-5.6 8-5.6 12.8v58.4h-64v-58.4c0-24.8 11.2-48 29.6-63.2l5.6-4.8c56-44.8 83.2-68 83.2-108C598.4 358.4 560 320 512 320c-49.6 0-86.4 36.8-86.4 86.4h-64C361.6 322.4 428 256 512 256c83.2 0 150.4 67.2 150.4 150.4 0 72.8-49.6 112.8-107.2 158.4z" fill="var(--subfont)"></path>
                        </svg>
                    </div>
                    <el-form ref="withdrawForm" :model="withdrawForm" :rules="withdrawRules">
                        <div class="formField">
                            <span>{{ $t("EnterYourAmount") }}<span style="margin-left:.5rem;color:#fb0101;font-size:.8rem;margin-bottom:.3rem">*</span></span>
                            <div class="InputBox2">
                                <div style="display:flex;align-items: center;width:100%">
                                    <div class="selectBox2">
                                        <span>R$</span>
                                    </div>
                                    <el-form-item prop="withdrawAmount" style="flex:1;width:100%">
                                        <input @blur="changeWithdrawPrice" v-model="withdrawForm.withdrawAmount" type="text" :placeholder="$t('PleaseEnterYourAmount')" style="width:100%"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <span class="inputDescript">{{ $t("WithdrawalFee") }}:R${{ withdrawForm.withdrawAmount }}</span>
                        </div>

                        <div class="formField">
                            <span>Carteira / Banco / Cartao<span style="margin-left:.5rem;color:#fb0101;font-size:.8rem;margin-bottom:.3rem">*</span></span>
                            <div class="formPayMethod">
                                <div :class="withdrawAccountActive == index ? 'formWithdrawItem active' : 'formWithdrawItem'" v-for="(item,index) in withdrawAccount" :key="index" @click="selectWithdrawAccount(index)">
                                    <!-- <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                        <path d="M484.8 585c10.8-10.8 29.4-10.8 40.2 0l154 154c28.4 28.4 66.2 44 106.2 44h30.2l-194.2 194.2c-60.6 59-159 59-219.6 0l-195-194.8h18.6c40 0 77.8-15.6 106.2-44l153.4-153.4z m40.2-147.2c-12.8 11-29.2 11.2-40.2 0l-153.4-153.4c-28.4-30.2-66.2-44-106.2-44h-18.6l194.8-194.88c60.8-60.692 159.2-60.692 219.8 0l194.4 194.28h-30.4c-40 0-77.8 15.6-106.2 44l-154 154z m-299.8-152.4c27.6 0 53 11.2 74.2 30.8l153.4 153.4c14.4 12.6 33.2 21.6 52.2 21.6 18.8 0 37.6-9 52-21.6l154-154c19.6-19.4 46.6-30.6 74.2-30.6h75.4l116.6 116.6c60.6 60.6 60.6 159 0 219.6l-116.6 116.6h-75.4c-27.6 0-54.6-11.2-74.2-30.8l-154-154c-27.8-27.8-76.4-27.8-104.2 0.2l-153.4 153.2c-21.2 19.6-46.6 30.8-74.2 30.8H161.56l-116.04-116c-60.692-60.6-60.692-159 0-219.6l116.04-116.2h63.64z" fill="#1afa29">
                                        </path>
                                    </svg> -->
                                    <img :src="PIX" />
                                    <div class="formWithdrawText">
                                        <span>PIX<span>({{ item.accountType }})</span></span>
                                        <span v-text="maskString(item.account)"></span>
                                    </div>
                                    <div class="plusDesign" :style="withdrawAccountActive===index ? 'background:green' : 'background:green;display:none'">
                                        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                            <path d="M442.88 1010.176c-147.968-183.296-322.048-322.048-442.88-365.568l286.72-172.032 138.24 268.8S650.24 195.584 1006.08 13.824C998.4 143.872 963.072 256.512 1024 395.264c-156.16 35.328-476.672 424.96-581.12 614.912z" fill="#ffffff"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div class="formPayMethodItem" @click="showConfirm">
                                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                        <path d="M960 256v64H64v-64c0-35.2 28.8-64 64-64h768c35.2 0 64 28.8 64 64z m0 128v384c0 35.2-28.8 64-64 64H128c-35.2 0-64-28.8-64-64V384h896zM256 640H128v32h128v-32z m128-64H128v32h256v-32z" fill="#583ece"></path>
                                    </svg>
                                    <span>{{ $t("AddPIX") }}</span>
                                    <svg
                                        class="icon"
                                        viewBox="0 0 1024 1024"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="200"
                                        height="200"
                                        >
                                        <path d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                                            fill="#583ece">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div class="formField">
                            <div class="formFieldTitle" style="display:flex;justify-content: space-between">
                                <span>{{ $t("TransactionPassword") }}<span style="margin-left:.5rem;color:#fb0101;font-size:.8rem;margin-bottom:.3rem">*</span></span>
                                <template v-if="entryPasswordType === 'password'">
                                    <svg @click="entryPasswordType = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                        <path d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z" fill="#495669"></path>
                                    </svg>
                                </template>
                                <template v-if="entryPasswordType === 'text'">
                                    <svg @click="entryPasswordType = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                        <path d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z" fill="#495669"></path>
                                    </svg>
                                </template>
                            </div>
                            <MobileInput :password.sync="withdrawForm.transactionPassword" :passwordType.sync="entryPasswordType"></MobileInput>
                        </div>

                        <div class="LoginButton" @click="withdraw" :style="canSubmit ? '' : 'filter: brightness(80%);'">
                            {{ $t("Pay") }}
                            <span style="font-size:1rem;margin-left:0.3rem" v-loading="depositLoading"></span>
                        </div>

                        <div class="formDes" v-html="withdrawRule">
                        </div>
                    </el-form>
                </div>
            </template>
        </div>
        <Modal
            :visible="withdrawVisible"
            :padding="false"
            :gradient="true"
        >
            <div class="withdrawModalBox">
                <div class="withdrawModalTitle">
                    <span></span>
                    <span class="withdrawModalTitleText">{{ $t("WithdrawalInstructions") }}</span>
                    <svg @click="withdrawVisible = false" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path>
                    </svg>
                </div>
                <Withdrawal></Withdrawal>
                <div class="withdrawDes">
                    1.Kapag tumaya ka, nag-reload at tumanggapng mga bonus, maa-update ang iyong progresosa withdrawal.
                </div>
                <div class="withdrawDes">
                    2.Kapag nakumpleto mo ang iyong pag-usad ng withdrawal, ang balanse ng iyong account aymako-convert
                    sa halaga ng withdrawal.
                </div>
            </div>
        </Modal>
        <Modal :visible.sync="depositVisible" :gradient="false" :padding="false" :hasBg="false">
            <PayModal :channelImg="payconfig[payMethodSelected]?.fullMethodIcon" :visible.sync="depositVisible" :depositForm="depositForm" :depositResult="depositResult" :endTime="depositEndTime" :type="type" @showDeposit="showDeposit"></PayModal>
        </Modal>
        <Modal :beforeClose="() => changeActive(0)" :visible.sync="setTranscationPassword" :gradient="false" :title="$t('ChangePassword')">
            <SetTranscationModal :visible.sync="setTranscationPassword">
            </SetTranscationModal>
        </Modal>
        <Modal :visible.sync="confirmTranscationPassword" :gradient="false">
            <ConfirmTranscationmModal @successCheck="successCheck" :visible.sync="confirmTranscationPassword">
            </ConfirmTranscationmModal>
        </Modal>
        <Modal :visible.sync="transcationInfoModal" :gradient="false">
            <TranscationInfoModal :password="transcationInfoPassword" :visible.sync="transcationInfoModal">
            </TranscationInfoModal>
        </Modal>
    </div>
</template>

<script>

import PriceButton from "@/assets/img/priceButton.png"
import Wallet from "@/assets/img/wallet.png";
import PIX from "@/assets/withdraw/deposit_pix.webp";

import Modal from "@/components/Modal.vue";
import Table from "@/components/Table.vue";
import PayModal from "@/components/PayModal.vue";
import Checkbox from "@/components/Checkbox.vue";
import { mathPrize } from "@/utils/deposit.js";
import { maskString } from "@/utils/utils.js";

import { WithdrawalInfo,PayOrderCreate,RechargeRule,WithdrawRule,GetTranscationInfo,WithdrawAdd,GetRechargeActivity,GetRechargeActivityMoneyList,RechargeActivityParticipation } from "@/api/deposit";
import { mapState } from 'vuex';

import SetTranscationModal from "@/components/SetTranscationModal.vue";
import ConfirmTranscationmModal from "@/components/ConfirmTranscationmModal.vue";
import TranscationInfoModal from "@/components/TranscationInfoModal.vue";
import MobileInput from "@/components/MobileInput.vue";
import Withdrawal from "@/components/Withdrawal.vue";

export default {
    name: "Deposit",
    data(){
        const validateZero = (rule, value, callback) => {
            // 检查转换后的值是否大于0
            if (!isNaN(value) && value > 0) {
                callback(); // 校验通过
            } else {
                callback(new Error('请输入大于0的有效数字')); // 校验不通过，返回错误信息
            }
        };
        return {
            maskString,
            mathPrize,
            PriceButton,
            Wallet,
            PIX,
            navList: [ // 头部切换列表
                {
                    active: false,
                    name:this.$t("Deposit")
                },
                {
                    active: true,
                    name:this.$t("Withdraw")
                }
            ],
            payMethodSelected:0,
            payChannelSelected:0,
            payMoneySelected:0,

            withdrawVisible: false, // 提现弹窗是否显示
            depositVisible:false, // 充值的确认弹框是否显示
            depositLoading:false,

            rechargeActivityTitle:"",
            rechargeActivityId:0,
            rechargeActivityPriceList:[],
            rechargeActivity:false,

            depositForm: { // 充值表单
                channelId:"", // 支付通道id
                rechargeAmount:"", // 充值金额
                min:0,
                max:0
            },

            depositEndTime:0,
            depositResult:{ // 创建到订单的结果
                
            },
            
            withdrawForm: { // 提现表单
                transactionPassword: "", // 交易密码
                account:"", // 本次提现收款账号
                accountType:"", // 账号类型
                withdrawAmount: 0, // 提现金额
            },
            withdrawRules: { // 提现表单的规则
                withdrawAmount: [
                    {validator: validateZero, trigger: 'blur'}
                ],
                account: [ 
                    {required: true, trigger: 'change'}
                ],
                accountType:[ // 账户类型
                    {required: true, trigger: 'change'}
                ],
                transactionPassword: [
                    {required: true, trigger: 'change'}
                ],
            },
            withdrawAccount:[
            ], // 提现账户列表
            withdrawAccountActive:-1,
            depositRules: { // 充值表单的规则
                rechargeAmount: [
                    { validator: validateZero, message: "", trigger: 'blur' }
                ]
            },

            depositRule:"", // 充值规则
            withdrawRule:"", // 提现规则

            withdrawBalance:0, // 可提现金额

            type:"qrcode",

            setTranscationPassword:false, // 设置交易密码的弹窗
            confirmTranscationPassword:false,
            transcationInfoModal:false,
            transcationInfoPassword:0,

            entryPasswordType:"password",
        }
    },
    computed:{
        ...mapState({
            payconfig: state => state.user.payconfig,
        }),
        canSubmit() {
            const { transactionPassword, account, accountType, withdrawAmount } = this.withdrawForm;

            const isFormValid = transactionPassword && account && accountType && withdrawAmount > 0;
            return isFormValid;
        }
    },
    watch:{
        transcationInfoModal(){
            this.updateWithdrawAccount();
        },
        payMoneySelected(){
            this.changePayMoneySelected();
        },
    },
    methods: {
        rechargeAmountFocus(){
            this.payMoneySelected = -1;
        },
        selectWithdrawAccount(index){
            this.withdrawAccountActive = index;
            this.withdrawForm.account = this.withdrawAccount[index].account;
            this.withdrawForm.accountType = this.withdrawAccount[index].accountType;
        },
        updateWithdrawAccount(){ // 刷新体现账户
            GetTranscationInfo().then(res => {
                if(res.code == 200){
                    this.withdrawAccount = res.data.withdrawalAccounts;
                }
            })
        },
        successCheck(password){
            this.confirmTranscationPassword = false;
            this.transcationInfoModal = true;
            this.transcationInfoPassword = password;
        },
        showConfirm(){ // 显示确认交易密码弹窗
            this.confirmTranscationPassword = true;
        },
        changePayMoneySelected(){
            this.depositForm.rechargeAmount = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected]?.payConfig.fastAmount.split(",")[this.payMoneySelected];
            this.changeDepositPrice();
        },
        initPayInfo(){
            this.depositForm.min = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected]?.payConfig.minAmount;
            this.depositForm.max = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected]?.payConfig.maxAmount;
            this.depositForm.channelId = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected].channelId;
        },
        payAmountClick(index){
            this.payMoneySelected = index;
            this.depositForm.rechargeAmount = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected]?.payConfig.fastAmount.split(",")[index];
        },
        changeDepositPrice(){ // 改变充值的价格时（失焦）
            if(this.depositForm.rechargeAmount > this.depositForm.max){
                this.depositForm.rechargeAmount = this.depositForm.max
            }
            if(this.depositForm.rechargeAmount < this.depositForm.min){
                this.depositForm.rechargeAmount = this.depositForm.min
            }
            this.depositForm.rechargeAmount = parseInt(this.depositForm.rechargeAmount, 10);
        },
        changeWithdrawPrice(){ // 改变提现的价格时（失焦）
            if(this.withdrawForm.withdrawAmount > this.withdrawBalance){
                this.withdrawForm.withdrawAmount = this.withdrawBalance
            }
            if(this.withdrawForm.withdrawAmount < 0){
                this.withdrawForm.withdrawAmount = 0
            }
            this.withdrawForm.withdrawAmount = parseInt(this.withdrawForm.withdrawAmount, 10);
        },
        selectAccount(item){ // 选择交易信息
            this.withdrawForm.account = item.account;
            this.withdrawForm.accountid = item.id;
            this.$refs.accountdrop.hide();
        },
        withdraw() { // 提现
            if(this.canSubmit){
                if(!this.depositLoading){
                    let data = {
                        transactionPassword:this.withdrawForm.transactionPassword,
                        account:this.withdrawForm.account,
                        accountType:this.withdrawForm.accountType,
                        withdrawAmount:this.withdrawForm.withdrawAmount,
                        payCategory:"BANK"
                    }
                    WithdrawAdd(data).then(res => {
                        if(res.code == 200){
                            this.$message(res.msg);
                            this.withdrawForm = { // 提现表单
                                transactionPassword: "", // 交易密码
                                account:"", // 本次提现收款账号
                                withdrawAmount: 0, // 提现金额
                                payCategory:"", // 支付类型
                                accountType:0, // 收款账号id
                            }
                        }
                    })
                }
            }
        },
        showDeposit(){ // 手动开启充值窗口
            this.depositVisible = true;
            this.depositLoading = false;
        },
        deposit() { // 充值
            if(!this.depositLoading){
                this.$refs.depositForm.validate(valid => {
                    if (valid) {
                        this.depositLoading = true;
                        if(this.rechargeActivity){ // 充值活动充值
                            RechargeActivityParticipation({
                                activityId:this.rechargeActivityId,
                                basicValue:this.depositForm.rechargeAmount,
                                channelId:this.depositForm.channelId,
                                prize:this.mathPrize(this.depositForm.rechargeAmount,this.rechargeActivityPriceList)
                            }).then(res => {
                                if(res.code == 200){
                                    if(res.data.type == 'http'){
                                        this.type = res.data.type;
                                        this.depositResult = res.data;
                                    }else{
                                        this.type = 'qrcode';
                                        this.depositResult = res.data;
                                        this.depositVisible = true;
                                        res.data.countdown = new Date().getTime() + res.data.timeExpire * 1000;
                                        this.depositEndTime = res.data.countdown;
                                        this.depositLoading = false;
                                    }
                                }else{
                                    this.$message(res.msg);
                                    this.depositLoading = false;
                                }
                            }).catch(err => {
                                this.depositLoading = false;
                            })
                        }else{ // 普通充值
                            PayOrderCreate(this.depositForm).then(res => {
                                if(res.code == 200){
                                    if(res.data.type == 'http'){
                                        this.type = res.data.type;
                                        this.depositResult = res.data;
                                    }else{
                                        this.type = 'qrcode';
                                        this.depositResult = res.data;
                                        this.depositVisible = true;
                                        res.data.countdown = new Date().getTime() + res.data.timeExpire * 1000;
                                        this.depositEndTime = res.data.countdown;
                                        this.depositLoading = false;
                                    }
                                }else{
                                    this.$message(res.msg);
                                    this.depositLoading = false;
                                }
                            }).catch(err => {
                                this.depositLoading = false;
                            })
                        }
                    } else {
                        this.$message(this.$t("FormComplete"))
                    }
                })
            }
        },
        changePhoneConutry() { // 电话号的区域更换

        },
        typeClick(index){ // 点击了支付类型
            this.payMethodSelected = index;
            this.payChannelSelected = 0;
            this.payMoneySelected = 0;
        },
        channelClick(index) { // 点击了支付通道
            this.payChannelSelected = index;
            this.payMoneySelected = 0;
            this.depositForm.rechargeAmount = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected]?.payConfig.fastAmount.split(",")[0];
            this.depositForm.min = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected]?.payConfig.minAmount;
            this.depositForm.max = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected]?.payConfig.maxAmount;
            this.depositForm.channelId = this.payconfig[this.payMethodSelected]?.payChannels[this.payChannelSelected].channelId;
        },
        showWithdraw() { // 弹出提现弹窗
            this.withdrawVisible = true;
        },
        changeActive(index) { // 更换充值或提现
            this.navList.forEach((item, i) => {
                debugger
                if (i === index) {
                    item.active = true;
                } else {
                    item.active = false;
                }
            });
            if(index == 1){
                this.checkTranscationStatus();
            }
        },
        goBack() { // 返回
            this.$router.go(-1)
        },
        getPayRule(){ // 获取支付规则
            RechargeRule().then(res => { // 充值规则
                if(res.code == 200){
                    this.depositRule = res.data.content;
                }
            })
            WithdrawRule().then(res => { // 体现规则
                if(res.code == 200){
                    this.withdrawRule = res.data.content;
                }
            })
            WithdrawalInfo().then(res => {
                this.withdrawBalance = res.data.balance;
            })
            // UserTaskInfo().then(res => {
            // })
            // GetTranscationInfo().then(res => {
            //     if(res.code == 200){
            //         this.withdrawAccount = res.data.withdrawalAccounts;
            //     }
            // })
        },
        async getRechargeActivity(){
            let res = await GetRechargeActivity();
            this.rechargeActivityId = res.data.activityId;
            this.rechargeActivityTitle = res.data.activityTitle;
            let res2 = await GetRechargeActivityMoneyList({activityId:this.rechargeActivityId});
            if(res2.data){
                this.rechargeActivityPriceList = res2.data;
                this.rechargeActivity = true;
            }
        },
        checkTranscationStatus(){ // 检查交易状态
            GetTranscationInfo().then(res => {
                if(res.code == 200){
                    this.setTranscationPassword = !res.data.hasSetTransactionPassword;
                }
            })
        },
    },
    mounted() {
    },
    activated(){
        if (this.$route.query.mode === 'deposit') {
            this.changeActive(0)
        } else {
            this.changeActive(1);
            this.checkTranscationStatus();
        }
        this.type = "qrcode";
        this.initPayInfo();
        this.getPayRule();
        this.getRechargeActivity();
        this.changePayMoneySelected();
        this.updateWithdrawAccount();
    },
    deactivated(){
        this.setTranscationPassword = false // 设置交易密码的弹窗
        this.confirmTranscationPassword = false
        this.transcationInfoModal = false
    },
    components: {
        Modal,
        Table,
        PayModal,
        Checkbox,
        SetTranscationModal,
        ConfirmTranscationmModal,
        TranscationInfoModal,
        MobileInput,
        Withdrawal
    }
}
</script>

<style scoped>

.priceButtonDesign{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity: 0.2;
}

.formItemInput{
    width:5.5rem;
    height:5.5rem;
    background:var(--subbackground);
    border-radius:1rem;
    display:flex;
    align-items: center;
    justify-content: center;
}

.formItemBottomBox{
    width:100%;
    margin-top:1rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.formPayMethodItem > span {
    color:var(--basic);
    font-size:1.6rem;
    font-weight:bold;
    margin-left:1rem;
    display:inline-block;
    width:100%;
    flex:1;
    overflow:hidden;
    text-overflow: ellipsis;
}
.formWithdrawItem > img {
    width:3rem;
    height:3rem;
}
.formPayMethodItem > svg:nth-child(3) {
    width:1.5rem;
    height:1.5rem;
}

.formPayMethodItem > svg:nth-child(1) {
    width:3rem;
    height:3rem;
}

.formWithdrawText > span:nth-child(1) > span {
    color:var(--subfont);
}

.formWithdrawText > span:nth-child(2){
    font-size:1.5rem;
    color:var(--subfont);
}

.formWithdrawText > span:nth-child(1){
    font-size:1.5rem;
    color:var(--basic);
}

.formWithdrawText{
    display:flex;
    flex-direction: column;
    margin-left:1rem;
}
.formWithdrawItem > svg {
    width:3rem;
    height:3rem;
}

.formWithdrawItem.active{
    border-color:#583ece;
}

.formWithdrawItem{
    display:flex;
    align-items: center;
    border:1px solid var(--subbackgroundspecial);
    width:calc((100% - 1rem) / 2);
    border-radius:0.5rem;
    box-sizing:border-box;
    padding:0 1rem;
    height:5rem;
    font-weight:bold;
    margin-bottom:1rem;
    position:relative;
    overflow:hidden;
}

.formPayMethodItem{
    display:flex;
    align-items: center;
    border:1px solid var(--subbackgroundspecial);
    width:calc((100% - 1rem) / 2);
    border-radius:0.5rem;
    box-sizing:border-box;
    padding:0 1rem;
    height:5rem;
    margin-bottom:1rem;
}

.formPayMethodItem path {
    fill:var(--gdient2)
}

.formPayMethod{
    display:flex;
    align-items: center;
    margin-top:1rem;
    flex-wrap:wrap;
    justify-content: space-between;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.withdrawDes {
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 4rem;
    word-break: break-all;
    box-sizing: border-box;
    padding: 0 1rem;
    line-height: 2rem;
}

.amountValue {
    color: var(--basic);
    font-size: 1.5rem;
}

.amountDes {
    color: var(--subfont);
    font-size: 1.5rem;
}

.rightFourth {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: .5rem 1.5rem;
    border-radius: 1rem;
    align-items: center;
    margin-top: auto;
}

.rightThird {
    color: #fff;
    margin-top: 1rem;
    font-size: 1.3rem;
    width: 70%;
}

.rightSecondProcess {
    width: 100%;
    background: var(--background);
    border-radius: 2rem;
    height: 100%;
}

.rightSecond > span {
    color: var(--mainfont);
    font-size: 1.3rem;
}

.rightSecond {
    height: 1rem;
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.rightFirst > span {
    color: var(--success);
    font-size: 1.5rem;
}

.rightFirst > svg {
    height: 2rem;
    width: 2rem;
}

.rightFirst {
    display: flex;
    justify-content: space-between;
}

.profileProcessRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin-left: 1.5rem;
    height: 18rem;
}

.withdrawModalLeftBoxLabel {
    color: #fff;
    font-size: 1.4rem;
    margin-top: 1rem;
    text-align: center;
}

.withdrawModalLeftBoxValue {
    color: var(--success);
    font-size: 1.7rem;
    font-weight: bold;
    margin-top: 1rem;
}

.withdrawModalLeftbox img {
    height: 5rem;
}

.withdrawModalLeftbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(255, 255, 255, .1);
    align-items: center;
    box-sizing: border-box;
    padding: 1rem 2rem;
    border-radius: 1rem;
    width: 100%;
    margin-top: 1rem;
}

.withdrawModalProcessLeft > span {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
}

.withdrawModalProcessLeft {
    display: flex;
    flex-direction: column;
    width: 15rem;
    align-items: center;
}

.withdrawModalProcess {
    margin-top: 2rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 1.5rem;
    background: radial-gradient(circle at 20% 20%, var(--blue7), var(--sub13black));
    margin-bottom: 2rem;
}

.withdrawModalTitle svg {
    width: 2rem;
    height: 2rem;
}

.withdrawModalTitleText {
    color: #fff;
    font-size: 2.3rem;
    font-weight: bold;
}

.withdrawModalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.withdrawModalTitle path {
    fill:var(--subfont);
}

.withdrawModalBox {
    box-sizing: border-box;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column
}

.withdrawFirst > svg {
    height: 3rem;
    width: 3rem;
    margin-left: 3rem;
}

.withdrawFirst {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.formDes {
    overflow: hidden;
    color: #fff;
    font-size: 1.5rem;
    width: 100%;
    font-weight: bold;
}

.selectBox svg {
    height: 3rem;
    width: 2.5rem;
}

.selectBox span {
    color: var(--subfont);
    margin-left: .5rem;
    font-size: 1.5rem;
}

.selectBox {
    display: flex;
    align-items: center;
}

.selectBox path {
    fill:var(--subfont);
}

.formFieldTitle > svg {
    width:2rem;
    height:2rem;
}
.formFieldTitle path {
    fill:var(--gdient2);
}

.formFieldTitle > span {
    color: var(--subfont);
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
}

.formField > span {
    color: var(--subfont);
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
}

.formField {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.inputDescript {
    color: #fff !important;
    font-size: 1.3rem !important;
}

.selectBox2 svg {
    height: 3rem;
    width: 2.5rem;
}

.selectBox2 span {
    color: var(--basic);
    margin-left: .5rem;
    font-size: 1.5rem;
}

.selectBox2 {
    display: flex;
    align-items: center;
}

.InputBox2 input::placeholder {
    color: var(--subfont);
    font-weight: bold;
}

.InputBox2 input {
    color: var(--basic);
    font-size: 1.5rem;
    background: none;
    outline: none;
    border: none;
    margin-left: 1rem;
}

.InputBox2 > svg {
    height: 2rem;
    width: 2rem;
}

.InputBox2 {
    border: 1px solid var(--subfont);
    height: 5.5rem;
    width: 100%;
    border-radius: 1rem;
    display: flex;
    box-sizing: border-box;
    padding: 0 2rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    margin-top: .5rem;
}

.withdrawBalanceLabel {
    color: var(--subfont);
    font-size: 1.5rem;
}

.withdrawBalanceVal {
    color: #fff;
    font-size: 1.5rem;
    margin-right: 6rem;
}

.withdrawBalance {
    background: var(--background);
    box-sizing: border-box;
    height: 4rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    align-items: center;
    border-radius: 1rem;
}

.withdrawBox {
    box-sizing: border-box;
    padding: 1rem 0;
}

.LoginButton {
    border-radius: 1rem;
    background: var(--gdient2);
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.7rem;
    margin-top: 1.5rem;
    cursor:pointer;
}

.inputDesBox{
    margin-top: 1.5rem;
    display:flex;
    align-items: center;
}

.inputDes {
    margin-left:0.5rem;
    color: #fff;
    background: linear-gradient(to right, var(--success), var(--primary));
    opacity:0.8;
    height: 3rem;
    width: 100%;
    box-sizing: border-box;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 2rem;
}

.selectBox {
    display: flex;
    align-items: center;
    color: var(--basic);
    font-size: 1.5rem;
}

.InputBox input::placeholder {
    color: var(--subfont);
    font-weight: bold;
}

.InputBox input {
    color: var(--basic);
    font-size: 1.5rem;
    background: none;
    outline: none;
    border: none;
    margin-left: 1rem;
    font-weight:bold;
}

.InputBox > svg {
    height: 2rem;
    width: 2rem;
}

.InputBox path {
    fill:var(--subfont);
}

.InputBox {
    height: 5.5rem;
    background: var(--subbackground);
    width: 100%;
    border-radius: 1rem;
    display: flex;
    box-sizing: border-box;
    padding: 0 2rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    font-weight:bold;
}

.plusDesign > svg {
    position: absolute;
    top: 0.1rem;
    left: 1.8rem;
    width: 1rem;
    height: 1rem;
    transform: rotate(48deg);
}

.plusDesign {
    position: absolute;
    width: 6rem;
    height: 2rem;
    background: var(--blue);
    bottom: 0;
    right: -3rem;
    transform: rotate(-45deg);
}

.priceItem.active .plusPrice {
    background-color: var(--pricebutton);
    color: #fff;
}

.plusPrice {
    position: absolute;
    background-color: var(--subfont);
    color:#fff;
    top: 0;
    right: 0;
    font-size: 1.3rem;
    /* width:5rem; */
    padding: 0 1rem;
    text-align: center;
    border-bottom-left-radius: 1rem;
}

.priceItem:nth-child(3n) {
    margin-right: 0 !important;
}

.priceItem {
    width: 31.6%;
    height: 6rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: var(--subbackground);
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--basic);
    font-size: 2rem;
    font-weight: bold;
    position: relative;
    overflow: hidden;
}

.priceItem.active{
    color:#fff;
}

.textItem.active {
    background: var(--gdient2);
    color: #fff;
    border-radius: 4rem;
}

.textRate {
    position: absolute;
    right: 1rem;
    top: .5rem;
}

.textItem > img {
    height:2rem;
    margin-right:1rem;
}

.textItem {
    width: 33.33%;
    color: var(--subfont);
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    white-space: nowrap;
    font-weight: bold;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
    cursor:pointer;
}

.priceOptions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
}

.textOptions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
}

.text-title {
    color: var(--subfont);
    font-size: 1.7rem;
    font-weight: bold;
}

.text {
    box-sizing: border-box;
    padding: 3rem 3rem;
}

.box {
    width: 100%;
    height: fit-content;
    background: var(--primary);
    box-sizing: border-box;
    padding-bottom: 6rem;
}

.navItem.active {
    border-top: 1px solid var(--subbackgroundspecial);
    border-left: 1px solid var(--subbackgroundspecial);
    border-right: 1px solid var(--subbackgroundspecial);
    border-bottom: 1px solid var(--primary);
    position: relative;
    z-index: 5;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    color:var(--basic);
    height:calc(100%);
    background:var(--primary);
}

.navItem {
    /* box-sizing: border-box; */
    height: 5rem;
    width: 12rem;
    justify-content: center;
    color: var(--subfont);
    font-size: 1.7rem;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.header svg {
    height: 2rem;
    width: 2rem;
}

.header path {
    fill:var(--subfont);
}

.header {
    height: 7rem;
    width: 100%;
    border-bottom: 1px solid var(--subbackgroundspecial);
    box-sizing: border-box;
    padding: 0 3rem;
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navList {
    display: flex;
    height: 100%;
}
</style>
