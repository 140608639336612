<template>

    <div class="setTranscationModal">
        <div class="setTranscationTips">
            {{ $t("SetTranscationTip1") }}
        </div>

        <div class="formBox">
            <div class="formItemBox">
                <div class="formItemTop">
                    <span>{{ $t("NewWithdrawalPassword") }}</span>
                    <template v-if="passwordType === 'password'">
                        <svg @click="passwordType = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z" fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="passwordType === 'text'">
                        <svg @click="passwordType = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z" fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <MobileInput :password.sync="password" :passwordType.sync="passwordType"></MobileInput>
            </div>
            
            <div class="formItemBox">
                <div class="formItemTop">
                    <span>{{ $t("EnterPassword") }}</span>
                    <template v-if="cpasswordType === 'password'">
                        <svg @click="cpasswordType = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z" fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="cpasswordType === 'text'">
                        <svg @click="cpasswordType = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z" fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <MobileInput :password.sync="cpassword" :passwordType.sync="cpasswordType"></MobileInput>
            </div>
        </div>
        
        <div class="setTranscationTips" style="margin-top:3rem">
            {{ $t("SetTranscationTip2") }}
        </div>
        <div class="formButton" :style="canPush ? '' : 'filter: brightness(80%);'" @click="confirm">
            {{ $t("ConfirmChange") }}
            <div style="margin-left:0.3rem" v-loading="isPush"></div>
        </div>
        <input v-model="password" style="height:0;background:none;width:0;border:0;padding:0;margin:0;outline:0;" type="number" onInput="value=value.replace(/[^\d]/g,'').slice(0,6)" :maxlength="6" ref="passwordInput"/>
        <input v-model="cpassword" style="height:0;background:none;width:0;border:0;padding:0;margin:0;outline:0;" type="number" onInput="value=value.replace(/[^\d]/g,'').slice(0,6)" :maxlength="6" ref="cpasswordInput"/>
    </div>

</template>

<script>

    import { SetTranscationPassword } from "@/api/deposit";
    import MobileInput from "@/components/MobileInput.vue";

    export default {
        name:"SetTranscationModal",
        data(){
            return {
                password:"",
                passwordType:"password",
                cpassword:"",
                cpasswordType:"password",
                isPush:false,
            }
        },
        props:{
            visible:{
                type:Boolean
            }
        },
        computed:{
            canPush(){
                return this.password.length >= 6 && this.cpassword.length >= 6;
            }
        },
        watch:{
            visible(newVal){
                if(!newVal){
                    this.password = "";
                    this.cpassword = "";
                    this.passwordType = "password";
                    this.cpasswordType = "password";
                    this.isPush = false;
                }
            }
        },
        methods:{
            confirm(){ // 第一次设置密码
                if(this.canPush){
                    if(!this.isPush){
                        this.isPush = true;
                        if (this.password !== this.cpassword) {
                            this.$message(this.$t("PasswordDontMatch"));
                            this.isPush = false;
                            return;
                        }
                        let data = {
                            newPassword:this.password
                        }
                        SetTranscationPassword(data).then(res => {
                            if(res.code == 200){
                                this.$message(res.msg);
                                this.isPush = false;
                                this.$emit("update:visible",false)
                            }else{
                                this.$message(res.msg);
                                this.isPush = false;
                            }
                        })
                    }
                }
            },
            passwordFocus(){
                this.$nextTick(() => {
                    this.$refs.passwordInput.focus();
                });
            },
            cpasswordFocus(){
                this.$nextTick(() => {
                    this.$refs.cpasswordInput.focus();
                });
            }
        },
        components:{
            MobileInput
        }
    }
</script>

<style scoped>

    .formButton {
        border-radius: 1rem;
        background: var(--gdient2);
        height: 5.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        font-size:1.7rem;
        margin-top:2rem;
        cursor:pointer;
    }

    .formItemInput{
        width:5.1rem;
        height:5.1rem;
        background:var(--subbackground);
        border-radius:1rem;
        display:flex;
        align-items: center;
        justify-content: center;
        color:var(--basic);
        font-size:1.6rem;
        font-weight:bold;
    }

    .formItemBottomBox{
        width:100%;
        margin-top:1rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .setTranscationModal{
        width:100%;
    }
    .setTranscationTips{
        color: var(--basic);
        font-size:1.6rem;
        font-weight:bold;
        word-break: break-all;
        margin-top:1rem;
    }
    .formBox{
        width:100%;
        margin-top:3rem;
    }
    .formItemBox{
        width:100%;
        margin-top:1rem;
    }
    .formItemTop{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .formItemTop > span {
        font-size:1.5rem;
        color:var(--gdient2);
        font-weight:bold;
    }
    .formItemTop > svg {
        width:2rem;
        height:2rem;
    }
    .formItemTop path {
        fill:#fff;
    }
</style>