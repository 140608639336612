<template>
    <div class="adBox" :style="'right: ' + right + 'rem;bottom:' + bottom + 'rem;'" v-if="visible">
        <div @click="close" style="position:absolute;top:0rem;right:0rem;width:2rem;height:2rem;background:rgba(255,255,255,.4);border-radius:50%;display:flex;align-items: center;justify-content: center;">
            <svg style="width:1rem;height:1rem;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#ffffff"></path>
            </svg>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name:"AdBox",
        props:{
            bottom:{
                type:Number,
            },
            right:{
                type:Number
            },
            visible:{
                type:Boolean
            }
        },
        data(){
            return {
            }
        },
        methods:{
            close(){
                this.$emit("update:visible",false)
            }
        }
    }
</script>

<style scoped>
    .adBox{
        z-index: 21;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }
</style>