var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"8rem 0"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"helpImg"},[_c('img',{attrs:{"src":_vm.Help}})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"optionButton",on:{"click":_vm.goBack}},[_vm._v(" REFERRAL NOW ")]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpP"},[_c('p',{staticClass:"helpTitle"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPera")]),_c('p',{staticClass:"helpDes"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpP"},[_c('p',{staticClass:"helpTitle"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPera")]),_c('p',{staticClass:"helpDes"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpP"},[_c('p',{staticClass:"helpTitle"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPera")]),_c('p',{staticClass:"helpDes"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpP"},[_c('p',{staticClass:"helpTitle"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPera")]),_c('p',{staticClass:"helpDes"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpP"},[_c('p',{staticClass:"helpTitle"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPera")]),_c('p',{staticClass:"helpDes"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpP"},[_c('p',{staticClass:"helpTitle"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPera")]),_c('p',{staticClass:"helpDes"},[_vm._v("EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera")])])
}]

export { render, staticRenderFns }