

export const mathPrize = (price, array) => {
    for (let item of array) {
      if (price >= item.basicValueMin && price <= item.basicValueMax) {
        if (item.prizeType === 0) {
          return item.prizeValue; // 固定金额
        } else if (item.prizeType === 1) {
          return price * (item.prizeValue / 100); // 百分比金额
        }
      }
    }
    return 0;
  };
  