<template>
    <div class="container" @click="changeRadioValue">
        <div class="radioControl">
            <div class="yes" v-if="control == name">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                  <path d="M442.88 1010.176c-147.968-183.296-322.048-322.048-442.88-365.568l286.72-172.032 138.24 268.8S650.24 195.584 1006.08 13.824C998.4 143.872 963.072 256.512 1024 395.264c-156.16 35.328-476.672 424.96-581.12 614.912z" fill="#ffffff"></path>
                </svg>
            </div>
        </div>
        <span v-text="label"></span>
    </div>
</template>

<script>
    export default {
        name:"Radio",
        props:{
            name:{
                type:String,
            },
            label:{
                type:String,
            },
            control:{
                type:String
            }
        },
        data(){
            return {
                
            }
        },
        methods:{
            changeRadioValue(){
                this.$emit("update:control",this.name)
            }
        },
        components:{

        }
    }
</script>

<style scoped>
    .yes > svg {
        width:1.1rem;
        height:1.1rem;
    }
    .yes{
        width:2rem;
        height:2rem;
        background:var(--success);
        display:flex;
        color:#fff;
        justify-content: center;
        align-items: center;
    }
    .radioControl{
        height:2rem;
        width:2rem;
        background:var(--subbackgroundspecial);
        border-radius:1rem;
        overflow:hidden;
    }
    .container>span{
        color:#fff;
        font-size:1.5rem;
        margin-left:.5rem;
        font-weight:bold;
    }
    .container{
        display:flex;
        align-items: center;
    }

</style>