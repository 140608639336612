import { render, staticRenderFns } from "./Empty.vue?vue&type=template&id=2e70a99b"
import script from "./Empty.vue?vue&type=script&lang=js"
export * from "./Empty.vue?vue&type=script&lang=js"
import style0 from "./Empty.vue?vue&type=style&index=0&id=2e70a99b&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports