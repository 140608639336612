import Vue from "vue";
import VueI18n from "vue-i18n"
import enLocale from '@/i18n/en.json'
import zhLocale from '@/i18n/zh.json'
import pbLocale from '@/i18n/pb.json'

Vue.use(VueI18n)

const messages = {
  en:enLocale,
  pb:pbLocale,
  zh:zhLocale
}

const i18n = new VueI18n({
  locale:localStorage.getItem("language") || 'pb',
  messages
})

export default i18n