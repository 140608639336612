<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="vipBox" v-if="vipList.length != 0">
                <div class="vipItem">
                    <div class="vipLevel" :style="'background-image:url(' + vipList[current].levelBg + ')'">
                        <!-- <img v-preload="vipList[current].levelBg" /> -->
                        <div class="vipTitle">
                            <span class="vipLevelText" v-text="vipList[current].levelName"></span>
                            <template v-if="user">
                                <template v-if="vipList[current].level == user.level">
                                    <span class="isCurrent">{{ $t("CurrentLevel") }}</span>
                                </template>
                            </template>
                            <img :src="vipList[current].levelIcon" />
                        </div>
                        <div class="vipProcess" style="position:relative;z-index:1;">
                            <span :style="'color::#6c3c02'">{{ $t("Recharge") }}<span :style="'color::#6c3c02;margin-left:.5rem'">0/{{ vipList[current].payment }}</span></span>
                            <div class="vipProcessText">

                            </div>
                        </div>
                        <div class="vipProcess" style="margin-top:2rem;position:relative;z-index:1;">
                            <span>{{ $t("BetAmount") }}<span :style="'margin-left:.5rem'">0/{{ vipList[current].gameStatement }}</span></span>
                            <div class="vipSubProcessText">

                            </div>
                            <div class="vipProcessDes">
                                <span>V{{ vipList[current].level }}</span>
                                <span>V{{ vipList[current+1] ? vipList[current+1].level : vipList[current].level }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="vipList">
                        <div @click="changeCurrent(index)" :class="current == index ? 'buttonTransition vipListItem active' : 'buttonTransition vipListItem'" v-for="(item,index) in vipList" :key="index">
                            <img :style="item.level <= user.level ? 'filter:none !important;' : ''" :src="item.levelIcon" />
                            <span v-text="item.levelName"></span>
                        </div>
                    </div>
                    <div class="vipPowerArray" v-html="vipList[current].levelDescription">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex';

    export default {
        name:"VIP",
        data(){
            return {
                current:0,
                isDragging: false
            }
        },
        computed:{
            ...mapState({
                user: state => state.user.user || { level:-1 },
                vipList: state => state.user.vipList,
            }),
        },
        methods:{
            initSelect(){
                this.current = this.vipList.findIndex(item => item.level == this.user.level);
            },
            changeCurrent(index){
                this.current = index;
            }
        },
        mounted(){
            this.initSelect();
        }
    }

</script>

<style scoped>

    .vipListItem > span {
        color:var(--basic);
        font-size:1.5rem;
        font-weight:bold;
    }

    .vipListItem > img {
        width:90%;
        filter: grayscale(100%);
    }

    .vipListItem.active{
        background:var(--gdient2) !important;
    }

    .vipListItem{
        background-color:var(--primary);
        width:calc((100% - 4rem) / 5);
        height:10rem;
        border-radius:1rem;
        margin-top:1.5rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .vipList{
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
    }

    .vipPowerArrayDes>div>span:nth-child(2){
        margin-top:.3rem;
    }
    .vipPowerArrayDes>div{
        margin-bottom:3rem;
        color:#fff;
        font-size:1.4rem;
        display:flex;
        flex-direction: column;
        font-weight:bold;
    }
    .vipPowerArrayReal>span>span{
        color:#fff;
    }
    .vipPowerArrayReal>span{
        color:var(--mainfont);
        font-size:1.4rem;
        font-weight:bold;
        margin-bottom:.5rem;
    }
    .vipPowerArrayReal{
        display:flex;
        flex-direction: column;
        margin-top:2rem;
        align-items: center;
    }
    .vipPowerArrayTitle{
        color:var(--blue3);
        font-weight:bold;
        font-size:1.8rem;
        margin-top:2rem;
    }
    .vipPowerArray>img{
        width:10rem;
    }
    .vipPowerArray{
        background:var(--primary);
        box-sizing:border-box;
        padding-top:2rem;
        padding-bottom:3rem;
        border-radius:1rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-top:1.5rem;
        font-size:1.5rem;
    }
    .viewPowerDays{
        height:3rem;
        width:3rem;
        background-color:rgba(255,255,255,.3);
        border-radius:.5rem;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        color:#fff;
        font-weight:bold;
        margin-top:1rem;
    }
    .vipPowerLast{
        margin-top:auto;
        font-size:1.4rem;
        font-weight:bold;
        margin-bottom:.5rem;
    }
    .vipPowerItem>svg{
        margin-top:auto;
        height:3rem;
        width:3rem;
    }
    .vipPowerItem>span:nth-child(3){
        font-size:1.8rem;
        font-weight:bold;
        text-align: center;
        margin-top:.7rem;
    }
    .vipPowerItem>img{
        opacity: .3;
        height:3rem;
        margin-top:1rem;
    }
    .vipPowerItem>span:nth-child(1){
        font-size:1.5rem;
        font-weight:bold;
        text-align: center;
    }
    .vipPowerItem{
        width:30%;
        height:17rem;
        background-repeat:no-repeat;
        background-size:100% 100%;
        box-sizing:border-box;
        padding:1rem 1rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        padding-bottom:.5rem;
    }
    .vipPowerList{
        width:100%;
        display:flex;
        margin-top:2rem;
        justify-content: space-between;
    }
    .vipPowerTitle{
        margin-top:2rem;
        font-size:1.7rem;
        font-weight:bold;
        text-align: center;
    }
    .vipDesign{
        width:100%;
        margin-top:2rem;
    }
    .vipProcessDes{
        width:100%;
        justify-content: space-between;
        display: flex;
        margin-top:.3rem;
        font-size:1.2rem;
        font-weight:bold;
    }
    .vipProcessText{
        background:rgba(0,0,0,0.3);
        height:1.5rem;
        width:55%;
        border-radius:1rem;
        margin-top:.5rem;
    }
    .vipSubProcessText{
        background:rgba(0,0,0,0.3);
        height:1rem;
        width:100%;
        border-radius:1rem;
        margin-top:.5rem;
    }

    .vipProcess>span{
        font-size:1.4rem;
        font-weight:bold;
    }
    .vipProcess{
        display:flex;
        flex-direction: column;
    }
    .isCurrent{
        font-size:1.4rem;
        font-weight:bold;
    }
    .vipLevelText{
        font-size:3.5rem;
        font-weight:bold;
    }
    .vipTitle>img{
        height:10rem;
    }
    .vipTitle{
        display:flex;
        align-items: center;
        justify-content: space-between;
        position:relative;
        z-index:1;
    }
    .vipLevel > img {
        height:100%;
        width:100%;
        position:absolute;
        z-index:0;
        left:0;
        top:0;
    }
    .vipLevel{
        height:25rem;
        width:100%;
        display:flex;
        background-size:100% 100%;
        background-repeat:no-repeat;
        flex-direction: column;
        box-sizing:border-box;
        padding:2rem 2.5rem;
        position:relative;
        color: white; /* 默认字体颜色 */
    }
    .vipItem{
        height:100%;
        width:100%;
        /* position:absolute; */
        transition:all .5s;
    }
    .vipBox{
        /* height:130rem; */
        height:fit-content;
        position:relative;
        display:flex;
        flex-direction: column;
        box-sizing:border-box;
        padding:3rem;
        background:var(--background);
        overflow:hidden;
    }
    .box {
        background: var(--background);
        box-sizing: border-box;
        box-sizing: border-box;
        padding: 1rem 0;
        padding-bottom: 14rem;
    }
</style>