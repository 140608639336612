<template>
    <div class="box">
        <div class="process">
            <div class="processValue">
            </div>
        </div>
        <img :src="P777" />
        <span></span>
        <!-- V1.1.0118-94 -->
    </div>
</template>

<script>

    import P777 from "@/assets/img/777.png";

    export default {
        name:"Load",
        data(){
            return {
                P777
            }
        },
        methods:{
            init(){
                setTimeout(() => {
                    // 执行跳转
                    this.$router.push("/home")
                },1000)
            }
        },
        mounted(){
            this.init();
        }
    }
</script>

<style scoped>
    @keyframes progress {
        0% {
            width: 0%;
        }
        100% {
            width: 100%;
        }
    }
    .processValue{
        height:100%;
        width:30%;
        background:var(--error);
        border-radius:1rem;
        animation: progress 2s ease-in-out forwards;
    }
    .process{
        height:1rem;
        width:100%;
    }
    .box>img{
        width:20rem;
    }
    .box>span{
        color:#fff;
        font-size:1.4rem;
    }
    .box{
        box-sizing:border-box;
        width:100%;
        height:100%;
        background:var(--background);
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-between;
    }
</style>
