<template>
    <div class="otherSide">
        <div class="paginationContainer">
            <template v-for="(item,itemIndex) in data">
                <GameItem 
                    v-if="item.mytype == 'game'"
                    :key="itemIndex"
                    :item="item"
                    :click="toGame">
                </GameItem>
                <FolderItem
                    v-else
                    :key="item.categoryId"
                    :item="item"
                    :click="toCateGory"
                >
                </FolderItem>
            </template>
            
        </div>
        <div class="loadMore" @click="refreshData" v-if="data.length < total">
            {{ $t("LoadMore") }}
        </div>
    </div>
</template>

<script>

    import GameItem from "@/components/GameItem.vue";
    import FolderItem from "@/components/FolderItem.vue";

    export default {
        name:"Pagination",
        components:{
            GameItem,
            FolderItem
        },
        props:{
            folderSource:{
                type:Array,
                default:[],
            },
            gameSource:{
                type:Array,
                default:[],
            },
            type:{
                type:String,
                default:"home",
            },
            categoryId:{
                type:String,
                default:"0"
            },
            categoryName:{
                type:String,
                default:"0"
            },
            size:{
                type:Number,
                default:9
            }
        },
        data(){
            return {
                source:[],
                page:1, // 当前在哪页
                data:[], // 实际显示的list
            }   
        },
        computed:{
            total(){
                return this.source.length;
            },
        },
        methods:{
            toCateGory(item){
                this.$router.push({
                    path:"/classification",
                    query:{id:item.categoryId,name:item.categoryName}
                })
                this.$store.dispatch("setCurrentCategoryBg",item.fullCategoryBgImg);
            },
            refreshData(){
                this.data = [...this.data,...this.source.slice((this.page-1)*this.size,this.size*this.page)];
                this.page += 1;
            },
            toGame(item) {
                const game = item;

                //这里需要判断是否已经登录，如果没有登录跳转到登录页面
                if (!this.$store.state.user.token) {
                    this.$router.push({path: "/login", query: {mode: "login"}});
                    return;
                }

                //这里需要判断余额是否足够
                if (this.$store.state.user?.user?.balance < game.minBalanceEnter) {
                    this.confirmVisible = true;
                    return;
                }

                this.setHistory(game.gameId);

                const gameParams = {channel: game.channelBean, gameId: game.gameId, gameCode: game.gameCode, lang: ""};
                if(this.type == 'home'){
                    this.$router.push({
                        path: '/other',
                        query: {data: JSON.stringify(gameParams), type: "game",intype:'home'}
                    });
                }else{
                    this.$router.push({
                        path: '/other',
                        query: {data: JSON.stringify(gameParams), type: "game",intype:'folder',folderid:this.categoryId,foldername:this.categoryName}
                    });
                }
            },
            setHistory(gameId) {
                try {
                    // 从 localStorage 中获取历史记录
                    let history = JSON.parse(localStorage.getItem('history')) || [];
                    // 过滤掉所有具有相同 id 的记录
                    history = history.filter(item => item.id !== gameId);
                    // 添加新记录到数组
                    history.push({ time: new Date().getTime(), id: gameId });
                    // 按时间降序排序，确保最新的记录在前面
                    history.sort((a, b) => b.time - a.time);
                    // 只保留最新的五个记录
                    history = history.slice(0, 5);
                    // 将更新后的数组存回 localStorage
                    this.$store.dispatch('setHistory',history)
                } catch (e) {
                    console.error('Error parsing history from localStorage:', e);
                }
            },
            initSource(){
                this.source = [...this.folderSource.map(item => {
                    item.mytype = 'folder';
                    return item;
                }),...this.gameSource.map(item => {
                    item.mytype = 'game';
                    return item;
                })]
                this.page = 1;
                this.data = [];
                this.refreshData();
            }
        },
        created(){
            this.initSource();
        },
        mounted(){
        },
        watch:{
            source(){
                this.page = 1;
                this.data = [];
                this.refreshData();
            },
            folderSource(oldVal,newVal){
                this.initSource();
            },
            gameSource(oldVal,newVal){
                this.initSource();
            }
        },
    }
</script>

<style scoped>

    .otherSide{
        display:flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        height:fit-content;
    }

    .loadMore{
        box-sizing:border-box;
        padding:1rem 1.5rem;
        border-radius:1rem;
        background:var(--primary);
        color:var(--subfont);
        width:fit-content;
        margin:0 auto;
        margin-top:1.5rem;
        font-size:1.5rem;
        font-weight:bold;
        cursor:pointer;
    }

    .paginationContainer{
        height:fit-content;
        width:100%;
        display:flex;
        flex-wrap:wrap;
    }
</style>