<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="DesTitle">
                <span>{{$t("Welcome")}}!</span>
                <img :src="Clap" />
            </div>
            <div class="DesText" v-text="$t('ServiceTip')">
            </div>
            <div class="ServiceList">
                <div class="serviceItem" v-for="item in user.customer" :key="item.account">
                    <div class="serviceName" v-text="item.nickname"></div>
                    <div class="serviceInfo">
                        <div class="serviceAvatar">
                            <img :src="item.im" />
                        </div>
                        <div class="serviceRight">
                            <span v-text="item.account"></span>
                            <div class="serviceTg">
                                <div class="tgSvg">
                                    <img :src="item.im" />
                                </div>
                                <span v-text="item.pc"></span>
                            </div>
                        </div>
                    </div>
                    <div class="serviceButton" @click="openContact(item.pc)">
                        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M512 67.168c-247.04 0-448 172.256-448 384 0 116.512 63.04 226.048 174.048 301.408l84.16 52.032c-3.68 23.552-18.752 74.496-33.44 115.936-4.256 12.064-0.928 25.504 8.448 34.176 6.016 5.568 13.824 8.512 21.728 8.512 4.416 0 8.832-0.896 13.024-2.752 36.416-16.224 147.456-68.96 187.584-125.376C763.136 831.616 960 660.704 960 451.168 960 239.424 759.04 67.168 512 67.168zM580.224 543.968 384.96 543.968c-17.664 0-32-14.336-32-32s14.336-32 32-32l195.264 0c17.696 0 32 14.336 32 32S597.92 543.968 580.224 543.968zM640.928 415.968 384.96 415.968c-17.664 0-32-14.336-32-32s14.336-32 32-32l255.968 0c17.696 0 32 14.336 32 32S658.624 415.968 640.928 415.968z" fill="#ffffff"></path></svg>
                        <span v-text="$t('SendMessage')"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Logo from "../assets/img/logorow.png";
    import Clap from "../assets/img/clap.png";
    import { mapState } from 'vuex';

    export default {
        name:"Service",
        data(){
            return {
                Logo,
                Clap,
            }
        },
        computed:{
            ...mapState({
                user: state => state.user.user,
            })
        },
        methods:{
            toBack(){
                this.$router.go(-1)
            },
            openContact(url){
                window.open(url, '_blank');
            }
        }
    }
</script>

<style scoped>
    .serviceButton svg{
        width:3rem;
        height:3rem;
    }
    .serviceButton span{
        color:#fff;
        font-size:1.5rem;
        font-weight:bold;
        margin-left:1rem;
    }
    .serviceButton{
        background:var(--gdient2);
        width:70%;
        margin-left:2rem;
        height:5rem;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:3rem;
        margin-bottom:2rem;
        cursor:pointer;
    }
    .tgSvg{
        background:#fff;
        width:2.5rem;
        height:2.5rem;
        border-radius:50%;
        overflow:hidden;
    }
    .tgSvg > img {
        width:100%;
        height:100%;
    }
    .serviceTg span{
        color:var(--mainfont);
        font-size:1.8rem;
        margin-left:.5rem;
    }
    .serviceTg svg{
        width:2.5rem;
        height:2.5rem;
    }
    .serviceTg{
        display:flex;
        margin-top:1rem;
    }
    .serviceRight>span:nth-child(1){
        color:var(--mainfont);
        font-size:1.8rem;
    }
    .serviceRight{
        display:flex;
        flex-direction: column;
        margin-left:2rem;
        flex:1;
    }
    .serviceAvatar img{
        width:100%;
        height:100%;
    }
    .serviceAvatar{
        height:6rem;
        width:6rem;
        background:#fff;
        border-radius:50%;
        overflow:hidden;
    }
    .serviceInfo{
        display:flex;
        height:10rem;
        align-items: center;
        box-sizing:border-box;
    }
    .serviceName{
        color:#fff;
        font-size:2rem;
        font-weight:bold;
    }
    .serviceItem{
        display:flex;
        flex-direction: column;
        box-sizing:border-box;
        padding:2rem;
        border-radius:1rem;
        background:var(--subbackground);
        margin-bottom:1rem;
    }
    .ServiceList{
        height:100%;
        flex:1;
    }
    .DesText{
        overflow-wrap: break-word; /* 文字超出容器宽度时换行 */
        word-wrap: break-word; /* 兼容旧版浏览器 */
        color:#fff;
        font-size:2rem;
        font-weight:bold;
        margin-top:2.5rem;
        margin-bottom:3rem;
    }
    .DesTitle span{
        color:#fff;
        font-size:3rem;
        font-weight:bold;
    }
    .DesTitle img{
        width:4rem;
        margin-left:1rem;
    }
    .DesTitle{
        display:flex;
    }
    .titleBox svg{
        height:3rem;
        width:3rem;
    }
    .titleBox img{
        height:3rem;
    }
    .titleBox{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .box{
        display:flex;
        flex-direction: column;
        height:100%;
        width:100%;
        height:fit-content;
        background:var(--primary);
        box-sizing: border-box;
        padding:2rem 4rem;
        padding-bottom:14rem;
    }
</style>