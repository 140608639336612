import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from "./store"
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import "./basic/rem.js"
import i18n from './i18n';
import VueLazyload from 'vue-lazyload';

import LoadingCom from "@/components/Loading.vue";
import './registerServiceWorker.js'
const EventBus = new Vue();
export default EventBus;

Vue.config.productionTip = false

// 注册全局命令
Vue.prototype.$showLoading = () => {
  EventBus.$emit('show-loading');
};

Vue.prototype.$hideLoading = () => {
  EventBus.$emit('hide-loading');
};

Vue.directive('preload', {
  bind(el, binding) {
    loadImage(binding.value, el);
  },
  update(el, binding) {
    if (binding.value !== binding.oldValue) {
      // 预加载新图片
      const newSrc = binding.value;
      preloadImage(newSrc, () => {
        el.src = newSrc;
      });
    }
  }
});

function loadImage(src, el) {
  preloadImage(src, () => {
    el.src = src;
  });
}

function preloadImage(src, callback) {
  let img = new Image();
  img.src = src;
  img.onload = callback;
}

Vue.directive("click-outside",{
  bind(el,binding){
    el.clickOutsideEvent = function (event) {
      if(!(el === event.target || el.contains(event.target))){
        binding.value(event)
      }
    }
    document.addEventListener("click",el.clickOutsideEvent)
  },
  unbind(el){
    document.removeEventListener("click",el.clickOutsideEvent)
  }
})

Vue.use(ElementUI, { locale })
Vue.use(VueLazyload);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.component('loading-component', LoadingCom);