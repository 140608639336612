<template>
    <div class="TranscationModal">

        <div class="title">
            <div style="width:2rem"></div>
            <span v-text="'Inserir senha'"></span>
            <svg @click.stop="closeModal" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path></svg>
        </div>

        <div class="formBox">
            <div class="settingInput">
                <svg class="brownSvg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 2.8335C1.82081 2.8335 1 3.65431 1 4.66683V19.3335C1 20.346 1.82081 21.1668 2.83333 21.1668H21.1667C22.1792 21.1668 23 20.346 23 19.3335V4.66683C23 3.65431 22.1792 2.8335 21.1667 2.8335H2.83333ZM4.77887 9.71687C4.77887 11.072 5.61904 12.2309 6.8069 12.7011C4.88194 13.1629 3.3865 14.7289 3.02782 16.6918C2.93683 17.1898 3.35594 17.6039 3.86221 17.6039H12.1122C12.6185 17.6039 13.0376 17.1898 12.9466 16.6918C12.5879 14.7289 11.0925 13.1629 9.16751 12.7011C10.3554 12.2309 11.1955 11.072 11.1955 9.71687C11.1955 7.94496 9.75912 6.50854 7.9872 6.50854C6.21529 6.50854 4.77887 7.94496 4.77887 9.71687ZM19.9207 9.64957C20.427 9.64957 20.8374 10.06 20.8374 10.5662C20.8374 11.0725 20.427 11.4829 19.9207 11.4829H14.4207C13.9145 11.4829 13.5041 11.0725 13.5041 10.5662C13.5041 10.06 13.9145 9.64957 14.4207 9.64957H19.9207ZM20.8374 15.1496C20.8374 14.6433 20.427 14.2329 19.9207 14.2329H14.4207C13.9145 14.2329 13.5041 14.6433 13.5041 15.1496C13.5041 15.6558 13.9145 16.0662 14.4207 16.0662H19.9207C20.427 16.0662 20.8374 15.6558 20.8374 15.1496Z" fill="#039685"></path>
                </svg>
                <input type="text" v-model="cpf" :placeholder="placeholder" />
            </div>
        </div>
        
        <div class="formButton" :style="canPush ? '' : 'filter: brightness(80%);'" @click="confirm">
            {{ $t("Confirm") }}
            <div style="margin-left:0.3rem" v-loading="isPush"></div>
        </div>
    </div>
</template>

<script>

    import { SetCPF } from "@/api/setting.js";

    export default {
        name:"SetCPF",
        data(){
            return {
                cpf:"",
                isPush:false,
                placeholder:this.$t("CPF")
            }
        },
        props:{
            visible:{
                type:Boolean
            }
        },
        computed:{
            canPush(){
                return this.cpf.length >= 6;
            }
        },
        methods:{
            confirm(){ // 确认提交
                if(this.canPush){
                    if(!this.isPush){
                        let data = {
                            idcard:this.cpf
                        }
                        this.isPush = true;
                        SetCPF(data).then(res => {
                            if(res.code == 200){ // 设置成功
                                this.$message(res.msg);
                                // 设置成功调用vuex设置user的idcard字段
                                this.$store.dispatch("setUserIDCard",this.cpf);
                            }else{
                                this.$message(res.msg);
                            }
                            this.$emit("update:visible",false);
                            this.isPush = false;
                        }).catch(err => {
                            this.isPush = false;
                        })
                    }
                }
            },
            closeModal(){
                this.password = "";
                this.$emit("update:visible",false);
            },
        },
    }
</script>

<style scoped>

    .brownSvg path {
        fill:var(--subfont);
    }
    
    .brownSvg{
        margin-right:1rem;
    }

    .settingInput{
        display:flex;
        align-items: center;
        height:4.5rem;
        width:100%;
        border: 0.05rem solid var(--subbackgroundspecial);
        border-radius:0.5rem;
        box-sizing:border-box;
        padding:0 1rem;
        overflow:hidden;
    }
    .settingInput > input {
        border:none;
        outline:none;
        background:none;
        padding:0;
        margin:0;
        color:var(--basic);
        font-size:1.3rem;
        font-weight:bold;
        flex:1;
        width:100%;
    }
    .settingInput > input::placeholder{
        color:var(--subfont);
    }

    .title svg{
        width:2rem;
        height:2rem;
    }
    .title path{
        fill:var(--mainfont)
    }
    
    .title > span {
        color:var(--gdient2);
        font-size:1.6rem;
        font-weight:bold;
    }

    .title{
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .formButton {
        border-radius: 1rem;
        background: var(--gdient2);
        height: 5.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--basic);
        font-weight: bold;
        font-size:1.7rem;
        margin-top:2rem;
        cursor:pointer;
    }

    .TranscationModal{
        width:100%;
    }
    .setTranscationTips{
        color: var(--gdient2);
        font-size:1.6rem;
        font-weight:bold;
        word-break: break-all;
        margin-top:1rem;
    }
    .formBox{
        width:100%;
        margin-top:2rem;
    }
    .formItemBox{
        width:100%;
        margin-top:1rem;
    }
    .formItemTop{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .formItemTop > span {
        font-size:1.5rem;
        color:#fff;
        font-weight:bold;
    }
    .formItemTop > svg {
        width:2rem;
        height:2rem;
    }
    .formItemTop path {
        fill:#fff;
    }
</style>