<template>
    <div class="TranscationModal">

        <div class="title">
            <div style="width:2rem"></div>
            <span v-text="'Inserir senha'"></span>
            <svg @click.stop="closeModal" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path></svg>
        </div>

        <div class="formBox">
            <div class="settingInput">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.1667 20.25H2.83333C2.561 20.25 2.30253 20.1906 2.07017 20.0841L9.50372 12.6506L10.0936 12.982C11.1587 13.5805 12.4612 13.57 13.5166 12.9544L14.3775 12.4522C14.4162 12.5179 14.4637 12.5798 14.5201 12.6362L21.9558 20.0719C21.7168 20.1861 21.4492 20.25 21.1667 20.25Z" fill="#9D9D9D"></path>
                    <path d="M22.9971 18.5205C22.999 18.4861 23 18.4515 23 18.4167V7.4228L15.9889 11.5123L22.9971 18.5205Z" fill="#9D9D9D"></path>
                    <path d="M7.8437 11.7178L1 7.87261V18.4167C1 18.4636 1.00177 18.5102 1.00524 18.5563L7.8437 11.7178Z" fill="#9D9D9D"></path>
                    <path d="M1 5.58333V5.76971L10.9916 11.3837C11.4899 11.6636 12.0992 11.6587 12.5928 11.3708L22.9801 5.31202C22.8489 4.42815 22.087 3.75 21.1667 3.75H2.83333C1.82081 3.75 1 4.57081 1 5.58333Z" fill="#9D9D9D"></path>
                </svg>
                <input type="text" v-model="email" :placeholder="placeholder" />
            </div>
            <div class="settingInput" style="margin-top:1rem;">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7V9H19C20.1046 9 21 9.89543 21 11V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V11C3 9.89543 3.89543 9 5 9H6V7ZM16 7V9H8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 14.7998C14 15.5625 13.5731 16.2255 12.9451 16.5628L13.5612 18.1029C13.7714 18.6284 13.3843 19.2 12.8184 19.2H11.1816C10.6157 19.2 10.2286 18.6284 10.4388 18.1029L11.0549 16.5628C10.4269 16.2255 10 15.5625 10 14.7998C10 13.6952 10.8954 12.7998 12 12.7998C13.1046 12.7998 14 13.6952 14 14.7998Z" fill="#039685"></path>
                </svg>
                <input type="password" v-model="password" :placeholder="passwordPlaceholder" />
            </div>
        </div>
        
        <div class="formButton" :style="canPush ? '' : 'filter: brightness(80%);'" @click="confirm">
            {{ $t("Confirm") }}
            <div style="margin-left:0.3rem" v-loading="isPush"></div>
        </div>
    </div>
</template>

<script>

    import { SetEmail } from "@/api/setting.js";

    export default {
        name:"SetEmail",
        data(){
            return {
                email:"",
                password:"",
                isPush:false,
                placeholder:this.$t("Email"),
                passwordPlaceholder:this.$t("Password")
            }
        },
        props:{
            visible:{
                type:Boolean
            }
        },
        computed:{
            canPush() {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 简单的邮箱正则
                const isEmailValid = emailPattern.test(this.email); // 检查 email 是否符合格式
                const isPasswordValid = this.password.length >= 6 && this.password.length <= 20; // 检查密码长度
                return isEmailValid && isPasswordValid;
            }
        },
        methods:{
            confirm(){ // 确认提交
                if(this.canPush){
                    if(!this.isPush){
                        let data = {
                            email:this.email,
                            password:this.password
                        }
                        this.isPush = true;
                        SetEmail(data).then(res => {
                            if(res.code == 200){
                                this.$store.dispatch("setUserEmail",this.email);
                                this.email = "";
                                this.password = "";
                                this.$emit("update:visible",false);
                            }
                            this.$message(res.msg)
                            this.isPush = false;
                        }).catch(err => {
                            this.isPush = false;
                        })
                    }
                }
            },
            closeModal(){
                this.password = "";
                this.$emit("update:visible",false);
            },
        },
    }
</script>

<style scoped>

    .brownSvg path {
        fill:var(--subfont);
    }
    
    .brownSvg{
        margin-right:1rem;
    }

    .settingInput{
        display:flex;
        align-items: center;
        height:4.5rem;
        width:100%;
        border: 0.05rem solid var(--subbackgroundspecial);
        border-radius:0.5rem;
        box-sizing:border-box;
        padding:0 1rem;
        overflow:hidden;
    }
    .settingInput > input {
        border:none;
        outline:none;
        background:none;
        padding:0;
        margin:0;
        color:var(--basic);
        font-size:1.3rem;
        font-weight:bold;
        flex:1;
        width:100%;
    }
    .settingInput > input::placeholder{
        color:var(--subfont);
    }

    .title svg{
        width:2rem;
        height:2rem;
    }
    .title path{
        fill:var(--mainfont)
    }
    
    .title > span {
        color:var(--gdient2);
        font-size:1.6rem;
        font-weight:bold;
    }

    .title{
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .formButton {
        border-radius: 1rem;
        background: var(--gdient2);
        height: 5.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--basic);
        font-weight: bold;
        font-size:1.7rem;
        margin-top:2rem;
        cursor:pointer;
    }

    .TranscationModal{
        width:100%;
    }
    .setTranscationTips{
        color: var(--gdient2);
        font-size:1.6rem;
        font-weight:bold;
        word-break: break-all;
        margin-top:1rem;
    }
    .formBox{
        width:100%;
        margin-top:2rem;
    }
    .formItemBox{
        width:100%;
        margin-top:1rem;
    }
    .formItemTop{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .formItemTop > span {
        font-size:1.5rem;
        color:#fff;
        font-weight:bold;
    }
    .formItemTop > svg {
        width:2rem;
        height:2rem;
    }
    .formItemTop path {
        fill:#fff;
    }
</style>