<template>
    <div class="menuItemBox">
        <div class="menuItem" v-show="!loading" @click="toPage(item)">
            <img :src="item.fullImgUrl" @load="load" />
            <span v-text="item.imgTitle"></span>
        </div>
        <div class="skeleton" v-show="loading">

        </div>
    </div>
</template>

<script>
    export default {
        name:"MenuItem",
        props:{
            item:{
                type:Object
            }
        },
        data(){
            return {
                loading:true,
            }
        },
        methods:{
            toPage(item){
                if(item.routerType == 2){
                    this.$router.push(item.routerUrl)
                }
            },
            load(){
                this.loading = false;
            }
        },
        mounted(){
            setTimeout(() => {
                this.loading = false;
            },1000)
        }
    }
</script>

<style scoped>
    .menuItem > img {
        height: 100%;
        position:absolute;
        z-index:1;
        left:0;
        top:0;
    }
    .menuItem > span {
        color: #fff;
        font-size: 1.6rem;
        font-weight: bold;
        width: 11rem;
        word-break: break-all;
        display: -webkit-box;           /* 必须结合 -webkit-line-clamp 使用 */
        -webkit-box-orient: vertical;   /* 设置盒子内部元素的排列方式为垂直 */
        -webkit-line-clamp: 2;          /* 显示的行数 */
        overflow: hidden;               /* 超出内容隐藏 */
        text-overflow: ellipsis; 
        position:relative;
        z-index:2;
    }
    .menuItem {
        height:100%;
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 0 1rem;
        padding-right:6rem;
        padding-left:10rem;
        position:relative;
        cursor:pointer;
    }
    .menuItemBox{
        height: 8rem;
        width:100%;
    }
</style>